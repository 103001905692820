import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './CompanyDetailPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CompanyDetailPopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [employeeCount, setEmployeeCount] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setCompanyName(response.data.result.questions[0].answer);
            setCompanyWebsite(response.data.result.questions[9].answer);
            setEmployeeCount(response.data.result.questions[10].answer);
        }
    };

    const handleSaveButton = async () => {
        if (!companyName || !companyWebsite || !employeeCount) {
            toast.error('All questions are mandatory.');
            return;
        }

        const answers = [
            { question_id: questions[0].id, answer: companyName },
            { question_id: questions[9].id, answer: companyWebsite },
            { question_id: questions[10].id, answer: employeeCount },
        ];

        for (let answer of answers) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                survey_user_input_id: surveyUserInputId,
                question_id: answer.question_id,
                answer: answer.answer,
                user_id: user.userId,
            });
        }
        onClose();
    };

    return (
        <div className='company-detail-edit-popup-main'>
            <div className="company-detail-edit-popup">
                <IoMdClose className="company-edit-pop-close" onClick={onClose} />
                <section className="companys-section">
                    <div className="input-group">
                        <label>What is the name of your company?</label>
                        <input
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label>Could you please provide your company's website?</label>
                        <input
                            type="text"
                            value={companyWebsite}
                            onChange={(e) => setCompanyWebsite(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label>What is your company's employee count?</label>
                        <input
                            type="text"
                            value={employeeCount}
                            onChange={(e) => setEmployeeCount(e.target.value)}
                        />
                    </div>

                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default CompanyDetailPopup;
