import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './LoginForm1.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginForm1 = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    // const role = location.state?.role;
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handleChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login_step2`, { email, password });
            console.log("response login ps===============", response);
            if (response.data.result.status === 'success') {
                localStorage.setItem('user', JSON.stringify({
                    userName: response.data.result.user_name,
                    firstName: response.data.result.first_name,
                    userId: response.data.result.user_id,
                    role: response.data.result.emp_type
                }));
                if (response.data.result.emp_type === 'freelancer') {
                    navigate('/freelancer/onboarding');
                } else {
                    navigate('/client/survey');
                }
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleForgetPassword = () => {
        navigate('/forget_password');
    };

    const handlePrivacyPolicy = () => {
        navigate('/freelancer/privacy_policy');
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="password-form-container">
            <div className="logo" onClick={handleLogoClick}>
                <img style={{ height: '100px' }} src={logo} alt="Logo" />
            </div>
            <div className='content'>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <p>Glad to have you back</p>
                    <div className="input-group">
                        <input
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            placeholder="password (8 or more characters)"
                            value={password}
                            onChange={handleChange}
                            required
                        />
                        <div className="password-toggle" onClick={togglePasswordVisibility}>
                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </div>
                    </div>
                    <div className='checkbox'>
                        <label>
                            <input type="checkbox" style={{ width: '15px' }} /> Remember Me
                        </label>
                        <a href="" onClick={handleForgetPassword} style={{ fontSize: '14px' }}>Forgot Password?</a>
                    </div>
                    <button type="submit" className="login-btn">Login</button>
                </form>
            </div>
            {/* <div className="footer">
                © 2024 powered by O2B. &nbsp;<a href="" onClick={handlePrivacyPolicy}>Privacy Policy</a>
            </div> */}
            <ToastContainer />
        </div>
    );
};

export default LoginForm1;
