import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Bio.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Bio = () => {
    const [bio, setBio] = useState('');
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_emp_bio';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetch request options==========", requestOptions);

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setBio(data.result.bio);
                        console.log('bio from backend==========', data.result.bio);
                    } else {
                        console.error('Error fetching bio=======', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching bio======', error);
                });
        }
    }, []);

    const handleBioChange = (e) => {
        const { value } = e.target;
        setBio(value);
        // localStorage.setItem('bio', value);
        // setIsButtonDisabled(e.target.value.length < 50);
        // toast.error("Provide bio in more than 50 word")
    };

    const handleNextButton = () => {
        if (user) {
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/update_bio`, {
                bio: bio,
                user_id: user.userId
            })
                .then(response => {
                    if (response.data.result.status === 'success') {
                        navigate('/freelancer/profile/rate');
                    } else {
                        alert('Failed to update bio: ' + response.data.result.message);
                    }
                });
        } else {
            alert('User information not found. Please log in again.');
        }
    };

    const handleBackButton = () => {
        navigate('/freelancer/profile/skills');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="bio-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="main-contents">
                <section className="bio-section">
                    <div style={{ display: 'flex' }}>7/10</div><br />
                    <h2>Great. Now write a bio to tell the world about yourself.</h2>
                    <p>Help people get to know you at a glance. What work do you do best? Tell them clearly, using paragraphs or bullet points. You can always edit later, just make sure you proofread now.</p>
                    <textarea
                        placeholder="Enter Your Top Skills, Experiences, and interests. This is one of the things clients will see on your profile."
                        value={bio}
                        onChange={handleBioChange}
                    />
                    <span className="char-counter">{bio.length >= 100 ? '' : `At least ${100 - bio.length} characters`}</span>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src="https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg" alt="Natalia G." className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Natalia G.</div>
                                <span>Odoo Buddy</span>
                                {/* <div className="testimonial-stats">
                                    <span>5.0</span> <span>50.6k</span> <span>1.4k Jobs</span>
                                </div> */}
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "I rely on Odoo Freelancer for finding meaningful freelance work. The platform's vetting process ensures quality clients, and its messaging system makes communication straightforward. It's empowering to have Odoo Freelancer support my career growth in such a dynamic and supportive environment."
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next, choose your categories</button>
                {/* disabled={isButtonDisabled} */}
            </footer>
        </div>
    );
}

export default Bio;
