import React from 'react';
import './AgencyAccount.css';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../Footer/Footer';
import Header from '../../../Header/Header';

const AgencyAccount = () => {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/freelancer/setting/membership')
    }

    return (
        <div className="agency-account-container">
            <Header />

            <main className="agency-account-main">
                <h1>Create agency</h1>
                <p>
                    Agencies allow for multiple Odoo Freelancers on a single team and often have business managers.
                    Create an agency if you plan to work this way. <a href="">Learn more</a>
                </p>

                <div className="form-group">
                    <label htmlFor="companyName">Company Name</label>
                    <input type="text" id="companyName" placeholder="Enter Name" />
                </div>

                <div className="form-actions">
                    <button className="cancel-button">Cancel</button>
                    <button className="continue-button" onClick={handleContinue}>Continue</button>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default AgencyAccount;
