import React, { useState, useEffect } from 'react';
import './MyProfile.css';
import RoleEditPop from '../../../../EditPopUp/RoleEditPop';
import BioEditPop from '../../../../EditPopUp/BioEditPop';
import RateEditPop from '../../../../EditPopUp/RateEditPop';
import SkillEditPop from '../../../../EditPopUp/SkillEditPop';
import LangEditPop from '../../../../EditPopUp/LangEditPop';
import EducEditPop from '../../../../EditPopUp/EducEditPop';
import ExpEditPop from '../../../../EditPopUp/ExpEditPop';
import { MdEdit } from "react-icons/md";
import { IoLocationOutline, IoInformationCircleOutline } from "react-icons/io5";
import demoProfile from '../../../../../assets/imgs/demo_profile_1.jpg';

const MyProfile = () => {

    const [user, setUser] = useState(null);
    const [showRoleEditPop, setShowRoleEditPop] = useState(false);
    const [showBioEditPop, setShowBioEditPop] = useState(false);
    const [showRateEditPop, setShowRateEditPop] = useState(false);
    const [showSkillEditPop, setShowSkillEditPop] = useState(false);
    const [showLangEditPop, setShowLangEditPop] = useState(false);
    const [showEducEditPop, setShowEducEditPop] = useState(false);
    const [showExpEditPop, setShowExpEditPop] = useState(false);
    const [profile, setProfile] = useState({
        name: '',
        location: '',
        role: '',
        overview: '',
        hourlyRate: '',
        skills: [],
        workHistory: [],
        education: [],
        languages: {},
        profilePic: '',
    });
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchProfileData();
    }, []);

    const fetchProfileData = async () => {
        try {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const userId = storedUser ? storedUser.userId : null;

            if (!userId) {
                console.error('User ID not found in localStorage');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch_profile_preview_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch profile data');
            }

            const data = await response.json();
            if (data.result.status === 'success') {
                setProfile(data.result.profile_data);
            } else {
                throw new Error(data.message || 'Failed to fetch profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error.message);
        }
    };

    const handleEditClick = (section) => {
        if (section === 'role') {
            setShowRoleEditPop(true);
        }
        if (section === 'overview') {
            setShowBioEditPop(true);
        }
        if (section === 'hourlyRate') {
            setShowRateEditPop(true);
        }
        if (section === 'skills') {
            setShowSkillEditPop(true);
        }
        if (section === 'languages') {
            setShowLangEditPop(true);
        }
        if (section === 'workHistory') {
            setShowExpEditPop(true);
        }
        if (section === 'education') {
            setShowEducEditPop(true);
        }
    };

    const handleRoleEditClose = () => {
        setShowRoleEditPop(false);
        fetchProfileData();
    };

    const handleRoleChange = (newRole) => {
        if (profile.role !== newRole) {
            setProfile(prevProfile => ({ ...prevProfile, role: newRole }));
            setIsEdited(true);
        }
    };

    const handleBioEditClose = () => {
        setShowBioEditPop(false);
        fetchProfileData();
    };

    const handleBioChange = (newBio) => {
        if (profile.overview !== newBio) {
            setProfile(prevProfile => ({ ...prevProfile, overview: newBio }));
            setIsEdited(true);
        }
    };

    const handleRateEditClose = () => {
        setShowRateEditPop(false);
        fetchProfileData();
    };

    const handleHourlyRateChange = (newHourlyRate) => {
        if (profile.hourlyRate !== newHourlyRate) {
            setProfile(prevProfile => ({ ...prevProfile, hourlyRate: newHourlyRate }));
            setIsEdited(true);
        }
    };

    const handleSkillEditClose = () => {
        setShowSkillEditPop(false);
        fetchProfileData();
    };

    const handleSkillsChange = (newSkills) => {
        if (profile.skills.length !== newSkills.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                skills: newSkills
            }));
            setIsEdited(true);
        }
    };

    const handleLangEditClose = () => {
        setShowLangEditPop(false);
        fetchProfileData();
    };

    const handleLanguageChange = (newLanguages) => {
        if (profile.languages.length !== newLanguages.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                languages: newLanguages.map(lang => lang.language),
                proficiency: newLanguages.map(lang => lang.proficiency)
            }));
            setIsEdited(true);
        }
    };


    const handleExpEditClose = () => {
        setShowExpEditPop(false);
        fetchProfileData();
    };

    const handleExperienceChange = (newWorkHistory) => {
        if (profile.workHistory.length !== newWorkHistory.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                workHistory: newWorkHistory
            }));
            setIsEdited(true);
        }
    }

    const handleEducEditClose = () => {
        setShowEducEditPop(false);
        fetchProfileData();
    };

    const handleEducationChange = (newEducation) => {
        if (profile.education.length !== newEducation.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                education: newEducation
            }));
            setIsEdited(true);
        }
    }

    return (
        <section className="profile-details">
            <div className="profile-card">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {profile.profilePic ? <img src={`data:image/png;base64,${profile.profilePic}`} alt="Profile" className="profile-pic" /> : <img src={demoProfile} alt='Profile' className="profile-pic" />}
                    <span style={{ marginLeft: '25px' }}>
                        <h3 style={{ fontWeight: '700' }}>{profile.name}</h3>
                        <p style={{ fontWeight: '600', display: 'flex', alignItems: 'center' }}><IoLocationOutline />&nbsp;{profile.location}</p>
                    </span>
                </div>
                <div className="profile-info">
                    <div className='edit-btn-profile'>
                        <h5 style={{ color: '#A3A3A3', fontWeight: '600' }}>Role</h5>
                        <button className="edit-button" onClick={() => handleEditClick('role')}><MdEdit /></button>
                    </div>
                    <h5 style={{ fontWeight: '600' }}>{profile.role}</h5><br />
                    <div className='edit-btn-profile'>
                        <h5 style={{ color: '#A3A3A3', fontWeight: '600' }}>Overview</h5>
                        <button className="edit-button" onClick={() => handleEditClick('overview')}><MdEdit /></button>
                    </div>
                    <h5 style={{ fontWeight: '400' }}>{profile.overview}</h5><br />
                    <div className='edit-btn-profile'>
                        <h5 style={{ color: '#A3A3A3', fontWeight: '600' }}>Hourly Rate</h5>
                        <button className="edit-button" onClick={() => handleEditClick('hourlyRate')}><MdEdit /></button>
                    </div>
                    <h5 style={{ fontWeight: '600' }}>${profile.hourlyRate} / hour</h5>
                </div>
            </div>
            <div className="skills-section">
                <div className='edit-btn-div'>
                    <h4 style={{ fontWeight: '700' }}>Skills</h4>
                    <button className="edit-button" onClick={() => handleEditClick('skills')}><MdEdit /></button>
                </div>
                <ul>
                    {profile.skills.map((skill, index) => (
                        <li key={index} className="skill-item">{skill}</li>
                    ))}
                </ul>
            </div>
            <div className="work-history-section">
                <div className='edit-btn-div'>
                    <h4 style={{ fontWeight: '700' }}>Work History</h4>
                    <button className="edit-button" onClick={() => handleEditClick('workHistory')}><MdEdit /></button>
                </div>
                {profile.workHistory.map((work, index) => (
                    <div key={index} className="work-history-item">
                        <h5 style={{ fontWeight: '600' }}>{work.company} | {work.position}</h5>
                        <p style={{ color: '#A3A3A3', fontWeight: '600' }}>{work.period}</p>
                    </div>
                ))}
            </div>
            <div className="education-section">
                <div className='edit-btn-div'>
                    <h4 style={{ fontWeight: '700' }}>Education</h4>
                    <button className="edit-button" onClick={() => handleEditClick('education')}><MdEdit /></button>
                </div>
                {profile.education.map((edu, index) => (
                    <div key={index} className="education-item">
                        <h5 style={{ fontWeight: '600' }}>{edu.institution}</h5>
                        <p style={{ color: '#A3A3A3', fontWeight: '600' }}>{edu.degree} | {edu.period}</p>
                    </div>
                ))}
            </div>
            <div className="languages-section">
                <div className='edit-btn-div'>
                    <h4 style={{ fontWeight: '700' }}>Languages</h4>
                    <button className="edit-button" onClick={() => handleEditClick('languages')}><MdEdit /></button>
                </div>
                <ul>
                    {Object.entries(profile.languages).map(([language, level], index) => (
                        <li key={index} className="language-item" ><span style={{ fontWeight: '600' }}>{language}</span> : &nbsp;&nbsp;<span style={{ color: '#A3A3A3', fontWeight: '600' }}>{level}</span></li>
                    ))}
                </ul>
            </div>
            {showRoleEditPop && (
                <RoleEditPop
                    user={user}
                    role={profile.role}
                    onClose={handleRoleEditClose}
                    onRoleChange={handleRoleChange}
                />
            )}
            {showBioEditPop && (
                <BioEditPop
                    user={user}
                    bio={profile.overview}
                    onClose={handleBioEditClose}
                    onBioChange={handleBioChange}
                />
            )}
            {showRateEditPop && (
                <RateEditPop
                    user={user}
                    hourlyRate={profile.hourlyRate}
                    onClose={handleRateEditClose}
                    onHourlyRateChange={handleHourlyRateChange}
                />
            )}
            {showSkillEditPop && (
                <SkillEditPop
                    user={user}
                    selectedSkills={profile.skills}
                    onClose={handleSkillEditClose}
                    onSkillsChange={handleSkillsChange}
                />
            )}
            {showLangEditPop && (
                <LangEditPop
                    user={user}
                    languages={profile.languages}
                    onClose={handleLangEditClose}
                    onLanguageChange={handleLanguageChange}
                />
            )}
            {showExpEditPop && (
                <ExpEditPop
                    onClose={handleExpEditClose}
                    onExperienceChange={handleExperienceChange}
                />
            )}
            {showEducEditPop && (
                <EducEditPop
                    onClose={handleEducEditClose}
                    onEducationChange={handleEducationChange}
                />
            )}
        </section>
    )
}

export default MyProfile