import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoMdInformationCircleOutline, IoMdClose } from "react-icons/io";
import './RoleEditPop.css';

const RoleEditPop = ({ user, role: initialRole, onClose, onRoleChange }) => {
    const [role, setRole] = useState(initialRole);
    const [error, setError] = useState('');

    useEffect(() => {
        if (user) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_emp_role';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: user.userId })
            };

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setRole(data.result.role_name);
                    } else {
                        console.error('Error fetching role', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching role', error);
                });
        }
    }, [user]);

    const handleSaveButton = async () => {
        if (!user || !user.userId) {
            setError('User not logged in');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update_job_position`, {
                user_id: user.userId,
                job_position: role
            });

            if (response.data.result.status === 'success') {
                onRoleChange(role);
                onClose();
            } else {
                setError(response.data.result.message);
            }
        } catch (error) {
            console.error('Error updating job position:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    return (
        <div className="role-edit-pop-container">
            <div className="role-main-content">
                <IoMdClose className="role-edit-pop-close" onClick={onClose} />
                <section className="question-section">
                    <div className="input">
                        <label>Your Professional Role</label>
                        <input
                            type="text"
                            name="role"
                            placeholder='Software Developer, etc.'
                            value={role}
                            onChange={handleRoleChange}
                            required
                        />
                        <p className='message'><IoMdInformationCircleOutline /> A descriptive title must have at least 4 letters.</p>
                    </div>
                </section>
                <button className='save-btn' onClick={handleSaveButton}>Save</button>
                {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
            </div>
        </div>
    );
};

export default RoleEditPop;
