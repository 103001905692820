import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './BudgetPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BudgetPopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [timeline, setTimeline] = useState('');
    const [budget, setBudget] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setTimeline(response.data.result.questions[16].answer);
            setBudget(response.data.result.questions[17].answer);
        }
    };

    const handleSaveButton = async () => {
        if (!timeline || !budget) {
            toast.error('All questions are mandatory.');
            return;
        }

        const answers = [
            { question_id: questions[16].id, answer: timeline },
            { question_id: questions[17].id, answer: budget },
        ];

        for (let answer of answers) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                survey_user_input_id: surveyUserInputId,
                question_id: answer.question_id,
                answer: answer.answer,
                user_id: user.userId,
            });
        }
        onClose();
    };

    return (
        <div className='budget-edit-popup-main'>
            <div className="budget-edit-popup">
                <IoMdClose className="budget-edit-pop-close" onClick={onClose} />
                <section className="budgets-section">
                    <form>
                        <div className="question">
                            <div style={{ width: '75%' }}>
                                <label>Preferred Project Timeline</label>
                                <p>Please indicate your desired timeline for completing this Odoo implementation project.</p>
                            </div>
                            <select
                                value={timeline}
                                onChange={(e) => setTimeline(e.target.value)}
                            >
                                <option value="" disabled>Select</option>
                                <option value="Less than 1 Month">Less than 1 month</option>
                                <option value="1-6 Months">1-6 Months</option>
                                <option value="7-12 Months">7-12 Months</option>
                                <option value="1-2 Years">1-2 Years</option>
                                <option value="More than 2 Years">More than 2 year</option>
                            </select>
                        </div>
                        <div className="question">
                            <div style={{ width: '75%' }}>
                                <label>Your Budget</label>
                                <p>Please outline your budget allocation for this project.</p>
                            </div>
                            <span className='dollor'>$</span>
                            <input
                                type="number"
                                placeholder="$0.00"
                                value={budget}
                                onChange={(e) => setBudget(e.target.value)}
                            />
                        </div>
                    </form>
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default BudgetPopup;
