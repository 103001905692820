import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileSetup.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import axios from 'axios';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfileSetup = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [dob, setDob] = useState('');
    const [phone, setPhone] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [aptSuite, setAptSuite] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [profilePic, setProfilePic] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    // const [timezone, setTimezone] = useState('');

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);

        const fetchProfileData = async () => {
            if (storedUser) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch_buddy_profile`, {
                        user_id: storedUser.userId
                    });
                    if (response.data.result.status === 'success') {
                        const { dob, phone, streetAddress, aptSuite, country, city, state, zipCode, userImage } = response.data.result.data;
                        setDob(dob || '');
                        setPhone(phone || '');
                        setStreetAddress(streetAddress || '');
                        setAptSuite(aptSuite || '');
                        setSelectedCountry(country || '');
                        setCity(city || '');
                        setSelectedState(state || '');
                        setZipCode(zipCode || '');
                        setUserImage(userImage || '');

                        if (country) {
                            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get_country_code`, { country_name: country });
                            console.log(response);
                            if (response.data.result.code) {
                                setSelectedCountryCode(response.data.result.code);
                                fetchStatesByCountryCode(response.data.result.code);
                            }
                        }
                    } else {
                        console.error('Error fetching profile setup data:', response.data.result.message);
                    }
                } catch (error) {
                    console.error('Error fetching profile setup data:', error);
                }
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countryData = response.data.map(country => ({
                    code: country.cca2,
                    name: country.name.common,
                }));
                countryData.sort((a, b) => a.name.localeCompare(b.name));
                setCountries(countryData);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();
    }, []);

    const fetchStatesByCountryCode = async (countryCode) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch_states`, { country_code: countryCode });
            if (response.data.result.status === 'success') {
                setStates(response.data.result.data);
            } else {
                console.error('Error fetching states:', response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };


    const handleCountryChange = async (event) => {
        const country_code = event.target.value;
        setSelectedCountry(country_code);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch_states`, { country_code });
            if (response.data.result.status === 'success') {
                setStates(response.data.result.data);
            } else {
                console.error('Error fetching states:', response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setSelectedState(selectedState);
        setState(states.find(state => state.id === selectedState)?.name || '');
    };

    const handleFileChange = (e) => {
        setProfilePic(e.target.files[0]);
    };

    const triggerFileInput = () => {
        document.getElementById('file-input').click();
    };

    const handleNextButton = async () => {
        if (!user || !user.userId) {
            alert('User not logged in');
            return;
        }

        const formData = new FormData();
        formData.append('user_id', user.userId);
        formData.append('dob', dob);
        formData.append('phone', phone);
        formData.append('streetAddress', streetAddress);
        formData.append('aptSuite', aptSuite);
        formData.append('country', selectedCountry);
        formData.append('city', city);
        formData.append('state', selectedState);
        formData.append('zip', zipCode);
        // formData.append('timeZone',timezone);
        if (profilePic) {
            formData.append('profilePic', profilePic);
        }

        console.log(dob);
        console.log(phone);
        console.log(streetAddress);
        console.log(selectedCountry);
        console.log(city);
        if (!dob || !phone || !streetAddress || !selectedCountry || !city) {
            toast.error('Please fill in all required fields.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/setup_buddy_profile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === 'success') {
                navigate('/freelancer/profile/review-profile');
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const handleBackButton = () => {
        navigate('/freelancer/profile/rate');
    };
    console.log("user image=======", userImage);

    console.log(selectedCountry, selectedState);

    return (
        <div className="profile-setup-container">
            <header className="header">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <UserNav />
            </header>
            <div className="content">
                <div style={{ display: 'flex' }}>9/10</div>
                <h1>A few last details, then you can check and publish your profile.</h1>
                <p>A professional photo helps you build trust with your clients. To keep things safe and simple, they'll pay you through us - which is why we need your personal information.</p>
                <div className="form-container">
                    <div className="form-left">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="form-group">
                                <label>Date Of Birth*</label>
                                <div className="input-group">
                                    <input
                                        type="date"
                                        value={dob}
                                        onChange={(e) => setDob(e.target.value)}
                                        required
                                        style={{ width: '250px', height: '40px' }}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label style={{ marginLeft: '80px', marginBottom: '10px' }}>Phone*</label>
                                <PhoneInput style={{ height: '50px', marginLeft: '80px' }}
                                    defaultCountry="in"
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="form-group">
                                <label>Street Address*</label>
                                <input
                                    type="text"
                                    value={streetAddress}
                                    onChange={(e) => setStreetAddress(e.target.value)}
                                    placeholder="Enter Street address"
                                    required
                                    style={{ width: '370px', height: '40px' }}
                                />
                            </div>
                            <div className="form-group">
                                <label style={{ marginLeft: '41px' }}>Apt/Suite</label>
                                <input
                                    type="text"
                                    value={aptSuite}
                                    onChange={(e) => setAptSuite(e.target.value)}
                                    placeholder="Apt/Suite(Optional)"
                                    style={{ marginLeft: '40px', height: '40px' }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className="form-group">
                                <label style={{ marginBottom: '5px' }}>Country*</label>
                                <select name="country" value={selectedCountry} onChange={handleCountryChange} required style={{ width: '180px', height: '40px' }}>
                                    <option value="">{selectedCountry ? selectedCountry : 'Select'}</option>
                                    {countries.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>City*</label>
                                <input
                                    type="text"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    placeholder="Enter City"
                                    required
                                    style={{ width: '190px', height: '40px' }}
                                />
                            </div>
                            <div className="form-group">
                                <label>State/Province</label>
                                <select name="state" value={selectedState} onChange={handleStateChange} style={{ width: '190px', height: '40px' }}>
                                    <option value="">{selectedState ? selectedState : 'Select'}</option>
                                    {states.map((state) => (
                                        <option key={state.id} value={state.id}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>ZIP/Postal Code</label>
                                <input
                                    type="text"
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                    placeholder="Enter ZIP/Postal Code"
                                    style={{ width: '190px', height: '40px' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className=".profile-form">
                        <label style={{ display: 'flex', justifyContent: 'center' }}>Profile Pic</label>
                        <div className="profile-pic-container">
                            {profilePic ? (
                                <img
                                    src={URL.createObjectURL(profilePic)}
                                    alt="Profile Pic"
                                    className="profile-pic"
                                />
                            ) : userImage && userImage[0] !== '' ? (
                                <img
                                    className="profile-pic-placeholder"
                                    src={`data:image/png;base64,${userImage}`}
                                    alt="User"
                                    onClick={triggerFileInput}
                                />
                            ) : (
                                <div className="profile-pic-placeholder" onClick={triggerFileInput}>+</div>
                            )}
                            <input
                                type="file"
                                id="file-input"
                                onChange={handleFileChange}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                            <div onClick={triggerFileInput} style={{ border: '0.5px black solid', borderRadius: '30px', padding: '4px' }}> + Upload Photo </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Review Your Profile</button>
            </footer>
            <ToastContainer />
        </div>
    );
};

export default ProfileSetup;
