import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.css';
import logo from '../../assets/imgs/Odoo-Feelancer-LOGO.png';

const ClientPrivacyPolicy = () => {
    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(-1);
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="privacy-policy-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
            </header>
            <section className="main-contents">
                <h1>Privacy Policy</h1>
                <div className='content'>
                    <h2>Information We Collect</h2>
                    <p>We may collect the following types of information:</p>
                    <ul>
                        <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and any other information you provide when you register or interact with our website.</li>
                        <li><strong>Usage Data:</strong> We collect information about how you use our website, including your IP address, browser type, and pages visited.</li>
                    </ul>
                    <h2>How We Use Your Information</h2>
                    <p>We use your information for various purposes, including:</p>
                    <ul>
                        <li>To provide and maintain our services</li>
                        <li>To notify you about changes to our services</li>
                        <li>To allow you to participate in interactive features of our services when you choose to do so</li>
                        <li>To provide customer support</li>
                        <li>To gather analysis or valuable information so that we can improve our services</li>
                        <li>To monitor the usage of our services</li>
                        <li>To detect, prevent, and address technical issues</li>
                    </ul>
                    <h2>Sharing Your Information</h2>
                    <p>We do not sell or rent your personal information to third parties. We may share your information in the following situations:</p>
                    <ul>
                        <li><strong>With Service Providers:</strong> We may employ third-party companies and individuals to facilitate our services, provide the services on our behalf, or assist us in analyzing how our services are used.</li>
                        <li><strong>For Legal Reasons:</strong> We may disclose your personal information if required to do so by law or in response to valid requests by public authorities.</li>
                    </ul>
                    <h2>Security of Your Information</h2>
                    <p>The security of your personal information is important to us, and we strive to use commercially acceptable means to protect it. However, please remember that no method of transmission over the internet or method of electronic storage is 100% secure.</p>
                    <h2>Your Rights</h2>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access the personal information we hold about you.</li>
                        <li>Request correction of any inaccurate or incomplete information.</li>
                        <li>Request deletion of your personal information.</li>
                        <li>Object to the processing of your personal information.</li>
                    </ul>
                    <p>To exercise these rights, please contact us using the information provided below.</p>
                    <h2>Changes to This Privacy Policy</h2>
                    <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions about this privacy policy, please contact us at:</p>
                    <ul>
                        <li>Email: info@o2b.co.in</li>
                        <li>Phone: 0120 431 1908</li>
                    </ul>
                    <p>By using our website, you consent to our privacy policy.</p>
                </div>
            </section>
            {/* <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Close</button>
            </footer> */}
        </div>
    );
}

export default ClientPrivacyPolicy;
