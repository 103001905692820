import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignUpForm from './odoo-buddy/Auth/SignUp/SignUpForm';
import ClientRegisterForm from './odoo-buddy/Auth/ClientRegistrationForm/ClientRegisterForm';
import UserRegisterForm from './odoo-buddy/Auth/UserRegistration/UserRegisterForm';
import LoginForm1 from './odoo-buddy/Auth/Login/LoginForm1';
import ClientLoginForm from './odoo-buddy/Auth/Login/ClientLoginForm';
import ClientLoginForm1 from './odoo-buddy/Auth/Login/ClientLoginForm1';
import ForgetPassword from './odoo-buddy/Auth/Password/ForgetPassword/ForgetPassword';
import EmailSent from './odoo-buddy/Auth/Password/EmailSent/EmailSent';
import NewPassword from './odoo-buddy/Auth/Password/NewPassword/NewPassword';
import Onboarding1 from './odoo-buddy/Onboarding/Onboarding1';
import FreelanceSurvey from './odoo-buddy/Survey/FreelanceSurvey';
import FreelanceSurvey2 from './odoo-buddy/Survey/FreelanceSurvey2';
import FreelanceSurvey3 from './odoo-buddy/Survey/FreelanceSurvey3';
import Profile from './odoo-buddy/ProfileInfo/ProfileAbout/Profile';
import Role from './odoo-buddy/ProfileInfo/Role/Role';
import Education from './odoo-buddy/ProfileInfo/Education/Education';
import Experience from './odoo-buddy/ProfileInfo/Experience/Experience';
import Skills from './odoo-buddy/ProfileInfo/Skills/Skills';
import Language from './odoo-buddy/ProfileInfo/Language/Language';
import LoginForm from './odoo-buddy/Auth/Login/LoginForm';
import Bio from './odoo-buddy/ProfileInfo/Bio/Bio';
import Categories from './odoo-buddy/ProfileInfo/Categories/Categories';
import ProfileSetup from './odoo-buddy/ProfileInfo/ProfileSetup/ProfileSetup';
import ProfilePreview from './odoo-buddy/ProfileInfo/ProfilePreview/ProfilePreview';
import ProfileReady from './odoo-buddy/ProfileInfo/ProfileReady/ProfileReady';
import FindWork from './odoo-buddy/Jobs/FindWork/FindWork';
import PrivacyPolicy from './odoo-buddy/AgreementFiles/PrivacyPolicy';
import UserAgreement from './odoo-buddy/AgreementFiles/UserAgreement';
import TermsOfServices from './odoo-buddy/AgreementFiles/TermsOfServices';
import ClientPrivacyPolicy from './odoo-buddy/AgreementFiles/ClientPrivacyPolicy';
import ClientUserAgreement from './odoo-buddy/AgreementFiles/ClientUserAgreement';
import ClientTermsOfServices from './odoo-buddy/AgreementFiles/ClientTermsOfServices';
import SearchJob from './odoo-buddy/Jobs/SearchJob/SearchJob';
import Setting from './odoo-buddy/Jobs/Settings/Setting/Setting';
import ClientSurvey1 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey1';
import ClientSurvey2 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey2';
import ClientSurvey3 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey3';
import ClientSurvey4 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey4';
import ClientSurvey5 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey5';
import ClientSurvey6 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey6';
import ClientSurvey7 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey7';
// import ClientSurvey8 from './odoo-buddy/ClientSurvey/ClientSurveys/ClientSurvey8';
import ClientPreview from './odoo-buddy/Client/ClientPreview/ClientPreview';
import ClientAccount from './odoo-buddy/Jobs/Settings/NewAccounts/ClientAccount/ClientAccount';
import AgencyAccount from './odoo-buddy/Jobs/Settings/NewAccounts/AgencyAccount/AgencyAccount';
import Membership from './odoo-buddy/Jobs/Settings/Membership/Membership';
import SavedJobs from './odoo-buddy/Jobs/SavedJobs/SavedJobs';
import SitemapComponent from './odoo-buddy/Sitemap/Sitemap';
import ClientProfile from './odoo-buddy/Client/ClientProfile/ClientProfile';
import ThankYou from './odoo-buddy/Client/ThankYou/ThankYou';
import ClientProfilePrev from './odoo-buddy/Client/ClientProfilePrev/ClientProfilePrev';
import ClientDashboard from './odoo-buddy/Client/ClientDashboard/ClientDashboard';
import Skill from './odoo-buddy/Client/AddProject/Skills/Skill';
import Title from './odoo-buddy/Client/AddProject/Title/Title';
import Scope from './odoo-buddy/Client/AddProject/Scope/Scope';
import Budget from './odoo-buddy/Client/AddProject/Budget/Budget';
import Description from './odoo-buddy/Client/AddProject/Description/Description';
import ProjectPreview from './odoo-buddy/Client/AddProject/ProjectPreview/ProjectPreview';


function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<SignUpForm />} />
                    <Route path="/sitemap.xml" element={<SitemapComponent />} />
                    <Route path="/client/register" element={<ClientRegisterForm />} />
                    <Route path="/client/login" element={<ClientLoginForm />} />
                    <Route path="/client/login/ps" element={<ClientLoginForm1 />} />
                    <Route path="/client/survey_1" element={<ClientSurvey1 />} />
                    <Route path="/client/survey_2" element={<ClientSurvey2 />} />
                    <Route path="/client/survey_3" element={<ClientSurvey3 />} />
                    <Route path="/client/survey_4" element={<ClientSurvey4 />} />
                    <Route path="/client/survey_5" element={<ClientSurvey5 />} />
                    <Route path="/client/survey_6" element={<ClientSurvey6 />} />
                    <Route path="/client/survey_7" element={<ClientSurvey7 />} />
                    {/* <Route path="/client/survey_8" element={<ClientSurvey8 />} /> */}
                    <Route path="/client/preview" element={<ClientPreview />} />
                    <Route path="/client/profile" element={<ClientProfile />} />
                    <Route path="/client/profile_preview" element={<ClientProfilePrev />} />
                    <Route path="/client/profile_completed" element={<ThankYou />} />
                    <Route path="/client/privacy_policy" element={<ClientPrivacyPolicy />} />
                    <Route path="/client/user_agreement" element={<ClientUserAgreement />} />
                    <Route path="/client/terms_of_services" element={<ClientTermsOfServices />} />
                    <Route path="/client/dashboard" element={<ClientDashboard />} />
                    <Route path="/client/project/title" element={<Title />} />
                    <Route path="/client/project/skills" element={<Skill />} />
                    <Route path="/client/project/scope" element={<Scope />} />
                    <Route path="/client/project/budget" element={<Budget />} />
                    <Route path="/client/project/description" element={<Description />} />
                    <Route path="/client/project/preview" element={<ProjectPreview />} />
                    <Route path="/freelancer/register" element={<UserRegisterForm />} />
                    <Route path="/freelancer/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/freelancer/user_agreement" element={<UserAgreement />} />
                    <Route path="/freelancer/terms_of_services" element={<TermsOfServices />} />
                    <Route path="/freelancer/login" element={<LoginForm />} />
                    <Route path="/freelancer/login/ps" element={<LoginForm1 />} />
                    <Route path="/forget_password" element={<ForgetPassword />} />
                    <Route path="/email_sent" element={<EmailSent />} />
                    <Route path="/password/reset" element={<NewPassword />} />
                    <Route path="/freelancer/onboarding" element={<Onboarding1 />} />
                    <Route path="/freelancer/survey/1" element={<FreelanceSurvey />} />
                    <Route path="/freelancer/survey/2" element={<FreelanceSurvey2 />} />
                    <Route path="/freelancer/survey/3" element={<FreelanceSurvey3 />} />
                    <Route path="/freelancer/profile" element={<Profile />} />
                    <Route path="/freelancer/profile/role" element={<Role />} />
                    <Route path="/freelancer/profile/experience" element={<Experience />} />
                    <Route path="/freelancer/profile/education" element={<Education />} />
                    <Route path="/freelancer/profile/language" element={<Language />} />
                    <Route path="/freelancer/profile/skills" element={<Skills />} />
                    <Route path="/freelancer/profile/bio" element={<Bio />} />
                    <Route path="/freelancer/profile/rate" element={<Categories />} />
                    <Route path="/freelancer/profile/setup" element={<ProfileSetup />} />
                    <Route path="/freelancer/profile/review-profile" element={<ProfilePreview />} />
                    <Route path="/freelancer/profile/ready" element={<ProfileReady />} />
                    <Route path="/freelancer/find_work" element={<FindWork />} />
                    <Route path="/freelancer/search_jobs" element={<SearchJob />} />
                    <Route path="/freelancer/saved_jobs" element={<SavedJobs />} />
                    <Route path="/freelancer/setting" element={<Setting />} />
                    <Route path="/freelancer/setting/client-account" element={<ClientAccount />} />
                    <Route path="/freelancer/setting/agency-account" element={<AgencyAccount />} />
                    <Route path="/freelancer/setting/membership" element={<Membership />} />
                </Routes>
            </Router>
        </>
        // <div>
        //     <SubmitProposal />
        //     {/* <Categories/> */}
        //     {/* <FreelanceSurvey/> */}
        //     {/* <Language/>  */}
        //     {/* <Skills/> */}
        // </div>
    );
}

export default App;