import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./ClientDashboard.css";
import ClientHeader from "../ClientHeader/ClientHeader";
import Footer from "../../Jobs/Footer/Footer";
import axios from 'axios';
import { PiDotsThreeOutlineFill } from "react-icons/pi";


const ClientDashboard = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log(storedUser);

        if (storedUser && storedUser.userId) {
            fetchUserJobs(storedUser.userId);
        }
    }, []);

    useEffect(() => {
        if (user) {
            fetchUserJobs(user.userId);
        }
    }, [user]);

    const fetchUserJobs = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/jobs`,
                { user_id: userId },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(response);

            if (response.data.result.status === 'success') {
                setJobs(response.data.result.jobs);
            } else {
                console.error('Failed to fetch jobs:', response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };


    const projects = [
        {
            title: "Figma Designer for Order Entry Screens",
            details: "Hourly: $25-$35 · Intermediate · Est. Time: Less than 1 month, Part Time",
            proposals: 12,
            hired: 0,
            status: "NA",
            posted: "2 days ago"
        },
        {
            title: "Website Redesign",
            details: "Project Fee: $1000 · Est. Budget: $600",
            proposals: 4,
            hired: 1,
            status: "NA",
            posted: "5 days ago"
        },
        {
            title: "Figma Designer for Order Entry Screens",
            details: "Hourly: $25-$35 · Intermediate · Est. Time: Less than 1 month, Part Time",
            proposals: 12,
            hired: 0,
            status: "Active",
            milestone: "Milestone 1",
            posted: "1 week ago"
        },
        {
            title: "Figma Designer for Order Entry Screens",
            details: "Hourly: $25-$35 · Intermediate · Est. Time: Less than 1 month, Part Time",
            proposals: 1,
            hired: 0,
            status: "NA",
            posted: "2 days ago"
        },
    ];

    const reviews = [
        {
            name: "Sebastian Vet.",
            role: "Odoo Enthusiast",
            rating: 4.9,
            reviewDate: "2 days ago",
            description: "I and my team needed a website overhaul for our non-profit, and OF connected us with a fantastic developer. The new site has helped us reach more donors and spread our message effectively.I and my team needed a website overhaul for our non-profit, and OF connected us with a fantastic developer. The new site has helped us reach more donors and spread our message effectively"
        },
        {
            name: "Sebastian Vet.",
            role: "Odoo Enthusiast",
            rating: 4.9,
            reviewDate: "2 days ago",
            description: "I and my team needed a website overhaul for our non-profit, and OF connected us with a fantastic developer. The new site has helped us reach more donors and spread our message effectively.I and my team needed a website overhaul for our non-profit, and OF connected us with a fantastic developer. The new site has helped us reach more donors and spread our message effectively"
        },
        {
            name: "Sebastian Vet.",
            role: "Odoo Enthusiast",
            rating: 4.9,
            reviewDate: "2 days ago",
            description: "I and my team needed a website overhaul for our non-profit, and OF connected us with a fantastic developer. The new site has helped us reach more donors and spread our message effectively.I and my team needed a website overhaul for our non-profit, and OF connected us with a fantastic developer. The new site has helped us reach more donors and spread our message effectively"
        }
    ];

    const handleAddProject = () => {
        navigate('/client/project/title');
    };

    const handleEditJob = (jobId) => {
        localStorage.setItem('job', JSON.stringify({ jobId: jobId }));
        navigate(`/client/project/preview`, { state: { jobId } });
    };

    return (
        <>
            <div className="client-dashboard">
                <ClientHeader />

                <main className="dashboard-content">
                    <section className="user-info">
                        <div>
                            <h1>Hi, {user?.firstName}</h1>
                            <p>Add new projects to get started</p>
                        </div>
                        <button className="add-project-button" onClick={handleAddProject}>+ Add Project</button>
                    </section>

                    <section className="projects-section">
                        <div className="projects-section-head">
                            <h2>Your Projects</h2>
                            <div className="sort-options">
                                <select name="sort">
                                    <option value="newest">Sort by: Newest</option>
                                    <option value="newest">Sort by: Popularity</option>
                                </select>
                            </div>
                        </div>
                        <div className="projects-list">
                            {jobs.map((job, index) => (
                                <div key={index} className="project-card">
                                    <div className="project-details">
                                        <h3>{job.title}</h3>
                                        <p>{job.billing_type} . ${job.budget} . {job.scope}</p>
                                        <p>Posted - {job.posted}</p>
                                    </div>
                                    <div className="project-stats">
                                        <div>
                                            <p className="content">{job.proposals || 0}</p>
                                            <p className="heading">Proposals</p>
                                        </div>
                                        <div>
                                            <p className="content">{job.hired || 0}</p>
                                            <p className="heading">Hired</p>
                                        </div>
                                        <div>
                                            <p className="content">{job.status || 'N/A'}</p>
                                            <p className="heading">Status</p>
                                        </div>
                                    </div>
                                    <div className="project-edit">
                                        <PiDotsThreeOutlineFill onClick={() => handleEditJob(job.job_id)} />
                                    </div>
                                </div>
                            ))}
                            {/* {projects.map((project, index) => (
                            <div key={index} className="project-card">
                                <div className="project-details">
                                    <h3>{project.title}</h3>
                                    <p>{project.details}</p>
                                    <p>Posted {project.posted}</p>
                                </div>
                                <div className="project-stats">
                                    <div>
                                        <p className="content">{project.proposals}</p>
                                        <p className="heading">Proposals</p>
                                    </div>
                                    <div>
                                        <p className="content">{project.hired}</p>
                                        <p className="heading">Hired</p>
                                    </div>
                                    <div>
                                        <p className="content">{project.status}</p>
                                        <p className="heading">Status</p>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                        </div>
                        {/* <button className="view-all-projects">View All Projects</button> */}
                    </section>

                    <section className="reviews-section">
                        <h2>Reviews</h2>
                        <div className="reviews-list">
                            {reviews.map((review, index) => (
                                <div key={index} className="review-card">
                                    <div className="review-header">
                                        <img src="https://img.freepik.com/free-photo/cheerful-indian-businessman-smiling-closeup-portrait-jobs-career-campaign_53876-129417.jpg" alt="Reviewer" />
                                        <div>
                                            <h3>{review.name}</h3>
                                            <p>{review.role}</p>
                                        </div>
                                    </div>
                                    <div className="review-body">
                                        <p>{review.description}</p>
                                    </div>
                                    {/* <div className="review-footer">
                                    <span>Rating: {review.rating}</span>
                                    <span> · {review.reviewDate}</span>
                                </div> */}
                                </div>
                            ))}
                        </div>
                    </section>
                </main>

            </div>
            <Footer />
        </>
    );
};

export default ClientDashboard;
