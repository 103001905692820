import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './ImplementationPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ImplementationPopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [currentSystems, setCurrentSystems] = useState('');
    const [implementation, setImplementation] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setCurrentSystems(response.data.result.questions[11].answer);
            setImplementation(response.data.result.questions[12].answer);
        }
    };

    const handleSaveButton = async () => {
        if (!currentSystems || !implementation) {
            toast.error('All questions are mandatory.');
            return;
        }

        const answers = [
            { question_id: questions[11].id, answer: currentSystems },
            { question_id: questions[12].id, answer: implementation },
        ];

        for (let answer of answers) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                survey_user_input_id: surveyUserInputId,
                question_id: answer.question_id,
                answer: answer.answer,
                user_id: user.userId,
            });
        }
        onClose();
    };

    return (
        <div className='implementation-detail-edit-popup-main'>
            <div className="implementation-detail-edit-popup">
                <IoMdClose className="implementation-edit-pop-close" onClick={onClose} />
                <section className="implementation-section">

                    <form>
                        <div className="question">
                            <label>Which systems are you currently using to manage your business processes ?</label>
                            <input
                                type='text'
                                placeholder="Enter system name"
                                value={currentSystems}
                                onChange={(e) => setCurrentSystems(e.target.value)}
                            />
                        </div>
                        <div className="question">
                            <label>What are the main goals and objectives for Odoo implementations ?</label>
                            <textarea
                                placeholder="Describe"
                                value={implementation}
                                onChange={(e) => setImplementation(e.target.value)}
                            />
                        </div>
                    </form>
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default ImplementationPopup;
