import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ThankYou.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import vector from '../../../assets/imgs/thank-you.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClientNavbar from '../ClientNavbar/ClientNavbar';

const ThankYou = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
    }, []);

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="thank-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <ClientNavbar />
            </header>
            <section className="thank-contents">
                <div className="profile-circle">
                    <h1>Thank You</h1>
                    {/* <img src="https://t4.ftcdn.net/jpg/03/52/86/63/360_F_352866304_U15J4MntUm2WNkPg2iePbJeUbB9vilz3.jpg" alt="Profile" /> */}
                </div>
                <h1>Nice work <span style={{ color: '#62495B' }}>{user?.firstName}</span></h1>

                <h3>Your profile is now complete and is currently under review. We will get back to you once the review process is finished. In the meantime, we appreciate your patience. If you have any questions or need assistance, please feel free to contact our support team at <a href='mailto:info@odoofreelancer.com'>info@odoofreelancer.com</a>. We are committed to providing you with the best experience possible.</h3>
            </section>
            {/* <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer> */}
            <ToastContainer />
        </div>
    );
}

export default ThankYou;
