import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './FreelanceSurvey3.css';
import logo from '../../assets/imgs/Odoo-Feelancer-LOGO.png';
import opportunity from '../../assets/imgs/opportunitiess.jpg';
import packages from '../../assets/imgs/package.jpeg';
import UserNav from '../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FreelanceSurvey3 = () => {
    const [workStyle, setWorkStyle] = useState('');
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // useEffect(() => {
    //     const storedWorkStyle = localStorage.getItem('workStyle');
    //     if (storedWorkStyle) {
    //         setWorkStyle(storedWorkStyle);
    //     }
    //     const storedUser = JSON.parse(localStorage.getItem('user'));
    //     if (storedUser) {
    //         setUser(storedUser);
    //     }
    // }, []);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_survey_workstyle';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetch request options==========", requestOptions);

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setWorkStyle(data.result.workstyle);
                        console.log('workstyle from backend==========', data.result.workstyle);
                    } else {
                        console.error('Error fetching workstyle=======', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching workstyle======', error);
                });
        }
    }, []);

    // useEffect(() => {
    //     localStorage.setItem('workStyle', workStyle);
    // }, [workStyle]);

    const handleChange = (e) => {
        setWorkStyle(e.target.value);
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleBackButton = () => {
        navigate('/freelancer/survey/2');
    };

    const handleSkipButton = () => {
        navigate('/freelancer/profile');
    };

    const handleNextButton = async () => {
        if (!user || !user.userId) {
            toast.error('User not logged in');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/workstyle`, {
                user_id: user.userId,
                workstyle: workStyle
            });
            console.log("Response from workstyle survey============", response);

            if (response.data.result.status === 'success') {
                navigate('/freelancer/profile');
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <div className="survey3-container">
                <header className="header">
                    <div className="logo" onClick={handleLogoClick}><img style={{ height: '65px' }} src={logo} alt="" /></div>
                    <UserNav />
                </header>
                <main className="main-content">
                    <section className="question-section">
                        <div className="progress">3/3</div>
                        <h1>And how would you like to work?</h1>
                        <p className="subtext">
                            Everybody works in different ways, so we have different ways of helping you work well. You can select multiple preferences now and can always change it later!
                        </p>
                        <div className="work-style-options">
                            <label className="work-style-option">
                                <img src={opportunity} alt='Opportunity'/>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>I'd like to find opportunities myself</p>
                                    <input
                                        type="radio"
                                        name="workStyle"
                                        value="I'd like to find opportunities for myself"
                                        checked={workStyle === 'I\'d like to find opportunities for myself'}
                                        onChange={handleChange}
                                    />
                                </div>
                                <p className="description">Clients post jobs in our Talent Marketplace™; you can browse and bid for them, or get invited by a client.</p>
                            </label>
                            <label className="work-style-option">
                                <img src={packages} alt="Package Work" />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>I'd like to package up my work for clients to buy</p>
                                    <input
                                        type="radio"
                                        name="workStyle"
                                        value="I'd like to package up my work for clients to buy"
                                        checked={workStyle === 'I\'d like to package up my work for clients to buy'}
                                        onChange={handleChange}
                                    />
                                </div>
                                <p className="description">Deliver your services with pre-defined steps and timelines. We'll list it in our Project Catalog™ for clients to buy right away.</p>
                            </label>
                        </div>
                    </section>
                </main>
                <div className="navigation-buttons">
                    <button className="back-button" onClick={handleBackButton}>Back</button>
                    <div className="skip-next-buttons">
                        <button className="skip-button" onClick={handleSkipButton}>Skip for now</button>
                        <button className="next-button" onClick={handleNextButton}>Next, Create a profile</button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
};

export default FreelanceSurvey3;
