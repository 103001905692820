import React, { useState, useEffect } from 'react';
import './CompleteProfilePopup.css';
import { FaChevronRight, FaChevronUp } from "react-icons/fa6";
import demoProfile from '../../assets/imgs/demo_profile_1.jpg';

const CompleteProfilePopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState({
        profilePic: '',
    });

    const fetchProfileData = async () => {
        try {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const userId = storedUser ? storedUser.userId : null;

            if (!userId) {
                console.error('User ID not found in localStorage');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch_profile_preview_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch profile data');
            }

            const data = await response.json();
            if (data.result.status === 'success') {
                setProfile(data.result.profile_data);
            } else {
                throw new Error(data.message || 'Failed to fetch profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error.message);
        }
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchProfileData(storedUser.id);
    }, []);

    return (
        <div className="complete-profile-popup-overlay">
            <div className="complete-profile-popup-content">
                <div className="profile-left">
                    {/* <img src={demoimg} alt="Profile" className="profile-image" /> */}
                    {profile.profilePic ? <img src={`data:image/png;base64,${profile.profilePic}`} alt="Profile" className="profile-image" /> : <img src={demoProfile} alt='Profile' className="profile-image" />}
                    <div className="completion-status">
                        <div className="completion-percentage">70% Complete</div>
                        <div className="completion-text">You're almost done!</div>
                    </div>
                </div>
                <div className="profile-right">
                    <span className="close" onClick={onClose}>&times;</span>
                    <h2>Complete your profile</h2>
                    <p>Odoo Buddy with complete, quality profiles are 4.5 times more likely to get hired by clients.</p>

                    <ul className="profile-sections">
                        <li className="section-item">
                            <div className="section-item-heading">
                                <div className="section-name">Employment history</div>
                                <div className="section-description">Past job experiences and positions (+20%)</div>
                            </div>
                            <div className="section-arrow"><FaChevronRight /></div>
                        </li>
                        <li className="section-item">
                            <div className="section-item-heading">
                                <div className="section-name">Portfolio</div>
                                <div className="section-description">Work Samples, Case Studies, etc. (+20%)</div>
                            </div>
                            <div className="section-arrow"><FaChevronRight /></div>
                        </li>
                        <li className="section-item">
                            <div className="section-item-heading">
                                <div className="section-name">Education</div>
                                <div className="section-description">Include degrees and diplomas (+10%)</div>
                            </div>
                            <div className="section-arrow"><FaChevronRight /></div>
                        </li>
                        <li className="section-item">
                            <div className="section-item-heading">
                                <div className="section-name">Video Introduction</div>
                                <div className="section-description">A short 30-60 second intro (+10%)</div>
                            </div>
                            <div className="section-arrow"><FaChevronRight /></div>
                        </li>
                        <li className="section-item">
                            <div className="section-item-heading">
                                <div className="section-name">Other experiences</div>
                                <div className="section-description">Bootcamps, conferences, awards, etc. (+5%)</div>
                            </div>
                            <div className="section-arrow"><FaChevronRight /></div>
                        </li>
                    </ul>

                    <div className="completed-sections">
                        <button className="toggle-completed">Hide Completed (3) <FaChevronUp /></button>
                        <p className="completed-note">Nicely done! These items are checked off the list.</p>
                        <ul className="profile-sections">
                            <li className="section-item completed">
                                <div className="section-item-heading">
                                    <div className="section-name">Profile Photo</div>
                                    <div className="section-description">Take a professional picture</div>
                                </div>
                                <div className="section-arrow"><FaChevronRight /></div>
                            </li>
                            <li className="section-item completed">
                                <div className="section-item-heading">
                                    <div className="section-name">Overview</div>
                                    <div className="section-description">Add a bio highlighting your talent</div>
                                </div>
                                <div className="section-arrow"><FaChevronRight /></div>
                            </li>
                            <li className="section-item completed">
                                <div className="section-item-heading">
                                    <div className="section-name">Skills</div>
                                    <div className="section-description">Showcase your expertise</div>
                                </div>
                                <div className="section-arrow"><FaChevronRight /></div>
                            </li>
                        </ul>
                    </div>

                    <button className="close-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default CompleteProfilePopup;
