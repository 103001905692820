import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientSurvey6.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientSurvey6 = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [implementingSupport, setImplementingSupport] = useState('');
    const [managingPlan, setManagingPlan] = useState(null);
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });
            console.log("rsdv", response);

            if (response.data.result.status === 'success') {
                setSurveyUserInputId(response.data.result.survey_user_input_id);
                setQuestions(response.data.result.questions);

                setImplementingSupport(response.data.result.questions[14].answer);
                setManagingPlan(response.data.result.questions[15].answer);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching survey questions:', error);
            toast.error('Error fetching survey questions');
        }
    };

    const handleNextButton = async () => {
        if (!implementingSupport || !managingPlan) {
            toast.error('All questions are mandatory.');
            return;
        }
        try {
            const answers = [
                { question_id: questions[14].id, answer: implementingSupport },
                { question_id: questions[15].id, answer: managingPlan },
            ];

            for (let answer of answers) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                    user_id: user.userId,
                    survey_user_input_id: surveyUserInputId,
                    question_id: answer.question_id,
                    answer: answer.answer,
                });
            }
            navigate('/client/survey_7');
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('Error submitting survey response');
        }
    };

    const handleBackButton = () => {
        navigate('/client/survey_5');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-survey6-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="survey6-contents">
                <section className="survey6-section">
                    <div style={{ display: 'flex' }}>6/7</div><br />
                    <h2>System Maintenance and Updates</h2>
                    <p>To ensure optimal system performance and security, please outline your plan for managing ongoing maintenance and updates.</p>

                    <form>
                        <div className="question">
                            <label>Would you like to have post implementation support ?<sup className='madatory'>*</sup></label>
                            <div className="options">
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="Less than 1 Month"
                                        checked={implementingSupport === 'Less than 1 Month'}
                                        onChange={() => setImplementingSupport('Less than 1 Month')}
                                    />
                                    Less than 1 month
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="1-6 Months"
                                        checked={implementingSupport === '1-6 Months'}
                                        onChange={() => setImplementingSupport('1-6 Months')}
                                    />
                                    1-6 Months
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="1-2 Years"
                                        checked={implementingSupport === '1-2 Years'}
                                        onChange={() => setImplementingSupport('1-2 Years')}
                                    />
                                    1-2 Years
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="More than 2 Years"
                                        checked={implementingSupport === 'More than 2 Years'}
                                        onChange={() => setImplementingSupport('More than 2 Years')}
                                    />
                                    More than 2 years
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="No"
                                        checked={implementingSupport === 'No'}
                                        onChange={() => setImplementingSupport('No')}
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                        <div className="question">
                            <label>What is your plan for managing ongoing maintenance and updates to the system?<sup className='madatory'>*</sup></label>
                            <textarea
                                placeholder="Tell us your plan"
                                value={managingPlan}
                                onChange={(e) => setManagingPlan(e.target.value)}
                            />
                        </div>
                    </form>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src="https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg" alt="Justin" className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Justin</div>
                                <span>Client</span>
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "I and my team needed a website overhaul for our non-profit, and OF connected us with a fantastic developer. The new site has helped us reach more donors and spread our message effectively."
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default ClientSurvey6;
