import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Profile.module.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { FaLinkedin } from "react-icons/fa";
import { ImUpload2 } from "react-icons/im";
import { MdOutlineWatchLater } from "react-icons/md";
import UserNav from '../../Auth/UserNav/UserNav';
import axios from 'axios';
import LinkedinPopUps from '../../Templates/LinkedinPopUps';
import ResumePopUp from '../../Templates/ResumePopUp';

const Profile = () => {
    const [userName, setUserName] = useState('Guest');
    const [user, setUser] = useState(null);
    const [showLinkedinPopup, setShowLinkedinPopup] = useState(false);
    const [showResumePopup, setShowResumePopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser && storedUser.userName) {
            setUserName(storedUser.userName);
        }
    }, []);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
    }, [])

    const handleBackButton = () => {
        navigate('/freelancer/survey/3');
    };

    const handleContinueButton = () => {
        navigate('/freelancer/profile/role');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const openLinkedinPopup = () => {
        setShowLinkedinPopup(true);
    };

    const closeLinkedinPopup = () => {
        setShowLinkedinPopup(false);
    };

    const openResumePopup = () => {
        setShowResumePopup(true);
    };

    const closeResumePopup = () => {
        setShowResumePopup(false);
    };

    const handleUploadResume = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (file.type !== 'application/pdf') {
            alert('Please select a PDF file.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', user.userId);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/linkedin/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <>
            <div className={styles.container}>
                <header className={styles.header}>
                    <div className={styles.logo} onClick={handleLogoClick}>
                        <img style={{ height: '65px' }} src={logo} alt="" />
                    </div>
                    <UserNav />
                </header>
                <main className={styles.mainContent}>
                    <section className={styles.questionSection}>
                        <div className={styles.profile}>
                            <h3>Create Your Profile</h3>
                            <div className={styles.sidebarStats}>
                                <span>1/10</span> <span>
                                    <MdOutlineWatchLater /> &nbsp;5-10 mins
                                </span>
                            </div>
                        </div>
                        <h1 style={{ fontSize: '30px', textAlign:'justify' }}>
                            How would you like to tell us about yourself?
                        </h1>
                        <p className={styles.footerNote}>
                            We need to get a sense of your education, experience and skills. It’s quickest to import your information — you can edit it before your profile goes live.
                        </p>
                        <div className={styles.box} onClick={openLinkedinPopup}>
                            <FaLinkedin style={{ color: '#62495B' }} /> &nbsp; &nbsp;Import from Linkedin
                        </div>
                        <div className={styles.box} onClick={openResumePopup}>
                            {/* <label htmlFor="uploadResume" style={{ cursor: 'pointer', fontWeight: '400' }}> */}
                            <ImUpload2 style={{ color: '#62495B' }} />&nbsp; &nbsp;Upload your Resume
                            {/* </label> */}
                            {/* <input
                                type="file"
                                id="uploadResume"
                                accept=".pdf"
                                onChange={handleUploadResume}
                                style={{ display: 'none' }}
                            /> */}
                        </div>
                        <div className={styles.box}>
                            Fill out manually <span style={{ color: '#62495B' }}> (5-10 mins)</span>
                        </div>
                    </section>
                    <section className={styles.sidebarSection}>
                        <div className={styles.sidebar}>
                            <div className={styles.sidebarHeader}>
                                <img
                                    src="https://img.freepik.com/free-photo/front-view-smiley-man-posing_23-2149512425.jpg"
                                    alt=""
                                    style={{ height: '100px', width: '100px', borderRadius: '10px', margin: '5px' }}
                                />
                                <div className={styles.sidebarName}>Sebastian</div>
                            </div>
                            <p className={styles.sidebarText}>
                                "Discovering Odoo Freelancer has transformed how I approach projects. The platform connects me with top-tier clients, making it seamless to showcase my skills and secure rewarding opportunities. With its user-friendly interface and robust support, Odoo Freelancer is my go-to for thriving in the freelance world."
                            </p>
                        </div>
                    </section>
                </main>
                <div className={styles.navigationButtons}>
                    <button className={styles.backButton} onClick={handleBackButton}>
                        Back
                    </button>
                    <div className={styles.skipNextButtons}>
                        <button className={styles.nextButton} onClick={handleContinueButton}>Continue Editing Your Profile</button>
                    </div>
                </div>
            </div>
            {showLinkedinPopup && <LinkedinPopUps onClose={closeLinkedinPopup} />}
            {showResumePopup && <ResumePopUp onClose={closeResumePopup} />}
        </>
    );
}

export default Profile;
