import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientSurvey2.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientSurvey2 = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [odooPurchased, setOdooPurchased] = useState(null);
    const [odooUsageDuration, setOdooUsageDuration] = useState(null);
    const [customizationsMade, setCustomizationsMade] = useState(null);
    const [customizationDetails, setCustomizationDetails] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [showRemainingQue, setShowRemainingQue] = useState(false);
    const [showCustomizeDetailQue, setShowCustomizeDetailQue] = useState(false);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    useEffect(() => {
        setShowRemainingQue(odooPurchased === 'Yes');
    }, [odooPurchased]);

    useEffect(() => {
        setShowCustomizeDetailQue(customizationsMade === 'Yes');
    }, [customizationsMade]);

    const fetchSurveyQuestions = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });
            console.log("rsdv", response);

            if (response.data.result.status === 'success') {
                setSurveyUserInputId(response.data.result.survey_user_input_id);
                setQuestions(response.data.result.questions);

                setOdooPurchased(response.data.result.questions[4].answer);
                setOdooUsageDuration(response.data.result.questions[5].answer);
                setCustomizationsMade(response.data.result.questions[6].answer);
                setCustomizationDetails(response.data.result.questions[7].answer);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching survey questions:', error);
            toast.error('Error fetching survey questions');
        }
    };

    const handleNextButton = async () => {
        if (!odooPurchased) {
            toast.error('All questions are mandatory');
            return;
        }
        if (odooPurchased === 'Yes') {
            if (!odooUsageDuration || !customizationsMade) {
                toast.error('All questions are mandatory');
                return;
            }
            if (customizationsMade === 'Yes') {
                if (!customizationDetails) {
                    toast.error('All questions are mandatory');
                    return;
                }
            }
        }

        try {
            const answers = [
                { question_id: questions[4].id, answer: odooPurchased },
                { question_id: questions[5].id, answer: odooUsageDuration },
                { question_id: questions[6].id, answer: customizationsMade },
                { question_id: questions[7].id, answer: customizationDetails },
            ];

            for (let answer of answers) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                    user_id: user.userId,
                    survey_user_input_id: surveyUserInputId,
                    question_id: answer.question_id,
                    answer: answer.answer,
                });
            }
            navigate('/client/survey_3');
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('Error submitting survey response');
        }
    };

    const handleBackButton = () => {
        navigate('/client/survey_1');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-survey2-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="survey2-contents">
                <section className="survey2-section">
                    <div style={{ display: 'flex' }}>2/7</div><br />
                    <h2>Odoo Usage and Customization</h2>
                    <p>To better understand your experience with Odoo and how it aligns with our platform, please answer the following questions about your Odoo usage and any customizations you have made.</p>

                    <form>
                        <div className="question">
                            <label>Have you already purchased Odoo?<sup className='madatory'>*</sup></label>
                            <div className="options">
                                <label>
                                    <input
                                        type="radio"
                                        name="odooPurchased"
                                        value='Yes'
                                        checked={odooPurchased === 'Yes'}
                                        onChange={() => setOdooPurchased('Yes')}
                                    />
                                    Yes
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="odooPurchased"
                                        value='No'
                                        checked={odooPurchased === 'No'}
                                        onChange={() => setOdooPurchased('No')}
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                        {showRemainingQue && (
                            <div className="question">
                                <label>Since when are you using Odoo?<sup className='madatory'>*</sup></label>
                                <div className="options">
                                    <label>
                                        <input
                                            type="radio"
                                            name="odooUsageDuration"
                                            value='Less than 1 Month'
                                            checked={odooUsageDuration === 'Less than 1 Month'}
                                            onChange={() => setOdooUsageDuration('Less than 1 Month')}
                                        />
                                        Less than 1 month
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="odooUsageDuration"
                                            value='1-6 Months'
                                            checked={odooUsageDuration === '1-6 Months'}
                                            onChange={() => setOdooUsageDuration('1-6 Months')}
                                        />
                                        1-6 Months
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="odooUsageDuration"
                                            value='1-2 Years'
                                            checked={odooUsageDuration === '1-2 Years'}
                                            onChange={() => setOdooUsageDuration('1-2 Years')}
                                        />
                                        1-2 Years
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="odooUsageDuration"
                                            value='More than 2 Years'
                                            checked={odooUsageDuration === 'More than 2 Years'}
                                            onChange={() => setOdooUsageDuration('More than 2 Years')}
                                        />
                                        More than 2 years
                                    </label>
                                </div>
                            </div>
                        )}

                        {showRemainingQue && (
                            <div className="question">
                                <label>Have you made any customizations to Odoo?<sup className='madatory'>*</sup></label>
                                <div className="options">
                                    <label>
                                        <input
                                            type="radio"
                                            name="customizationsMade"
                                            value='Yes'
                                            checked={customizationsMade === 'Yes'}
                                            onChange={() => setCustomizationsMade('Yes')}
                                        />
                                        Yes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="customizationsMade"
                                            value='No'
                                            checked={customizationsMade === 'No'}
                                            onChange={() => setCustomizationsMade('No')}
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                        )}

                        {showCustomizeDetailQue && (
                            <div className="question">
                                <label>Could you provide details about the customization?<sup className='madatory'>*</sup></label>
                                <textarea
                                    placeholder="Describe the customization"
                                    value={customizationDetails}
                                    onChange={(e) => setCustomizationDetails(e.target.value)}
                                />
                            </div>
                        )}

                    </form>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src="https://www.shutterstock.com/image-vector/modern-city-building-exterior-facade-600nw-1967885803.jpg" alt="Creative Minds Agency" className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Creative Minds Agency</div>
                                {/* <span>Company</span> */}
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "We needed a web developer for a tight deadline, and OF delivered. The freelancer we hired was skilled and proactive in suggesting improvements. Our website looks amazing, and we’ve seen increased traffic since the launch."
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default ClientSurvey2;
