import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UserAgreement.css';
import logo from '../../assets/imgs/Odoo-Feelancer-LOGO.png';

const UserAgreement = () => {
    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(-1);
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="ua-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
            </header>
            <section className="main-contents">
                <h1>User Agreement for Odoo Freelancer</h1>
                <div className='content'>
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing or using "Odoo Freelancer," you agree to comply with this User Agreement. If you do not agree, please do not use our website.</p>

                    <h2>2. Account Registration</h2>
                    <p>To use certain features of our services, you must create an account. You agree to provide accurate and complete information during registration and to update this information as necessary. You are responsible for maintaining the confidentiality of your account and password.</p>

                    <h2>3. Eligibility</h2>
                    <p>You must be at least 18 years old to use our services. By creating an account, you confirm that you meet this age requirement.</p>

                    <h2>4. User Responsibilities</h2>
                    <p>You agree to use our services for lawful purposes only. You will not engage in any activity that could harm our website or other users.</p>

                    <h2>5. Services Offered</h2>
                    <p>"Odoo Freelancer" provides a platform for freelancers and clients to connect. We are not responsible for the agreements made between users or the services provided.</p>

                    <h2>6. Fees</h2>
                    <p>You may incur fees for using certain services. These fees will be clearly stated on our website. By using our services, you agree to pay any applicable fees.</p>

                    <h2>7. Disputes</h2>
                    <p>In the event of a dispute between users, you agree to attempt to resolve the issue directly. If necessary, we may help in resolving disputes.</p>

                    <h2>8. Termination</h2>
                    <p>We reserve the right to suspend or terminate your account if you violate this agreement or engage in any harmful behavior.</p>

                    <h2>9. Changes to the Agreement</h2>
                    <p>We may update this User Agreement from time to time. Changes will be posted on our website, and your continued use of our services constitutes acceptance of the new terms.</p>

                    <h2>10. Governing Law</h2>
                    <p>This agreement is governed by the laws of the jurisdiction in which "Odoo Freelancer" operates.</p>

                    <p>By using "Odoo Freelancer," you acknowledge that you have read, understood, and agree to be bound by this User Agreement.</p>
                </div>
            </section>
            {/* <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Close</button>
            </footer> */}
        </div>
    );
}

export default UserAgreement;
