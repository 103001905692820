import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './TitleEdit.css';

const TitleEdit = ({ onClose }) => {
    const [jobTitle, setJobTitle] = useState('');
    const [jobId, setJobId] = useState(null);

    useEffect(() => {
        const storedJob = JSON.parse(localStorage.getItem('job'));
        if (storedJob) {
            setJobId(storedJob.jobId);
        }
    }, []);

    useEffect(() => {
        if (jobId) {
            fetchJobDetails(jobId);
        }
    }, [jobId]);

    const fetchJobDetails = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/job/details`, { job_id: id });
            console.log(response);

            if (response.data.result.status === 'success') {
                setJobTitle(response.data.result.job.title);
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
        }
    };

    const handleSaveButton = async () => {
        if (jobId && jobTitle) {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/job/update`, {
                job_id: jobId,
                title: jobTitle,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);

            if (response.data.result.status === 'success') {
                onClose();
            } else {
                alert('Error updating job details: ' + response.data.result.message);
            }
            return;
        }
    };

    return (
        <div className="job-title-edit-popup-main">
            <div className="job-title-edit-popup">
                <IoMdClose className="title-edit-pop-close" onClick={onClose} />
                <section className="title-section">
                    <div className="input-container">
                        <label>Write a title for your job post</label>
                        <input
                            type="text"
                            className="title-input"
                            placeholder="Title"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
        </div>
    )
}

export default TitleEdit