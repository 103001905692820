import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
// import { SnackbarProvider } from 'notistack';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';

const GOOGLE_CLIENT_ID = '1094927214987-gsin3atgsm19hsdjf0ugaa6jlhs68usn.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import { SnackbarProvider } from 'notistack';

// ReactDOM.render(
//   <SnackbarProvider maxSnack={3}>
//     <App />
//   </SnackbarProvider>,
//   document.getElementById('root')
// );
