import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchJob.css';
import { FaChevronDown, FaChevronUp, FaRegHeart, FaHeart, FaThumbsDown, FaRegThumbsDown } from "react-icons/fa";
import { IoIosFolderOpen } from "react-icons/io";
import { MdVerified } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { VscVerifiedFilled } from "react-icons/vsc";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


const SearchJob = () => {
  const [user, setUser] = useState(null);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [isJobTypeVisible, setIsJobTypeVisible] = useState(false);
  const [isExperienceVisible, setIsExperienceVisible] = useState(false);
  const [isProposalVisible, setIsProposalVisible] = useState(false);
  const [isClientInfoVisible, setIsClientInfoVisible] = useState(false);
  const [isClientHistoryVisible, setIsClientHistoryVisible] = useState(false);
  const [isClientLocationVisible, setIsClientLocationVisible] = useState(false);
  const [isClientTimezoneVisible, setIsClientTimezoneVisible] = useState(false);
  const [isProjectLengthVisible, setIsProjectLengthVisible] = useState(false);
  const [isWeekHoursVisible, setIsWeekHoursVisible] = useState(false);
  const [isJobDurationVisible, setIsJobDurationVisible] = useState(false);
  const [hourlyChecked, setHourlyChecked] = useState(false);
  const [fixedChecked, setFixedChecked] = useState(false);
  const [hourlyMin, setHourlyMin] = useState('');
  const [hourlyMax, setHourlyMax] = useState('');
  const [fixedMin, setFixedMin] = useState('');
  const [fixedMax, setFixedMax] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
  const [experienceFilters, setExperienceFilters] = useState([]);
  const [countryFilters, setCountryFilters] = useState('');
  const [projectLengthFilters, setProjectLengthFilters] = useState([]);


  const navigate = useNavigate();

  const toggleCategory = () => {
    setIsCategoryVisible(!isCategoryVisible);
  };

  const toggleJobType = () => {
    setIsJobTypeVisible(!isJobTypeVisible);
  };

  const toggleExperience = () => {
    setIsExperienceVisible(!isExperienceVisible);
  };

  const toggleProposals = () => {
    setIsProposalVisible(!isProposalVisible);
  };

  const toggleInfo = () => {
    setIsClientInfoVisible(!isClientInfoVisible);
  };

  const toggleHistory = () => {
    setIsClientHistoryVisible(!isClientHistoryVisible);
  };

  const toggleLocation = () => {
    setIsClientLocationVisible(!isClientLocationVisible);
  };

  const toggleTimezone = () => {
    setIsClientTimezoneVisible(!isClientTimezoneVisible);
  };

  const toggleProjectLength = () => {
    setIsProjectLengthVisible(!isProjectLengthVisible);
  };

  const toggleWeekHours = () => {
    setIsWeekHoursVisible(!isWeekHoursVisible);
  };

  const toggleJobDuration = () => {
    setIsJobDurationVisible(!isJobDurationVisible);
  };

  const handleHourlyChange = () => {
    setHourlyChecked(!hourlyChecked);
  };

  const handleFixedChange = () => {
    setFixedChecked(!fixedChecked);
  };

  const handleHourlyMinChange = (e) => {
    setHourlyMin(e.target.value);
  };

  const handleHourlyMaxChange = (e) => {
    setHourlyMax(e.target.value);
  };

  const handleFixedMinChange = (e) => {
    setFixedMin(e.target.value);
  };

  const handleFixedMaxChange = (e) => {
    setFixedMax(e.target.value);
  };

  const [filters, setFilters] = useState({
    category: '',
    experienceLevel: '',
    jobType: '',
    numberOfProposals: '',
    clientHistory: '',
    projectLength: '',
    hoursPerWeek: '',
    countries: []
  });

  const [jobs, setJobs] = useState([]);

  const fetchJobs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jobs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch jobs');
      }

      const data = await response.json();
      console.log(data);

      if (data.result.status === 'success') {
        setJobs(data.result.jobs);
      } else {
        throw new Error(data.result.message || 'Failed to fetch jobs');
      }
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
    }
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);
    fetchJobs();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleExperienceFilterChange = (event) => {
    const selectedExperience = event.target.value;
    setExperienceFilters(prevFilters =>
      prevFilters.includes(selectedExperience)
        ? prevFilters.filter(level => level !== selectedExperience)
        : [...prevFilters, selectedExperience]
    );
  };

  const handleProjectLengthChange = (event) => {
    const selectedLength = event.target.value;
    setProjectLengthFilters(prevFilters =>
      prevFilters.includes(selectedLength)
        ? prevFilters.filter(length => length !== selectedLength)
        : [...prevFilters, selectedLength]
    );
  };

  const handleCountryChange = (event) => {
    setCountryFilters(event.target.value);
  };

  const filteredJobs = jobs.filter(job => {
    const matchesSearchInput = job.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesExperienceLevel = experienceFilters.length === 0 || experienceFilters.includes(job.experienceLevel);
    const matchesCountry = countryFilters ? job.location === countryFilters : true;
    const matchesProjectLength = projectLengthFilters.length === 0 || projectLengthFilters.some(filter => {
      if (filter === 'lessThanOneMonth') {
        return job.projectDuration === 'Less than 1 Month';
      } else if (filter === 'oneToSixMonths') {
        return ['1-6 Months'].includes(job.projectDuration);
      } else if (filter === 'sevenToTwelveMonths') {
        return ['7-12 Months'].includes(job.projectDuration);
      } else if (filter === 'oneToTwoYears') {
        return ['1-2 Years'].includes(job.projectDuration);
      } else if (filter === 'moreThanTwoYears') {
        return ['More than 2 Years'].includes(job.projectDuration);
      }
      return false;
    });

    return matchesSearchInput && matchesExperienceLevel && matchesCountry && matchesProjectLength;
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const toggleFavorite = (index) => {
    setJobs((prevJobs) =>
      prevJobs.map((job, i) =>
        i === index ? { ...job, favorited: !job.favorited } : job
      )
    );
  };

  const toggleDislike = (index) => {
    setJobs((prevJobs) =>
      prevJobs.map((job, i) =>
        i === index ? { ...job, disliked: !job.disliked } : job
      )
    );
  };

  const handleSaveJob = () => {
    navigate('/freelancer/saved_jobs');
  }

  return (
    <div className="search-job">
      <Header />
      <div className='adv-search'>
        <input
          type="text"
          placeholder="Search here"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <p>Advance Search</p>

      </div>
      <main>
        <aside className="filters">
          <div className="filter-section">
            <div className="filter-header" onClick={toggleCategory}>
              <h5>Category</h5>
              <span>{isCategoryVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isCategoryVisible && (
              <select name="category" onChange={handleFilterChange}>
                <option value="">Select Category</option>
              </select>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleExperience}>
              <h5>Experience Level </h5>
              <span>{isExperienceVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isExperienceVisible && (
              <div className="filter-section">
                <div className="drop-check">
                  <label>
                    <input
                      type="checkbox"
                      value="Beginner"
                      onChange={handleExperienceFilterChange}
                      checked={experienceFilters.includes('Beginner')} />
                    Beginner
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="Intermediate"
                      onChange={handleExperienceFilterChange}
                      checked={experienceFilters.includes('Intermediate')} />
                    Intermediate
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="Expert"
                      onChange={handleExperienceFilterChange}
                      checked={experienceFilters.includes('Expert')} />
                    Expert
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleJobType}>
              <h5>Job type </h5>
              <span>{isJobTypeVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isJobTypeVisible && (
              <div className="filter-content">
                <div className="filter-section">
                  <label>
                    <input type="checkbox" checked={hourlyChecked} onChange={handleHourlyChange} />
                    Hourly
                  </label>
                  {hourlyChecked && (
                    <div className="hourly-rates">
                      <label>
                        <input type="checkbox" />
                        $<input type="number" placeholder="Min" value={hourlyMin} onChange={handleHourlyMinChange} />/hr
                      </label>
                      <label>
                        <input type="checkbox" />
                        $<input type="number" placeholder="Max" value={hourlyMax} onChange={handleHourlyMaxChange} />/hr
                      </label>
                      {/* <input type="number" placeholder="$ Min /hr" value={hourlyMin} onChange={handleHourlyMinChange} /> */}
                      {/* <input type="number" placeholder="$ Max /hr" value={hourlyMax} onChange={handleHourlyMaxChange} /> */}
                    </div>
                  )}
                </div>
                <div className="filter-section">
                  <label>
                    <input type="checkbox" checked={fixedChecked} onChange={handleFixedChange} />
                    Fixed
                  </label>
                  {fixedChecked && (
                    <div className="drop-check">
                      <label>
                        <input type="checkbox" />
                        Less than $100
                      </label>
                      <label>
                        <input type="checkbox" />
                        $100 to $500
                      </label>
                      <label>
                        <input type="checkbox" />
                        $500 - $1K
                      </label>
                      <label>
                        <input type="checkbox" />
                        $1K - $5K
                      </label>
                      <label>
                        <input type="checkbox" />
                        $5K+
                      </label>
                      <label>
                        <input type="checkbox" />
                        $<input type="number" placeholder="Min" value={fixedMin} onChange={handleFixedMinChange} />
                      </label>
                      <label>
                        <input type="checkbox" />
                        $<input type="number" placeholder="Max" value={fixedMax} onChange={handleFixedMaxChange} />
                      </label>
                      {/* <input type="number" placeholder="$ Min" value={fixedMin} onChange={handleFixedMinChange} /> */}
                      {/* <input type="number" placeholder="$ Max" value={fixedMax} onChange={handleFixedMaxChange} /> */}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleProposals}>
              <h5>Number of Proposals </h5>
              <span>{isProposalVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isProposalVisible && (
              <div className="filter-section">
                <div className="drop-check">
                  <label>
                    <input type="checkbox" />
                    Less than 5
                  </label>
                  <label>
                    <input type="checkbox" />
                    5 to 10
                  </label>
                  <label>
                    <input type="checkbox" />
                    10 to 15
                  </label>
                  <label>
                    <input type="checkbox" />
                    15 to 20
                  </label>
                  <label>
                    <input type="checkbox" />
                    20 to 50
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleInfo}>
              <h5>Client Info </h5>
              <span>{isClientInfoVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isClientInfoVisible && (
              <div className="filter-section">
                <div className="drop-check">
                  <label>
                    <input type="checkbox" />
                    My previous clients
                  </label>
                  <label>
                    <input type="checkbox" />
                    Payment Verified
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleHistory}>
              <h5>Client History </h5>
              <span>{isClientHistoryVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isClientHistoryVisible && (
              <div className="filter-section">
                <div className="drop-check">
                  <label>
                    <input type="checkbox" />
                    No hires
                  </label>
                  <label>
                    <input type="checkbox" />
                    1 to 9 hires
                  </label>
                  <label>
                    <input type="checkbox" />
                    10+ hires
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleLocation}>
              <h5>Client Locations </h5>
              <span>{isClientLocationVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isClientLocationVisible && (
              <select name="clientLocation" value={countryFilters} onChange={handleCountryChange}>
                <option value="">Select client locations</option>
                <option value="India">India</option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
                <option value="Japan">Japan</option>
              </select>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleTimezone}>
              <h5>Client time zones </h5>
              <span>{isClientTimezoneVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isClientTimezoneVisible && (
              <select name="clientTimezone" onChange={handleFilterChange}>
                <option value="">Select client time zones</option>
              </select>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleProjectLength}>
              <h5>Project Length </h5>
              <span>{isProjectLengthVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isProjectLengthVisible && (
              <div className="filter-section">
                <div className="drop-check">
                  <label>
                    <input
                      type="checkbox"
                      value="lessThanOneMonth"
                      onChange={handleProjectLengthChange}
                    />
                    Less than one month
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="oneToSixMonths"
                      onChange={handleProjectLengthChange}
                    />
                    1 to 6 months
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="sevenToTwelveMonths"
                      onChange={handleProjectLengthChange}
                    />
                    7 to 12 months
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="oneToTwoYears"
                      onChange={handleProjectLengthChange}
                    />
                    1 to 2 Years
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      value="moreThanTwoYears"
                      onChange={handleProjectLengthChange}
                    />
                    More than 2 Years
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleWeekHours}>
              <h5>Hours per week</h5>
              <span>{isWeekHoursVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isWeekHoursVisible && (
              <div className="filter-section">
                <div className="drop-check">
                  <label>
                    <input type="checkbox" />
                    20 to 30 hrs/week
                  </label>
                  <label>
                    <input type="checkbox" />
                    More than 30 hrs/week
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="filter-header" onClick={toggleJobDuration}>
              <h5>Job Duration</h5>
              <span>{isJobDurationVisible ? <FaChevronUp /> : <FaChevronDown />}</span>
            </div>
            {isJobDurationVisible && (
              <div className="filter-section">
                <div className="drop-check">
                  <label>
                    <input type="checkbox" />
                    Contract-to-hire roles
                  </label>
                </div>
              </div>
            )}
          </div>
        </aside>
        <section className="job-results">
          <div className="job-results-header">
            <h5 style={{ color: '#62495B' }}><IoIosFolderOpen /> &nbsp; Save Search</h5>
            <h5 style={{ color: '#62495B' }} onClick={handleSaveJob}><FaRegHeart /> &nbsp; Save Job</h5>
            <div className="sort-options">
              <select name="sort" onChange={handleFilterChange}>
                <option value="newest">Sort by: Newest</option>
                <option value="newest">Sort by: Popularity</option>
              </select>
            </div>
          </div>
          {filteredJobs.map((job, index) => (
            <div key={job.id} className="job-item">
              <div className='job-heading'>
                <div>
                  <h4>{job.title}</h4>
                  <span className='job-category'>Fixed-price - {job.experienceLevel} - Est. Budget : {job.hourly_rate}</span>
                </div>
                <div>
                  <button onClick={() => toggleDislike(index)} style={{ border: 'none' }}>
                    {job.disliked ? <FaThumbsDown style={{ color: '#7B1818', height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} /> : <FaRegThumbsDown style={{ height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} />}
                  </button>
                  {/* <AiOutlineDislike style={{ height: '30px', width: '30px', marginRight: '25px', marginTop: '10px' }} /> */}
                  <button onClick={() => toggleFavorite(index)} style={{ border: 'none' }}>
                    {job.favorited ? <FaHeart style={{ color: 'red', height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} /> : <FaRegHeart style={{ height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} />}
                  </button>
                  {/* <FaRegHeart style={{height:'30px',width:'25px',marginRight:'10px',marginTop:'10px'}}/> */}
                </div>
              </div>
              <p>{job.description}</p>
              <div className="job-tags">
                {job.skills.map((skill, index) => (
                  <span key={index} className="job-tag">{skill}</span>
                ))}
              </div>
              <div className="job-details">
                {job.paymentVerified && <span><VscVerifiedFilled style={{ color: '#62495B', height: '23px', width: '23px' }} /> &nbsp; Payment verified</span>}
                <span>{job.rating} stars</span>
                <span>{job.type}</span>
                <span>{job.clientHistory}</span>
                <span><IoLocationOutline />&nbsp; {job.location}</span>
              </div>
              <div className="job-rating">
                <span>Proposals :{job.proposals}</span>
                <span>{job.reviews} reviews</span>
              </div>
            </div>
          ))}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default SearchJob;
