import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsOfServices.css';
import logo from '../../assets/imgs/Odoo-Feelancer-LOGO.png';

const TermsOfServices = () => {
    const navigate = useNavigate();

    const handleBackButton = () => {
        navigate(-1);
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="tos-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
            </header>
            <section className="main-contents">
                <h1>Terms of Service for Odoo Freelancer</h1>
                <div className='content'>
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing and using the Odoo Freelancer website, you agree to comply with and be bound by these Terms of Service. If you do not agree with any part of these terms, you must not use our services.</p>

                    <h2>2. Services Offered</h2>
                    <p>Odoo Freelancer connects businesses with freelance developers and consultants specializing in Odoo ERP solutions. Services may include, but are not limited to:</p>
                    <ul>
                        <li>Odoo implementation</li>
                        <li>Custom module development</li>
                        <li>System integration</li>
                        <li>Training and support</li>
                    </ul>

                    <h2>3. User Registration</h2>
                    <p>To access certain features of the website, users may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

                    <h2>4. User Responsibilities</h2>
                    <p>Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>

                    <h2>5. Payment Terms</h2>
                    <p>All payments for services rendered through the platform must be made according to the specified payment methods. Users agree to pay all fees associated with their transactions and understand that fees may vary based on the services provided.</p>

                    <h2>6. Intellectual Property</h2>
                    <p>All content, trademarks, and other intellectual property on the Odoo Freelancer website are owned by or licensed to us. You may not use, reproduce, or distribute any content from the website without our prior written consent.</p>

                    <h2>7. Limitation of Liability</h2>
                    <p>Odoo Freelancer shall not be liable for any damages arising from the use or inability to use the website or services, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

                    <h2>8. Indentification</h2>
                    <p>You agree to indemnify and hold harmless Odoo Freelancer, its affiliates, and their respective officers, directors, employees, and agents from any claims, losses, liabilities, damages, costs, or expenses arising out of your use of the website or services.</p>

                    <h2>9. Modifications to Terms</h2>
                    <p>We reserve the right to modify these Terms of Service at any time. Changes will be effective immediately upon posting on the website. Your continued use of the services after any changes constitutes your acceptance of the new Terms of Service.</p>

                    <h2>10. Governing Law</h2>
                    <p>These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which Odoo Freelancer operates, without regard to its conflict of law principles.</p>

                    <h2>11. Contact Information</h2>
                    <p>For any questions or concerns regarding these Terms of Service, please contact us at info@o2b.co.in.</p>
                </div>
            </section>
            {/* <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Close</button>
            </footer> */}
        </div>
    );
}

export default TermsOfServices;
