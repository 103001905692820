import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Onboarding1.css';
import logo from '../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { TbUserQuestion } from "react-icons/tb";
import { MdOutlineToken, MdPaid } from "react-icons/md";
import { FaClipboardList,FaLaptopCode } from "react-icons/fa";
import { PiUserCircleFill } from "react-icons/pi";
import UserNav from '../Auth/UserNav/UserNav';

const Onboarding1 = () => {
    const navigate = useNavigate();
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const userName = storedUser ? storedUser.firstName.split(' ')[0] : 'Guest';

    // useEffect(() => {
    //     const storedUser = JSON.parse(localStorage.getItem('user'));
    //     if (storedUser && storedUser.userName) {
    //         setUserName(storedUser.userName);
    //     }
    // }, []);

    const handleContinueButton = () => {
        navigate('/freelancer/survey/1');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="onboarding-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img style={{ height: '65px'}} src={logo} alt="" />
                </div>
                <UserNav/>
            </header>
            <section className="main-contents">
                <section className="welcome-sections">
                    <h1 style={{ fontSize: '24px', marginBottom: '20px', width: '77%' }}>
                        Hey <span className="highlight">{userName}</span>, Ready for your next big opportunity?
                    </h1>
                    <ul className="opportunity-list">
                        <li><TbUserQuestion />&nbsp; &nbsp; Answer a few questions and start building your profile</li>
                        <li><MdOutlineToken />&nbsp; &nbsp; Get virtual tokens to bid on jobs, with a starter pack or subscription</li>
                        <li><FaClipboardList />&nbsp; &nbsp; Apply for open roles or list services for clients to buy</li>
                        <li><MdPaid />&nbsp; &nbsp; Get paid safely and know we're here to help</li>
                    </ul>
                    <button className="continue-buttons" onClick={handleContinueButton}>Continue</button>
                    <p className="footer-note" style={{ fontSize: '12px', color: '#666', marginRight: 'auto' }}>It only takes 5-10 minutes and you can edit it later. We'll save as you go.</p>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <div className="testimonial-name">Sebastian</div>
                            {/* <div className="testimonial-stats">
                                <span>5.0</span> <span>50.6k</span> <span>1.4k Jobs</span>
                            </div> */}
                        </div>
                        <p className="testimonial-text">
                            "Discovering Odoo Freelancer has transformed how I approach projects. The platform connects me with top-tier clients, making it seamless to showcase my skills and secure rewarding opportunities. With its user-friendly interface and robust support, Odoo Freelancer is my go-to for thriving in the freelance world."
                        </p>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default Onboarding1;

