import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientSurvey7.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import icon from '../../../assets/imgs/Upload.svg';
import testImg from '../../../assets/imgs/testimonial_person.jpg';

const ClientSurvey7 = () => {
    const [user, setUser] = useState(null);
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSupportDocument(storedUser.userId);
    }, []);

    const fetchSupportDocument = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/get_document_filename`, { user_id: userId });
            console.log("response of filename======", response);

            if (response.data.result.status === 'success') {
                setFileName(response.data.result.filename);
            } else {
                // toast.error(response.data.result.message);
            }
        } catch (error) {
            toast.error("Failed to fetch supporting document");
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            setFileName(file.name);

            const reader = new FileReader();
            reader.onload = () => {
                const fileData = reader.result.split(',')[1];
                const payload = {
                    user_id: user.userId,
                    file: fileData,
                    filename: file.name
                };

                axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/support_document`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((response) => {
                        if (response.data.result.status === 'success') {
                            toast.success(response.data.result.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {
                        toast.error('Error uploading file');
                        console.error('Error uploading files:', error);
                    });
            };
            reader.readAsDataURL(file);
        });
    }, [user]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleNextButton = () => {
        navigate('/client/preview');
    };

    const handleBackButton = () => {
        navigate('/client/survey_6');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-survey7-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="survey7-contents">
                <section className="survey7-section">
                    <div style={{ display: 'flex' }}>7/7</div><br />
                    <h2>Supporting Documents</h2>
                    <p>To provide additional context and support for your project, please upload any relevant documents.</p>
                    <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ? (
                                <p>Drop the files here ...</p>
                            ) : (
                                <>
                                    {fileName ? (
                                        <>
                                            <p>Selected file:</p>
                                            <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>{fileName}</p>
                                        </>
                                    ) : (
                                        <>
                                            <img src={icon} alt="" />
                                            <p>Drag & Drop files or Browse</p>
                                            <p>(pdf, jpg, png, word, ppt)</p>
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src={testImg} alt="Smith" className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Smith</div>
                                <span>Urban Builders</span>
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "Thanks to Odoo Freelancer, we found a developer who created a custom scheduling system for our construction sites. The process was smooth, and the results significantly improved our project timelines."
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default ClientSurvey7;
