import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLaptopCode, FaUserCog } from 'react-icons/fa';
import { RiLogoutBoxRFill } from "react-icons/ri";
import './UserNav.css';
import CloseAccountConfirmation from '../../Templates/CloseAccountConfirmation';

const UserNav = () => {
    const [user, setUser] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log("in navbar=======", storedUser);
    }, []);

    const handleToggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const openCloseAccountPopup = () => {
        setIsPopupOpen(true);
    };

    const closeCloseAccountPopup = () => {
        setIsPopupOpen(false);
    };

    const handleCloseAccount = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_id: user ? user.userId : null })
            });
            console.log("response from close account============", response);

            const result = await response.json();
            console.log("result===============", result);
            if (result.result.status === 'success') {
                localStorage.removeItem('user');
                localStorage.removeItem('experience');
                localStorage.removeItem('goal');
                localStorage.removeItem('workStyle');
                localStorage.removeItem('role');
                localStorage.removeItem('experiences');
                localStorage.removeItem('educationList');
                localStorage.removeItem('bio');
                localStorage.removeItem('selectedSkills');
                localStorage.removeItem('languages');
                localStorage.removeItem('hourlyRate');
                navigate('/');
            } else {
                console.error(result.message);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };


    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('experience');
        localStorage.removeItem('goal');
        localStorage.removeItem('workStyle');
        localStorage.removeItem('role');
        localStorage.removeItem('experiences');
        localStorage.removeItem('educationList');
        localStorage.removeItem('bio');
        localStorage.removeItem('selectedSkills');
        localStorage.removeItem('languages');
        localStorage.removeItem('hourlyRate');
        navigate('/');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    return (
        <div className="user-nav-container" ref={dropdownRef}>
            <div className="user-profile" onClick={handleToggleDropdown}>
                <div><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="2.5em" width="2.5em" xmlns="http://www.w3.org/2000/svg"><path d="M172,120a44,44,0,1,1-44-44A44.05,44.05,0,0,1,172,120Zm60,8A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88.09,88.09,0,0,0-91.47-87.93C77.43,41.89,39.87,81.12,40,128.25a87.65,87.65,0,0,0,22.24,58.16A79.71,79.71,0,0,1,84,165.1a4,4,0,0,1,4.83.32,59.83,59.83,0,0,0,78.28,0,4,4,0,0,1,4.83-.32,79.71,79.71,0,0,1,21.79,21.31A87.62,87.62,0,0,0,216,128Z"></path></svg></div>
                <div>
                    <div className="user-name">
                        &nbsp; {user ? user.firstName.split(' ')[0] : 'Guest'}
                    </div>
                    <div className="user-role">
                        {user ? user.role === 'freelancer' ? 'Odoo Freelancer' : 'Odoo Client' : ''} &nbsp;<FaLaptopCode />
                    </div>
                </div>
            </div>
            {dropdownOpen && (
                <div className="dropdown-nav-buttons">
                    <div className="dropdown-nav-item" onClick={openCloseAccountPopup}>
                        <FaUserCog /> Close Account
                    </div>
                    <div className="dropdown-nav-item" onClick={handleLogout}>
                        <RiLogoutBoxRFill /> Log Out
                    </div>
                </div>
            )}
            {isPopupOpen && (
                <CloseAccountConfirmation
                    onClose={closeCloseAccountPopup}
                    onSave={handleCloseAccount}
                />
            )}
        </div>
    );
};

export default UserNav;
