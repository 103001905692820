import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './ScopeEdit.css';
import { MdEdit } from "react-icons/md";

const ScopeEdit = ({ onClose }) => {

    const [jobId, setJobId] = useState(null);
    const [selectedOption, setSelectedOption] = useState({
        size: '',
        detail: '',
    });
    const [selectedTimeframe, setSelectedTimeframe] = useState('');
    const [selectedExperience, setSelectedExperience] = useState({
        experience: '',
        detail: '',
    });

    const [isSizeEditing, setIsSizeEditing] = useState(true);
    const [isTimeEditing, setIsTimeEditing] = useState(true);
    const [isExperienceEditing, setIsExperienceEditing] = useState(true);

    const sizeDetails = {
        'Large': 'Longer term or complex initiatives (e.g., design and build a full website)',
        'Medium': 'Well-defined projects (e.g., a landing page)',
        'Small': 'Quick and straightforward tasks (e.g., update text and images on a webpage)',
    };

    const experienceDetails = {
        'Entry': 'Looking for someone relatively new to this field',
        'Intermediate': 'Looking for substantial experience in this field',
        'Expert': 'Looking for comprehensive and deep expertise in this field',
    };

    useEffect(() => {
        const storedJob = JSON.parse(localStorage.getItem('job'));
        setJobId(storedJob?.jobId);
    }, []);

    useEffect(() => {
        if (jobId) {
            fetchJobDetails(jobId);
        }
    }, [jobId]);

    const fetchJobDetails = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/job/details`, { job_id: id });
            if (response.data.result.status === 'success') {
                if (response.data.result.job.scope !== '') {
                    updateScopeStates(response.data.result.job.scope);
                }
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
        }
    };

    const updateScopeStates = (scope) => {
        const [size, timeframe, experience] = scope.split(' | ');
        setSelectedOption({ size, detail: sizeDetails[size] || '' });
        setSelectedTimeframe(timeframe || '');
        setSelectedExperience({ experience, detail: experienceDetails[experience] || '' });
        setIsSizeEditing(false);
        setIsTimeEditing(false);
        setIsExperienceEditing(false);
    };

    const handleSizeOptionChange = (e, detail) => {
        setSelectedOption({
            size: e.target.value,
            detail: detail,
        });
        setIsSizeEditing(false);
    };

    const handleTimeframeChange = (event) => {
        setSelectedTimeframe(event.target.value);
        setIsTimeEditing(false);
    };

    const handleExperienceChange = (e, detail) => {
        setSelectedExperience({
            experience: e.target.value,
            detail: detail,
        });
        setIsExperienceEditing(false);
    };

    const handleEdit = () => {
        setIsSizeEditing(true);
    };

    const handleTimeEdit = () => {
        setIsTimeEditing(true);
    };

    const handleExpEdit = () => {
        setIsExperienceEditing(true);
    };

    const scope = `${selectedOption.size} | ${selectedTimeframe} | ${selectedExperience.experience}`;

    const handleSaveButton = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/job/update`, { scope, job_id: jobId }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.data.result.status === 'success') {
            onClose();
        } else {
            console.error('Failed to update scope:', response.data.result.message);
        }
    };

    return (
        <div className="job-scope-edit-popup-main">
            <div className="job-scope-edit-popup">
                <IoMdClose className="scope-edit-pop-close" onClick={onClose} />
                <section className="scope-section">
                    {isSizeEditing ? (
                        <div className="project-size">
                            <label>
                                <input
                                    type="radio"
                                    name="projectSize"
                                    value='Large'
                                    checked={selectedOption.size === 'Large'}
                                    onChange={(e) => handleSizeOptionChange(e, sizeDetails['Large'])}
                                />
                                Large
                            </label>
                            <p className="label-detail">Longer term or complex initiatives (e.g., design and build a full website)</p>
                            <label>
                                <input
                                    type="radio"
                                    name="projectSize"
                                    value='Medium'
                                    checked={selectedOption.size === 'Medium'}
                                    onChange={(e) => handleSizeOptionChange(e, sizeDetails['Medium'])}
                                />
                                Medium
                            </label>
                            <p className="label-detail">Well-defined projects (e.g., a landing page)</p>
                            <label>
                                <input
                                    type="radio"
                                    name="projectSize"
                                    value='Small'
                                    checked={selectedOption.size === 'Small'}
                                    onChange={(e) => handleSizeOptionChange(e, sizeDetails['Small'])}
                                />
                                Small
                            </label>
                            <p className="label-detail">Quick and straightforward tasks (e.g., update text and images on a webpage)</p>
                        </div>
                    ) : (
                        <div className="project-size">
                            <div className="selected-option">
                                <div className='edit-btn-div'>
                                    <div>
                                        <span className='selected-size'>{selectedOption.size}</span>
                                        <p className='selected-detail'>{selectedOption.detail}</p>
                                    </div>
                                    <button className="edit-button" onClick={handleEdit}><MdEdit /></button>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedOption.size !== '' && (isTimeEditing ?
                        (
                            <div className='project-time'>
                                <p>How long will your work take?</p>
                                <div className='project-time-options'>
                                    <label>
                                        <input
                                            type="radio"
                                            name="timeframe"
                                            value='More than 6 months'
                                            checked={selectedTimeframe === 'More than 6 months'}
                                            onChange={handleTimeframeChange}
                                        />
                                        More than 6 months
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="timeframe"
                                            value='3 to 6 months'
                                            checked={selectedTimeframe === '3 to 6 months'}
                                            onChange={handleTimeframeChange}
                                        />
                                        3 to 6 months
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="timeframe"
                                            value='1 to 3 months'
                                            checked={selectedTimeframe === '1 to 3 months'}
                                            onChange={handleTimeframeChange}
                                        />
                                        1 to 3 months
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <div className='project-time'>
                                <div className='edit-btn-div'>
                                    <p className='selected-time'>{selectedTimeframe}</p>
                                    <button className="edit-button" onClick={handleTimeEdit}><MdEdit /></button>
                                </div>
                            </div>
                        ))}

                    {selectedTimeframe !== '' && (isExperienceEditing ?
                        (<div className='project-experience'>
                            <h6>What level of experience will it need?</h6>
                            <span>This won't restrict any proposals, but helps match expertise to your budget.</span>
                            <div className='project-exp-options'>
                                <label>
                                    <input
                                        type="radio"
                                        name="projectExp"
                                        value='Entry'
                                        checked={selectedExperience.experience === 'Entry'}
                                        onChange={(e) => handleExperienceChange(e, experienceDetails['Entry'])}
                                    />
                                    Entry
                                </label>
                                <p className='label-detail'>Looking for someone relatively new to this field</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="projectExp"
                                        value='Intermediate'
                                        checked={selectedExperience.experience === 'Intermediate'}
                                        onChange={(e) => handleExperienceChange(e, experienceDetails['Intermediate'])}
                                    />
                                    Intermediate
                                </label>
                                <p className='label-detail'>Looking for substantial experience in this field</p>
                                <label>
                                    <input
                                        type="radio"
                                        name="projectExp"
                                        value='Expert'
                                        checked={selectedExperience.experience === 'Expert'}
                                        onChange={(e) => handleExperienceChange(e, experienceDetails['Expert'])}
                                    />
                                    Expert
                                </label>
                                <p className='label-detail'>Looking for comprehensive and deep expertise in this field</p>
                            </div>
                        </div>) : (
                            <div className='project-experience'>
                                <div className='edit-btn-div'>
                                    <div>
                                        <h6 className='selected-exp'>{selectedExperience.experience}</h6>
                                        <p className='selected-detail'>{selectedExperience.detail}</p>
                                    </div>
                                    <button className="edit-button" onClick={handleExpEdit}><MdEdit /></button>
                                </div>
                            </div>
                        ))}
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
        </div>
    )
}

export default ScopeEdit;