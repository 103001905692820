import React from 'react';
import styles from './EmailSent.module.css';
import logo from '../../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { useNavigate } from 'react-router-dom';
import illustration from '../../../../assets/imgs/sentbro.png';

const EmailSent = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img style={{ height: '80px' }} src={logo} alt="Logo" />
        </div>
      </header>
      <div className={styles.card}>
        <h2 className={styles.cardTitle}>Email Sent</h2>
        <div className={styles.imageContainer}>
          <img src={illustration} alt="Email Sent" />
        </div>
        <p className={styles.cardText}>
          We've sent an email to your email address. Follow the steps provided in the email to update your password or select Log in if you don't want to change your password at this time.
        </p>
        <div className={styles.buttons}>
          <button type="submit" className={styles.loginButton} onClick={handleLoginClick}>
            Login
          </button>
        </div>
      </div>
      <footer className={styles.footer}>
        <p>
          © 2024 Odoo. <a href="/privacy-policy" className={styles.footerLink}>Privacy Policy</a>
        </p>
      </footer>
    </div>
  );
};

export default EmailSent;
