import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './MaintenancePopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MaintenancePopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [implementingSupports, setImplementingSupports] = useState('');
    const [managingPlan, setManagingPlan] = useState(null);
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setImplementingSupports(response.data.result.questions[14].answer);
            setManagingPlan(response.data.result.questions[15].answer);
        }
    };

    const handleSaveButton = async () => {
        if (!implementingSupports || !managingPlan) {
            toast.error('All questions are mandatory.');
            return;
        }

        const answers = [
            { question_id: questions[14].id, answer: implementingSupports },
            { question_id: questions[15].id, answer: managingPlan },
        ];

        for (let answer of answers) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                survey_user_input_id: surveyUserInputId,
                question_id: answer.question_id,
                answer: answer.answer,
                user_id: user.userId,
            });
        }
        onClose();
    };

    return (
        <div className='maintenance-edit-popup-main'>
            <div className="maintenance-edit-popup">
                <IoMdClose className="maintenance-edit-pop-close" onClick={onClose} />
                <section className="maintenance-sections">
                    <form>
                        <div className="question">
                            <label>Would you like to have post implementation support ?</label>
                            <div className="options">
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="Less than 1 Month"
                                        checked={implementingSupports === 'Less than 1 Month'}
                                        onChange={() => setImplementingSupports('Less than 1 Month')}
                                    />
                                    Less than 1 month
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="1-6 Months"
                                        checked={implementingSupports === '1-6 Months'}
                                        onChange={() => setImplementingSupports('1-6 Months')}
                                    />
                                    1-6 Months
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="1-2 Years"
                                        checked={implementingSupports === '1-2 Years'}
                                        onChange={() => setImplementingSupports('1-2 Years')}
                                    />
                                    1-2 Years
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="More than 2 Years"
                                        checked={implementingSupports === 'More than 2 Years'}
                                        onChange={() => setImplementingSupports('More than 2 Years')}
                                    />
                                    More than 2 years
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="implementingSupport"
                                        value="No"
                                        checked={implementingSupports === 'No'}
                                        onChange={() => setImplementingSupports('No')}
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                        <div className="question">
                            <label>What is your plan for managing ongoing maintenance and updates to the system?</label>
                            <textarea
                                placeholder="Tell us your plan"
                                value={managingPlan}
                                onChange={(e) => setManagingPlan(e.target.value)}
                            />
                        </div>
                    </form>
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default MaintenancePopup;