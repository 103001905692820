import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ClientProfilePrev.css';
import axios from 'axios';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import sideImg from '../../../assets/imgs/Rectangle 97.png';
import { FaFileAlt } from "react-icons/fa";
import ClientNavbar from '../ClientNavbar/ClientNavbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientProfilePrev = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [odooPurchased, setOdooPurchased] = useState(null);
    const [odooUsageDuration, setOdooUsageDuration] = useState(null);
    const [customizationsMade, setCustomizationsMade] = useState(null);
    const [customizationDetails, setCustomizationDetails] = useState('');
    const [currentSystems, setCurrentSystems] = useState('');
    const [implementation, setImplementation] = useState('');
    const [integrationDetails, setIntegrationDetails] = useState('');
    const [implementingSupport, setImplementingSupport] = useState('');
    const [managingPlan, setManagingPlan] = useState(null);
    const [timeline, setTimeline] = useState('');
    const [budget, setBudget] = useState('');
    const [filenames, setFilenames] = useState([]);
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    const [showMaintenanceQue, setShowMaintenanceQue] = useState(false);
    const [showCustomizeDetailQue, setShowCustomizeDetailQue] = useState(false);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
        fetchSupportDocument(storedUser.userId);
    }, []);

    const fetchSupportDocument = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/get_document_filename`, { user_id: userId });
            console.log("response of filename======", response);

            if (response.data.result.status === 'success') {
                setFilenames(response.data.result.filenames);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            toast.error("Failed to fetch supporting document");
        }
    };

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setOdooPurchased(response.data.result.questions[4].answer);
            setOdooUsageDuration(response.data.result.questions[5].answer);
            setCustomizationsMade(response.data.result.questions[6].answer);
            setCustomizationDetails(response.data.result.questions[7].answer);
            setCurrentSystems(response.data.result.questions[11].answer);
            setImplementation(response.data.result.questions[12].answer);
            setIntegrationDetails(response.data.result.questions[13].answer);
            setImplementingSupport(response.data.result.questions[14].answer);
            setManagingPlan(response.data.result.questions[15].answer);
            setTimeline(response.data.result.questions[16].answer);
            setBudget(response.data.result.questions[17].answer);
        } else {
            toast.error(response.data.result.message);
        }
    };

    useEffect(() => {
        setShowMaintenanceQue(odooPurchased === 'Yes');
    }, [odooPurchased]);

    useEffect(() => {
        setShowCustomizeDetailQue(customizationsMade === 'Yes');
    }, [customizationsMade]);

    const handleFileDelete = async (fileId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/delete_file`, {
                file_id: fileId,
            });

            if (response.data.result.status === 'success') {
                toast.success("File deleted successfully.");
                fetchSupportDocument(user.userId);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            toast.error("Failed to delete the file.");
        }
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-profile-prev-container">
            <header className="client-profile-prev-header">
                <img src={logo} alt="Logo" className="client-profile-prev-logo" onClick={handleLogoClick} />
                <ClientNavbar />
            </header>
            {/* <div className='main-heading'>Project Preview</div> */}
            <section className="client-profile-prev-banner">
                <div className="banner-content">
                    <h2 style={{ color: '#62495B', fontWeight: '700' }}>Thank You, {user ? user.firstName : 'Guest'}!</h2>
                </div>
            </section>
            <main className="client-profile-prev-main">
                <section className="client-details">
                    <div className="customization-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Odoo Usage and Customization</h3>
                        </div>
                        <div>
                            <p className='heading' >Have you already purchased Odoo?</p>
                            <p className='content'>{odooPurchased}</p>
                        </div>
                        {showMaintenanceQue && <div>
                            <p className='heading'>Since when you are using Odoo?</p>
                            <p className='content'>{odooUsageDuration}</p>
                        </div>}
                        {showMaintenanceQue && <div>
                            <p className='heading'>Have you made any customizations to Odoo?</p>
                            <p className='content'>{customizationsMade}</p>
                        </div>}
                        {showMaintenanceQue && showCustomizeDetailQue && <div>
                            <p className='heading'>Customization details</p>
                            <p className='content'>{customizationDetails}</p>
                        </div>}
                    </div>
                    <div className="systems-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Current Systems and Odoo Implementation Goals </h3>
                        </div>
                        <div>
                            <p className='heading'>Current System</p>
                            <p className='content'>{currentSystems}</p>
                        </div>
                        <div>
                            <p className='heading'>Implementation Goals</p>
                            <p className='content'>{implementation}</p>
                        </div>
                    </div>
                    <div className="integration-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Odoo Integration</h3>
                        </div>
                        <div>
                            <p className='content'>{integrationDetails}</p>
                        </div>
                    </div>
                    <div className="maintenance-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>System Maintenance and Updates</h3>
                        </div>
                        <div>
                            <p className='heading'>Implementation support </p>
                            <p className='content'>{implementingSupport}</p>
                        </div>
                        <div>
                            <p className='heading'>maintenance and updates plan</p>
                            <p className='content'>{managingPlan}</p>
                        </div>
                    </div>
                    <div className="budget-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Project Timeline and Budget</h3>
                        </div>
                        <div>
                            <p className='heading'>Preferred Project Timeline</p>
                            <p className='content'>{timeline}</p>
                        </div>
                        <div>
                            <p className='heading'>Your Budget</p>
                            <p className='content'>$ {budget}</p>
                        </div>
                    </div>
                </section>
                <section className='supporting-doc'>
                    <div className="doc-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Supporting Documents</h3>
                        </div>

                        {filenames && filenames.map((file) => (
                            <div key={file.id} className="selected-files">
                                <span className='doc_filename'><FaFileAlt /> &nbsp;{file.name}</span>
                                {/* <button className='remove-button' onClick={() => handleFileDelete(file.id)}>x</button> */}
                            </div>
                        ))}

                    </div>
                </section>
            </main>
            <ToastContainer />
        </div>
    );
};

export default ClientProfilePrev;
