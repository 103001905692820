import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './CustomizationPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomizationPopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [odooPurchased, setOdooPurchased] = useState(null);
    const [odooUsageDuration, setOdooUsageDuration] = useState(null);
    const [customizationsMade, setCustomizationsMade] = useState(null);
    const [customizationDetails, setCustomizationDetails] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [showRemainingQue, setShowRemainingQue] = useState(false);
    const [showCustomizeDetailQue, setShowCustomizeDetailQue] = useState(false);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    useEffect(() => {
        setShowRemainingQue(odooPurchased === 'Yes');
    }, [odooPurchased]);

    useEffect(() => {
        setShowCustomizeDetailQue(customizationsMade === 'Yes');
    }, [customizationsMade]);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setOdooPurchased(response.data.result.questions[4].answer);
            setOdooUsageDuration(response.data.result.questions[5].answer);
            setCustomizationsMade(response.data.result.questions[6].answer);
            setCustomizationDetails(response.data.result.questions[7].answer);
        }
    };

    const handleSaveButton = async () => {
        if (!odooPurchased || !odooUsageDuration || !customizationsMade || !customizationDetails) {
            toast.error('All questions are mandatory.');
            return;
        }

        const answers = [
            { question_id: questions[4].id, answer: odooPurchased },
            { question_id: questions[5].id, answer: odooUsageDuration },
            { question_id: questions[6].id, answer: customizationsMade },
            { question_id: questions[7].id, answer: customizationDetails },
        ];

        for (let answer of answers) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                survey_user_input_id: surveyUserInputId,
                question_id: answer.question_id,
                answer: answer.answer,
                user_id: user.userId,
            });
        }
        onClose();
    };

    return (
        <div className='customization-detail-edit-popup-main'>
            <div className="customization-detail-edit-popup">
                <IoMdClose className="customization-edit-pop-close" onClick={onClose} />
                <section className="customizations-section">

                    <form>
                        <div style={{ display: 'flex', gap: '15%' }}>
                            <div className="question">
                                <label>Have you already purchased Odoo?</label>
                                <div className="options">
                                    <label>
                                        <input
                                            type="radio"
                                            name="odooPurchased"
                                            value='Yes'
                                            checked={odooPurchased === 'Yes'}
                                            onChange={() => setOdooPurchased('Yes')}
                                        />
                                        Yes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="odooPurchased"
                                            value='No'
                                            checked={odooPurchased === 'No'}
                                            onChange={() => setOdooPurchased('No')}
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                            {showRemainingQue && (
                                <div className="question">
                                    <label>Since when are you using Odoo?</label>
                                    <div className="options">
                                        <label>
                                            <input
                                                type="radio"
                                                name="odooUsageDuration"
                                                value='Less than 1 Month'
                                                checked={odooUsageDuration === 'Less than 1 Month'}
                                                onChange={() => setOdooUsageDuration('Less than 1 Month')}
                                            />
                                            Less than 1 month
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="odooUsageDuration"
                                                value='1-6 Months'
                                                checked={odooUsageDuration === '1-6 Months'}
                                                onChange={() => setOdooUsageDuration('1-6 Months')}
                                            />
                                            1-6 Months
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="odooUsageDuration"
                                                value='1-2 Years'
                                                checked={odooUsageDuration === '1-2 Years'}
                                                onChange={() => setOdooUsageDuration('1-2 Years')}
                                            />
                                            1-2 Years
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                name="odooUsageDuration"
                                                value='More than 2 Years'
                                                checked={odooUsageDuration === 'More than 2 Years'}
                                                onChange={() => setOdooUsageDuration('More than 2 Years')}
                                            />
                                            More than 2 years
                                        </label>
                                    </div>
                                </div>
                            )}

                        </div>

                        {showRemainingQue && (
                            <div className="question">
                                <label>Have you made any customizations to Odoo?</label>
                                <div className="options">
                                    <label>
                                        <input
                                            type="radio"
                                            name="customizationsMade"
                                            value='Yes'
                                            checked={customizationsMade === 'Yes'}
                                            onChange={() => setCustomizationsMade('Yes')}
                                        />
                                        Yes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="customizationsMade"
                                            value='No'
                                            checked={customizationsMade === 'No'}
                                            onChange={() => setCustomizationsMade('No')}
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                        )}

                        {showRemainingQue && showCustomizeDetailQue && (
                            <div className="question">
                                <label>Could you provide details about the customization?</label>
                                <textarea
                                    placeholder="Describe the customization"
                                    value={customizationDetails}
                                    onChange={(e) => setCustomizationDetails(e.target.value)}
                                />
                            </div>
                        )}

                    </form>

                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default CustomizationPopup;
