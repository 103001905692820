import React from 'react';
import './ClientAccount.css';
import Header from '../../../Header/Header';
import { GoAlertFill } from "react-icons/go";
import Footer from '../../../Footer/Footer';

const ClientAccount = () => {
    return (
        <div className="client-account-container">
            <Header />

            <main className="client-account-main">
                <h1>Create a client account</h1>
                <div className="alert-box">
                    <span className="alert-icon"><GoAlertFill /></span>
                    You must set up your&nbsp;<a href=''> billing method</a>&nbsp;before you can create a new client account.
                </div>
                <p>Setup a client account if you want to post jobs and hire talents.</p>

                <div className="form-group">
                    <label htmlFor="companyName">Company Name</label>
                    <input type="text" id="companyName" placeholder="Enter Name" />
                    <div>
                        Your company name will NOT appear on job posts unless you have previously worked with the talent or agency on Odoo Freelancer.
                    </div>
                </div>

                <div className="form-actions">
                    <button className="cancel-button">Cancel</button>
                    <button className="create-button">Create Client Account</button>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default ClientAccount;
