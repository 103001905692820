import React from 'react';
import './Footer.css';
import { FaTwitter, FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import appStore from '../../../assets/imgs/app-store.png';
import playStore from '../../../assets/imgs/play-store.png';

const Footer = () => {
    return (
        <footer className="footer-main">

            <div className="footer-links">
                <div className="footer-logo"><img src={logo} alt="" /></div>
                <div>
                    <a href="">About Us</a>
                    <a href="">Feedback</a>
                    <a href="">Community</a>
                </div>
                <div>
                    <a href="">Trust, Safety & Security</a>
                    <a href="">Help & Support</a>
                    <a href="">Foundation</a>
                </div>
                <div>
                    <a href="">Accessibility</a>
                    <a href="">Desktop App</a>
                    <a href="">Enterprise Solutions</a>
                </div>
                <div className="footer-apps">
                    <p>Apps</p>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <a href=""><img src={appStore} alt="" /></a>
                        <a href=""><img src={playStore} alt="" /></a>
                    </div>
                </div>
            </div>
            <div className="footer-socials">
                <p>Follow Us On</p>
                <a href=""><FaFacebook /></a>
                <a href=""><FaTwitter /></a>
                <a href=""><FaYoutube /></a>
                <a href=""><FaInstagram /></a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="footer-copyright">
                    &copy; 2024 Odoo Freelancer. All rights reserved.
                </div>
                <div className='footer-policy'>
                    <a href="">Cookie Policy</a>
                    <a href="">Cookie Settings</a>
                    <a href="">CA Notice at Collection</a>
                    <a href="">Privacy Policy</a>
                    <a href="">Terms & Condition</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer