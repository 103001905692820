import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoMdInformationCircleOutline } from "react-icons/io";
import './Role.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Role = () => {
    const [role, setRole] = useState('');
    const [error, setError] = useState('');
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    // useEffect(() => {
    //     const storedUser = JSON.parse(localStorage.getItem('user'));
    //     setUser(storedUser);
    //     console.log("stored user==============", storedUser);

    //     const storedRole = localStorage.getItem('role');
    //     if (storedRole) {
    //         setRole(storedRole);
    //     }
    // }, []);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_emp_role';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetch request options==========", requestOptions);

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setRole(data.result.role_name);
                        console.log('role from backend==========', data.result.role_name);
                    } else {
                        console.error('Error fetching role=======', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching role======', error);
                });
        }
    }, []);

    // useEffect(() => {
    //     localStorage.setItem('role', role);
    // }, [role]);

    const handleBackButton = () => {
        navigate('/freelancer/profile');
    };

    const handleNextButton = async () => {
        if (!user || !user.userId) {
            toast.error('User not logged in');
            return;
        }

        try {
            console.log('making request to update job position=============');
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update_job_position`, {
                user_id: user.userId,
                job_position: role
            });
            console.log("response in role ===============", response);

            if (response.data.result.status === 'success') {
                navigate('/freelancer/profile/experience');
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error updating job position:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    return (
        <>
            <div className="role-container">
                <header className="header">
                    <div className="logo" onClick={handleLogoClick}>
                        <img style={{ height: '65px' }} src={logo} alt="Logo" />
                    </div>
                    <UserNav />
                </header>
                <main className="main-content">
                    <section className="question-section">
                        <div style={{ display: 'flex' }}>2/10</div><br /><br />
                        <h1 style={{ fontSize: '30px', display: 'flex' }}>
                            Got it. Now add a title to tell the world what you do.
                        </h1>
                        <p className="footer-note">It is very first thing clients see, so make it count. Stand out by describing your expertise.</p>
                        <div>
                            <div className="input">
                                <label>Your Professional Role</label>
                                <input
                                    type="text"
                                    name="role"
                                    placeholder='Software Developer, etc.'
                                    value={role}
                                    onChange={handleRoleChange}
                                    required
                                />
                                <p className='message'><IoMdInformationCircleOutline /> A descriptive title must have at least 4 letters.</p>
                            </div>
                        </div>
                    </section>
                </main>
                <div className="navigation-buttons">
                    <button className="back-button" onClick={handleBackButton}>Back</button>
                    <div className="skip-next-buttons">
                        <button className="next-button" onClick={handleNextButton}>Next, add your experience</button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
};

export default Role;
