import React, { useState, useEffect } from 'react';
import './ExpEditPop.css';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import ExpPopUp from '../Templates/ExpPopUp';

const ExpEditPop = ({ onClose, onExperienceChange }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [experiences, setExperiences] = useState([]);
    const [currentExperience, setCurrentExperience] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage:', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_experience';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetch===============", requestOptions);
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.success) {
                        setExperiences(data.result.experiences);
                        console.log('Loaded experiences from backend:', data.result.experiences);
                    } else {
                        console.error('Error fetching experiences:', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching experiences:', error);
                });
        }
    }, []);;

    const handleAddExperience = () => {
        setCurrentExperience(null);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleSaveExperience = async (updatedExperience) => {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        let endpoint = '/create_experience';
    
        if (currentExperience !== null) {
            endpoint = '/update_experience';
        }
    
        const response = await fetch(`${apiUrl}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.userId,
                experience_id: currentExperience !== null ? experiences[currentExperience].id : null,
                title: updatedExperience.title,
                company: updatedExperience.company,
                start_date: updatedExperience.startDate,
                end_date: updatedExperience.endDate,
                location: updatedExperience.location,
                description: updatedExperience.description,
            }),
        });
    
        const result = await response.json();
    
        if (result.result.status === 'success') {
            let updatedList;
            if (currentExperience !== null) {
                updatedList = experiences.map((exp, index) =>
                    index === currentExperience ? { ...updatedExperience, id: exp.id } : exp
                );
                onExperienceChange(updatedList);
            } else {
                updatedList = [...experiences, { ...updatedExperience, id: result.result.experience_id }];
                onExperienceChange(updatedList);
            }
            
            setExperiences(updatedList);
            localStorage.setItem('experiences', JSON.stringify(updatedList));
            setIsPopupOpen(false);
        } else {
            console.error('Error saving experience:', result.result.message);
        }
    };


    const handleEditExperience = (index) => {
        setCurrentExperience(index);
        setIsPopupOpen(true);
    };

    const handleDeleteExperience = async (index) => {
        const experienceToDelete = experiences[index];
        console.log("================", experienceToDelete);
        if (!experienceToDelete) {
            console.error('Experience to delete not found');
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_experience`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.userId,
                experience_id: experienceToDelete.id,
            }),
        });

        console.log("response in experience=================", response);
        const result = await response.json();

        console.log('Response from server===============', result);

        if (result.result.status === 'success') {
            console.log('Experience deleted successfully');
            const updatedExperiences = experiences.filter((_, i) => i !== index);
            setExperiences(updatedExperiences);
            localStorage.setItem('experiences', JSON.stringify(updatedExperiences));
            onExperienceChange(updatedExperiences);
            console.log('Deleted experience and updated localStorage:', updatedExperiences);
        } else {
            console.error('Error deleting experience:', result.result.message);
        }
    };



    return (
        <>
            <div className="popup-backdrop" onClick={onClose}></div>
            <main className="experience-main-content">
                <div className="experience-container">
                    <h2>Here's what you’ve told us about your experience — any more to add?</h2>
                    {experiences.length > 0 ? (
                        experiences.map((experience, index) => (
                            <div className="experience-card" key={index}>
                                <div className="experience-card-header">
                                    <h3>{experience.title}</h3>
                                    <div className="experience-card-icons">
                                        <span className="edit-icon" onClick={() => handleEditExperience(index)}><FaEdit /></span>
                                        <span className="delete-icon" onClick={() => handleDeleteExperience(index)}><MdDeleteForever /></span>
                                    </div>
                                </div>
                                <p>{experience.company} | {experience.startDate} - {experience.endDate}</p>
                                <p>{experience.location}</p>
                                <p className="experience-description">{experience.description}</p>
                            </div>
                        ))
                    ) : (
                        <p>No experience records found. Please add your experience details.</p>
                    )}
                    <button className="add-experience-btn" onClick={handleAddExperience}>+</button>
                </div>
                <div>
                    <button className='save-btn' onClick={onClose}>Save</button>
                </div>
            </main>
            {isPopupOpen && (
                <ExpPopUp
                    onClose={handleClosePopup}
                    onSave={handleSaveExperience}
                    experience={currentExperience !== null ? experiences[currentExperience] : null}
                />
            )}
        </>
    );
}

export default ExpEditPop;