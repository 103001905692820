import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './AboutCompanyPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AboutCompanyPopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [companyDetails, setCompanyDetails] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setCompanyDetails(response.data.result.questions[8].answer);
        }
    };

    const handleSaveButton = async () => {

        if (!companyDetails) {
            toast.error('All questions are mandatory.');
            return;
        }

        const answers = [
            { question_id: questions[8].id, answer: companyDetails },
        ];

        for (let answer of answers) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                survey_user_input_id: surveyUserInputId,
                question_id: answer.question_id,
                answer: answer.answer,
                user_id: user.userId,
            });
        }
        onClose();
    };

    return (
        <div className="company-about-edit-popup-main">
            <div className="company-about-edit-popup">
                <IoMdClose className="about-edit-pop-close" onClick={onClose} />
                <section className="about-section">

                    <form>
                        <div className="question">
                            <label>Could you give a summary of your company and its operations?</label>
                            <textarea
                                placeholder="Describe"
                                value={companyDetails}
                                onChange={(e) => setCompanyDetails(e.target.value)}
                            />
                        </div>
                    </form>

                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default AboutCompanyPopup;
