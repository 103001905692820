import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './FileEditPopup.css';
import icon from '../../../assets/imgs/Upload.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FileEditPopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSupportDocument(storedUser.userId);
    }, []);

    const fetchSupportDocument = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/get_document_filename`, { user_id: userId });
            console.log("response of filename======", response);

            if (response.data.result.status === 'success') {
                setFileName(response.data.result.filename);
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error("Failed to fetch supporting document");
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            setFileName(file.name);

            const reader = new FileReader();
            reader.onload = () => {
                const fileData = reader.result.split(',')[1];
                const payload = {
                    user_id: user.userId,
                    file: fileData,
                    filename: file.name
                };

                axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/support_document`, payload, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((response) => {
                        if (response.data.result.status === 'success') {
                            toast.success(response.data.result.message);
                        } else {
                            // toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {
                        // toast.error('Error uploading file');
                        console.error('Error uploading files:', error);
                    });
            };
            reader.readAsDataURL(file);
        });
    }, [user]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleSaveButton = async () => {
        onClose();
    };

    return (
        <div className="doc-edit-popup-main">
            <div className="doc-edit-popup">
                <IoMdClose className="doc-edit-pop-close" onClick={onClose} />
                <section className="doc-section">
                    <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ? (
                                <p>Drop the files here ...</p>
                            ) : (
                                <>
                                    {fileName ? (
                                        <>
                                            <p>Selected file:</p>
                                            <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>{fileName}</p>
                                        </>
                                    ) : (
                                        <>
                                            <img src={icon} alt="" />
                                            <p>Drag & Drop files or Browse</p>
                                            <p>(pdf, jpg, png, word, ppt)</p>
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default FileEditPopup;
