import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Skill.css";
import axios from 'axios';
import ClientHeader from "../../ClientHeader/ClientHeader";

const Skill = () => {
    const navigate = useNavigate();

    const [selectedSkills, setSelectedSkills] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [user, setUser] = useState(null);
    const [jobId, setJobId] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        const storedJob = JSON.parse(localStorage.getItem('job'));
        setJobId(storedJob.jobId);
    }, []);

    useEffect(() => {
        if (jobId) {
            fetchJobDetails(jobId);
        }
    }, [jobId]);

    const fetchJobDetails = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/job/details`, { job_id: id });
            console.log(response);

            if (response.data.result.status === 'success') {
                setSelectedSkills(response.data.result.job.skills);
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
        }
    };

    const popularSkills = [
        "Odoo", "Web Development", "PHP", "CSS", "JS", "Web Dev", "MySQL",
        "Node js", "HTML", "Java", "Spring", "Python", "Flask", "Mongo db",
    ];

    const filteredSkills = popularSkills.filter(skill =>
        skill.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const addSkill = (skill) => {
        if (!selectedSkills.includes(skill)) {
            setSelectedSkills([...selectedSkills, skill]);
        }
    };

    const removeSkill = (skillToRemove) => {
        setSelectedSkills(selectedSkills.filter(skill => skill !== skillToRemove));
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleNext = async () => {
        if (!jobId) {
            alert("Job ID is missing!");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/job/update`, {
                job_id: jobId,
                skills: selectedSkills,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response);

            if (response.data.result.status === 'success') {
                navigate('/client/project/scope');
            } else {
                alert('Failed to update job details: ' + response.data.result.message);
            }
        } catch (error) {
            console.error('Error updating job details:', error);
            alert('An error occurred while updating job details. Please try again.');
        }
    };

    const handleBack = () => {
        navigate('/client/project/title');
    };

    return (
        <div className="skills-container">
            <ClientHeader />

            <div className="skills-content">
                <div className="progress">2/5</div>
                <h2>What are the main skills required for your work?</h2>

                <div className="search-containers">
                    <div className="input-label">
                        <p>Search skills or add your own</p>
                        <p>For the best results, add 3-5 skills</p>
                    </div>
                    <input
                        type="text"
                        className="search-skill-input"
                        placeholder="Search a skill"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>

                <div className="selected-skillss">
                    <h3>Selected Skills ({selectedSkills.length})</h3>
                    <div className="selected-skills-list">
                        {selectedSkills.map((skill, index) => (
                            <span key={index} className="selected-skill">
                                {skill} <button onClick={() => removeSkill(skill)}>✖</button>
                            </span>
                        ))}
                    </div>
                </div>

                <div className="popular-skills">
                    <h3>Popular skills</h3>
                    <div className="popular-skills-list">
                        {filteredSkills.map((skill, index) => (
                            <button
                                key={index}
                                className="skill-button"
                                onClick={() => addSkill(skill)}
                            >
                                {skill} +
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="navigation-buttons">
                <button className="back-button" onClick={handleBack}>Back</button>
                <button className="next-button" onClick={handleNext}>Next</button>
            </div>
        </div>
    );
};

export default Skill;
