import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientSurvey4.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientSurvey4 = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [currentSystems, setCurrentSystems] = useState('');
    const [implementation, setImplementation] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });
            console.log("rsdv", response);

            if (response.data.result.status === 'success') {
                setSurveyUserInputId(response.data.result.survey_user_input_id);
                setQuestions(response.data.result.questions);

                setCurrentSystems(response.data.result.questions[11].answer);
                setImplementation(response.data.result.questions[12].answer);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching survey questions:', error);
            toast.error('Error fetching survey questions');
        }
    };

    const handleNextButton = async () => {
        if (!currentSystems || !implementation) {
            toast.error('All questions are mandatory.');
            return;
        }
        try {
            const answers = [
                { question_id: questions[11].id, answer: currentSystems },
                { question_id: questions[12].id, answer: implementation },
            ];

            for (let answer of answers) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                    user_id: user.userId,
                    survey_user_input_id: surveyUserInputId,
                    question_id: answer.question_id,
                    answer: answer.answer,
                });
            }
            navigate('/client/survey_5');
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('Error submitting survey response');
        }
    };

    const handleBackButton = () => {
        navigate('/client/survey_3');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-survey4-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="survey4-contents">
                <section className="survey4-section">
                    <div style={{ display: 'flex' }}>4/7</div><br />
                    <h2>Current Systems and Odoo Implementation Goals</h2>
                    <p>To better understand your current business operations and how Odoo can be tailored to your needs, please provide the following information.</p>

                    <form>
                        <div className="question">
                            <label>Which systems are you currently using to manage your business processes ?<sup className='madatory'>*</sup></label>
                            <input
                                type='text'
                                placeholder="Enter system name"
                                value={currentSystems}
                                onChange={(e) => setCurrentSystems(e.target.value)}
                            />
                        </div>
                        <div className="question">
                            <label>What are the main goals and objectives for Odoo implementations ?<sup className='madatory'>*</sup></label>
                            <textarea
                                placeholder="Describe"
                                value={implementation}
                                onChange={(e) => setImplementation(e.target.value)}
                            />
                        </div>
                    </form>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src="https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg" alt="Trish" className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Trish</div>
                                <span>Home Seekers</span>
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "Thanks to Odoo Freelancer, we connected with a developer who created a mobile-friendly site for our listings. The ease of use has improved our client interactions, and we’ve seen an uptick in inquiries."
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default ClientSurvey4;
