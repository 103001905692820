import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Setting.css';
import axios from 'axios';
import Header from '../../Header/Header';
import { MdEdit } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import moment from 'moment-timezone';
import ProfileSection from '../SettingFeatures/ProfileSection/ProfileSection';
import MyProfile from '../SettingFeatures/MyProfile/MyProfile';
import Footer from '../../Footer/Footer';
import CloseAccountConfirmation from '../../../Templates/CloseAccountConfirmation';

const Setting = () => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [editInfo, setEditInfo] = useState(true);
    const [editLocation, setEditLocation] = useState(true);
    const [isContactInfo, setIsContactInfo] = useState(true);
    const [isMyProfile, setIsMyProfile] = useState(false);
    const [isProfileSetting, setIsProfileSetting] = useState(false);
    const [activeMenu, setActiveMenu] = useState('Contact Info');
    const [phone, setPhone] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [aptSuite, setAptSuite] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [profilePic, setProfilePic] = useState(null);
    const [userImage, setUserImage] = useState(null);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [timezone, setTimezone] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleTimezoneChange = (event) => {
        setTimezone(event.target.value);
    };

    const timezones = moment.tz.names();

    const editUserInfo = () => {
        setEditInfo(!editInfo)
    }

    const editUserLocation = () => {
        setEditLocation(!editLocation)
    }

    const showContactInfo = () => {
        setIsContactInfo(true);
        setIsMyProfile(false);
        setIsProfileSetting(false);
        setActiveMenu('Contact Info');
    };

    const showMyProfile = () => {
        setIsMyProfile(true);
        setIsContactInfo(false);
        setIsProfileSetting(false);
        setActiveMenu('My Profile');
    };

    const showProfileSetting = () => {
        setIsProfileSetting(true);
        setIsContactInfo(false);
        setIsMyProfile(false);
        setActiveMenu('Profile Settings');
    };

    const openCloseAccountPopup = () => {
        setIsPopupOpen(true);
    };

    const closeCloseAccountPopup = () => {
        setIsPopupOpen(false);
    };

    const handleCloseAccount = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_id: user ? user.userId : null })
            });
            console.log("response from close account============", response);

            const result = await response.json();
            console.log("result===============", result);
            if (result.result.status === 'success') {
                localStorage.removeItem('user');
                navigate('/');
            } else {
                console.error(result.message);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
    }, []);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('stored urer in fetch ====', storedUser);

        const fetchUserData = async () => {
            if (storedUser) {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/user`, {
                    user_id: storedUser.userId,
                });
                console.log("response in setting fetching=====", response);

                if (response.data.result.status === 'success') {
                    setFirstName(response.data.result.name.split(' ')[0] || '');
                    setLastName(response.data.result.name.split(' ').slice(-1).join(' ') || '');
                    setEmail(response.data.result.email || '');
                    setUserName(response.data.result.name || '');
                    setUser({ ...storedUser, ...response.data });
                }
            } else {
                console.error('Failed to fetch user data');
            }
        }

        fetchUserData();
    }, []);


    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);

        const fetchProfileData = async () => {
            if (storedUser) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch_buddy_profile`, {
                        user_id: storedUser.userId
                    });
                    if (response.data.result.status === 'success') {
                        const { dob, phone, streetAddress, aptSuite, country, city, state, zipCode, userImage, timeZone } = response.data.result.data;
                        setPhone(phone || '');
                        setStreetAddress(streetAddress || '');
                        setAptSuite(aptSuite || '');
                        setSelectedCountry(country || '');
                        setCity(city || '');
                        setSelectedState(state || '');
                        setZipCode(zipCode || '');
                        setTimezone(timeZone || '');

                        if (country) {
                            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get_country_code`, { country_name: country });
                            console.log(response);
                            if (response.data.result.code) {
                                setSelectedCountryCode(response.data.result.code);
                                fetchStatesByCountryCode(response.data.result.code);
                            }
                        }
                    } else {
                        console.error('Error fetching profile setup data:', response.data.result.message);
                    }
                } catch (error) {
                    console.error('Error fetching profile setup data:', error);
                }
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countryData = response.data.map(country => ({
                    code: country.cca2,
                    name: country.name.common,
                }));
                countryData.sort((a, b) => a.name.localeCompare(b.name));
                setCountries(countryData);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();
    }, []);

    const fetchStatesByCountryCode = async (countryCode) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch_states`, { country_code: countryCode });
            if (response.data.result.status === 'success') {
                setStates(response.data.result.data);
            } else {
                console.error('Error fetching states:', response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const handleCountryChange = async (event) => {
        const country_code = event.target.value;
        setSelectedCountry(country_code);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch_states`, { country_code });
            if (response.data.result.status === 'success') {
                setStates(response.data.result.data);
            } else {
                console.error('Error fetching states:', response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setSelectedState(selectedState);
        setState(states.find(state => state.id === selectedState)?.name || '');
    };

    const handleLocationUpdateButton = async () => {
        if (!user || !user.userId) {
            alert('User not logged in');
            return;
        }

        const formData = new FormData();
        formData.append('user_id', user.userId);
        formData.append('phone', phone);
        formData.append('streetAddress', streetAddress);
        formData.append('aptSuite', aptSuite);
        formData.append('country', selectedCountry);
        formData.append('city', city);
        formData.append('state', selectedState);
        formData.append('zip', zipCode);
        formData.append('timeZone', timezone);
        if (profilePic) {
            formData.append('profilePic', profilePic);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/setup_buddy_profile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === 'success') {
                setEditLocation(!editLocation)
                toast.success('Details updated successfully')
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const updateUserInfo = async () => {
        if (!user) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.userId,
                    name: `${firstName} ${lastName}`,
                    email
                }),
            });
            const data = await response.json();
            console.log("data user========", data);

            if (data.result.status === 'success') {

                const updatedUser = {
                    ...user,
                    userName: `${firstName} ${lastName}`,
                    firstName,
                    email,
                };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                setUserName(data.result.name)
                setEmail(data.result.email)
                setFirstName(data.result.name.split(' ')[0] || '');
                setLastName(data.result.name.split(' ').slice(-1).join(' ') || '');
                toast.success('User updated successfully');
                setEditInfo(true);
            } else {
                toast.error(`Error: ${data.result.message}`);
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    }

    const createClientAccountButton = () => {
        navigate('/freelancer/setting/client-account')
    }

    const createAgencyAccountButton = () => {
        navigate('/freelancer/setting/agency-account')
    }

    return (
        <div className="setting-container">
            <Header />
            <main className="setting-main">
                <aside className="setting-sidebar">
                    <h2>Settings</h2>
                    <ul>
                        <li className='menu-heading'>Billing</li>
                        <ul>
                            <li className={`menu-options ${activeMenu === 'Billing and Payments' ? 'active' : ''}`}>Billing and Payments</li>
                        </ul>
                        <li className="menu-heading">User Settings</li>
                        <ul>
                            <li className={`menu-options ${activeMenu === 'Membership & Connects' ? 'active' : ''}`}>Membership & Connects</li>
                            <li className={`menu-options ${activeMenu === 'Contact Info' ? 'active' : ''}`} onClick={showContactInfo}>Contact Info</li>
                            <li className={`menu-options ${activeMenu === 'My Profile' ? 'active' : ''}`} onClick={showMyProfile}>My Profile</li>
                            <li className={`menu-options ${activeMenu === 'Profile Settings' ? 'active' : ''}`} onClick={showProfileSetting}>Profile Settings</li>
                            <li className={`menu-options ${activeMenu === 'Get Paid' ? 'active' : ''}`}>Get Paid</li>
                            <li className={`menu-options ${activeMenu === 'My Teams' ? 'active' : ''}`}>My Teams</li>
                            <li className={`menu-options ${activeMenu === 'Connected Services' ? 'active' : ''}`}>Connected Services</li>
                            <li className={`menu-options ${activeMenu === 'Password & Security' ? 'active' : ''}`}>Password & Security</li>
                            <li className={`menu-options ${activeMenu === 'Notification Settings' ? 'active' : ''}`}>Notification Settings</li>
                        </ul>
                    </ul>
                </aside>
                {isContactInfo && (
                    <section className="setting-content">
                        <h2>Contact Info</h2>
                        <div className="contact-section">
                            {editInfo ? (<div className="contact-card">
                                <div className='edit-btn-div'>
                                    <h3 style={{ marginBottom: '20px' }}>Account</h3>
                                    <button className="edit-button" onClick={editUserInfo}><MdEdit /></button>
                                </div>
                                <div className='user-detail'>
                                    <p className='head'>User ID</p>
                                    <p className='record'>{user?.userId}</p>
                                </div>
                                <div className='user-detail'>
                                    <p className='head'>Name</p>
                                    <p className='record'>{userName}</p>
                                </div>
                                <div className='user-detail'>
                                    <p className='head'>Email</p>
                                    <p className='record'>{email}</p>
                                </div>
                                <button className='close-account' onClick={openCloseAccountPopup}>Close my account</button>
                            </div>) : (
                                <div className="contact-card">
                                    <h3 style={{ marginBottom: '20px' }}>Account</h3>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '18px' }}>
                                        <div className="input-group">
                                            <label>First Name</label>
                                            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
                                        </div>
                                        <div className="input-group">
                                            <label>Last Name</label>
                                            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="input-group">
                                        <label>Email</label>
                                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                                    </div>
                                    <button className='update-btn' onClick={updateUserInfo}>Update</button>
                                    <button className='cancel-btn' onClick={editUserInfo}>Cancel</button>
                                </div>
                            )}

                            <div className="contact-card">
                                <div className='edit-btn-div'>
                                    <h3>Additional accounts</h3>
                                    <button className="edit-button"><MdEdit /></button>
                                </div>
                                <p style={{ fontSize: '14px' }}>Creating a new account allows you to use Odoo in different ways, while still having just one login. </p>
                                <div className='client-account'>
                                    <p className='head'>Client Account</p>
                                    <p className='record'>Hire, manage and pay as a different company. Each client company has its own Odoo Freelancer's, payment methods and reports.</p>
                                    <button className='new-account' onClick={createClientAccountButton}>New Client Account</button>
                                </div>

                                <div className='agency-account'>
                                    <p className='head'>Agency Account</p>
                                    <p className='record'>Find jobs and earn money as manager of a team of Odoo Freelancer's .</p>
                                    <button className='new-account' onClick={createAgencyAccountButton}>New Agency Account</button>
                                </div>
                            </div>

                            {editLocation ? (
                                <div className="contact-card">
                                    <div className='edit-btn-div'>
                                        <h3 style={{ marginBottom: '20px' }}>Location</h3>
                                        <button className="edit-button" onClick={editUserLocation}><MdEdit /></button>
                                    </div>
                                    <div className='user-detail'>
                                        <p className='head'>Time Zone</p>
                                        <p className='record'>{timezone}, {selectedState}</p>
                                    </div>
                                    <div className='user-detail'>
                                        <p className='head'>Address</p>
                                        <p className='record'>{streetAddress}</p>
                                        <p className='record'>{aptSuite}</p>
                                        <p className='record'>{city}&nbsp; &nbsp;{zipCode}</p>
                                    </div>
                                    <div className='user-detail'>
                                        <p className='head'>Phone</p>
                                        <p className='record'>{phone}</p>
                                    </div>
                                </div>

                            ) : (
                                <div className="contact-card location-edit">
                                    <h3 style={{ marginBottom: '20px' }}>Location</h3>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '18px', marginBottom: '10px' }}>
                                        <div className="input-group">
                                            <label>Time Zone</label>
                                            <select
                                                id="timezone"
                                                value={timezone}
                                                onChange={handleTimezoneChange}
                                            >
                                                <option value="" disabled>{timezone ? timezone : 'Select your timezone'}</option>
                                                {timezones.map((timezone) => (
                                                    <option key={timezone} value={timezone}>
                                                        {timezone}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="input-group">
                                            <label>Country</label>
                                            <select name="country" value={selectedCountry} onChange={handleCountryChange}>
                                                <option value="">{selectedCountry ? selectedCountry : 'Select'}</option>
                                                {countries.map((country) => (
                                                    <option key={country.code} value={country.code}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <p>We take your privacy seriously. Only your city and country will be shared with clients.</p>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '18px', marginBottom: '10px', marginTop: '30px' }}>
                                        <div className="input-group">
                                            <label>Street Address</label>
                                            <input
                                                type="text"
                                                value={streetAddress}
                                                onChange={(e) => setStreetAddress(e.target.value)}
                                                placeholder="Enter Street address"
                                                required
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label>Apt/Suite</label>
                                            <input
                                                type="text"
                                                value={aptSuite}
                                                onChange={(e) => setAptSuite(e.target.value)}
                                                placeholder="Apt/Suite(Optional)"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '18px', marginBottom: '10px' }}>
                                        <div className="input-group">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                                placeholder="Enter City"
                                                required
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label>State/Province</label>
                                            <select name="state" value={selectedState} onChange={handleStateChange}>
                                                <option value="">{selectedState ? selectedState : 'Select'}</option>
                                                {states.map((state) => (
                                                    <option key={state.id} value={state.id}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '18px' }}>
                                        <div className="input-group" >
                                            <label>Phone</label>
                                            <PhoneInput style={{ width: '400px' }}
                                                defaultCountry="in"
                                                value={phone}
                                                onChange={(phone) => setPhone(phone)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <label>ZIP/Postal Code</label>
                                            <input
                                                type="text"
                                                value={zipCode}
                                                onChange={(e) => setZipCode(e.target.value)}
                                                placeholder="Enter ZIP/Postal Code"
                                            />
                                        </div>
                                    </div>
                                    <button className='update-btn' onClick={handleLocationUpdateButton}>Update</button>
                                    <button className='cancel-btn' onClick={editUserLocation}>Cancel</button>
                                </div>
                            )}
                        </div>
                    </section>
                )}
                {isMyProfile && (
                    <MyProfile />
                )}
                {(isProfileSetting &&
                    <ProfileSection />
                )}
            </main>
            <Footer />
            {isPopupOpen && (
                <CloseAccountConfirmation
                    onClose={closeCloseAccountPopup}
                    onSave={handleCloseAccount}
                />
            )}
            <ToastContainer />
        </div>
    );
};

export default Setting;
