import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './RateEditPop.css';

const RateEditPop = ({ user, hourlyRate: initialHourlyRate, onClose ,onHourlyRateChange}) => {
    const [hourlyRate, setHourlyRate] = useState(initialHourlyRate || 0);
    const serviceFeePercentage = 10;
    const serviceFee = (hourlyRate * serviceFeePercentage) / 100;
    const amountAfterFee = hourlyRate - serviceFee;

    useEffect(() => {
        setHourlyRate(initialHourlyRate || 0);
    }, [initialHourlyRate]);

    const handleRateChange = (e) => {
        const { value } = e.target;
        setHourlyRate(parseFloat(value) || 0);
    };

    const handleSaveButton = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/hourly_rate`, {
                hourly_rate: hourlyRate,
                service_fee: serviceFee,
                amount_after_fee: amountAfterFee,
                user_id: user.userId,
            });
            console.log("response in hourly rate================", response);
            if (response.data.result.status === 'success') {
                if (initialHourlyRate !== hourlyRate) {
                    onHourlyRateChange(hourlyRate);
                }
                onClose();
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error saving hourly rate:', error);
        }
    };

    return (
        <div className="rate-edit-pop">
            <div className="rate-content">
                <IoMdClose className="rate-edit-pop-close" onClick={onClose} />
                <h1>Update Your Hourly Rate</h1>
                <p>Clients will see this rate on your profile and in search results once you publish your profile. You can adjust your rate every time you submit a proposal.</p>
                <div className="rate-container">
                    <div className="rate-item">
                        <label>Hourly rate</label>
                        <input
                            type="number"
                            value={hourlyRate}
                            onChange={handleRateChange}
                            min="0"
                            step="0.5"
                        />
                        <span>/hr</span>
                    </div>
                    <div className="rate-item">
                        <label>Service fee</label>
                        <input
                            type="text"
                            value={`-$${serviceFee.toFixed(2)}`}
                            readOnly
                        />
                        <span>/hr</span>
                    </div>
                    <div className="rate-item">
                        <label>You'll get</label>
                        <input
                            type="text"
                            value={`$${amountAfterFee.toFixed(2)}`}
                            readOnly
                        />
                        <span>/hr</span>
                    </div>
                </div>
                <div className="button-container">
                    <button className="save-btn" onClick={handleSaveButton}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default RateEditPop;
