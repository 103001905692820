import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './FreelanceSurvey.css';
import logo from '../../assets/imgs/Odoo-Feelancer-LOGO.png';
import beginner from '../../assets/imgs/beginner.png';
import intermediate from '../../assets/imgs/intermediate.jpg';
import advanced from '../../assets/imgs/expert.webp';
import { MdContentPasteSearch } from "react-icons/md";
import { GiChampions, GiLevelThreeAdvanced } from "react-icons/gi";
import UserNav from '../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FreelanceSurvey = () => {
  const [experience, setExperience] = useState('');
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);
    console.log('Loaded user from localStorage=======', storedUser);

    if (storedUser) {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const endpoint = '/fetch_survey_experience';
      const url = `${apiUrl}${endpoint}`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: storedUser.userId })
      };
      console.log("fetch request option==============:", requestOptions);

      fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.result.status === 'success') {
            setExperience(data.result.experience);
            console.log('experience from backend============', data.result.experience);
          } else {
            console.error('Error fetching experience=========', data.result.message);
          }
        })
        .catch(error => {
          console.error('Error fetching experience==========', error);
        });
    }
  }, []);

  const handleChange = (e) => {
    setExperience(e.target.value);
  };

  const handleBackButton = () => {
    navigate('/freelancer/onboarding');
  };

  const handleNextButton = async () => {
    if (!user || !user.userId) {
      toast.error('User not logged in');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/experience`, {
        user_id: user.userId,
        experience: experience
      });
      console.log("Response from experience survey============", response);

      if (response.data.result.status === 'success') {
        navigate('/freelancer/survey/2');
      } else {
        toast.error(response.data.result.message);
      }
    } catch (error) {
      console.error('Error submitting survey response:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleSkipButton = () => {
    navigate('/freelancer/survey/2');
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="survey-container">
      <header className="header">
        <div className="logo" onClick={handleLogoClick}><img style={{ height: '65px' }} src={logo} alt="Logo" /></div>
        <UserNav />
      </header>
      <section className="main-content">
        <section className="welcome-section">
          <div className="progress">1/3</div>
          <h1>A few quick questions: first, have you freelanced before?</h1>
          <p>
            This lets us know how much help to give you along the way. We won't share your answer with anyone else, including potential clients.
          </p>
          <div className="options">
            <label className="option">
              <div className="icon"><img src={beginner} alt="" /></div>
              <span className="text">Beginner <br />
                <span className="subtext">I am brand new to this</span>
              </span>
              <input
                type="radio"
                name="experience"
                value="Beginner"
                checked={experience === 'Beginner'}
                onChange={handleChange}
              />
            </label>
            <label className="option">
              <div className="icon"><img src={intermediate} alt="" /></div>
              <span className="text">Intermediate <br />
                <span className="subtext">I have some experience</span>
              </span>
              <input
                type="radio"
                name="experience"
                value="Intermediate"
                checked={experience === 'Intermediate'}
                onChange={handleChange}
              />
            </label>
            <label className="option">
              <div className="icon"><img src={advanced} alt="" /></div>
              <span className="text">Advanced <br />
                <span className="subtext">I am an expert</span>
              </span>
              <input
                type="radio"
                name="experience"
                value="Advanced"
                checked={experience === 'Advanced'}
                onChange={handleChange}
              />
            </label>
          </div>
        </section>
      </section>
      <div className="navigation-buttons">
        <button className="back-button" onClick={handleBackButton}>Back</button>
        <div className="skip-next-buttons">
          <button className="skip-button" onClick={handleSkipButton}>Skip for now</button>
          <button className="next-button" onClick={handleNextButton}>Next</button>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default FreelanceSurvey;