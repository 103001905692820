import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfilePreview.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { MdEdit } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import UserNav from '../../Auth/UserNav/UserNav';
import RoleEditPop from '../../EditPopUp/RoleEditPop';
import BioEditPop from '../../EditPopUp/BioEditPop';
import RateEditPop from '../../EditPopUp/RateEditPop';
import SkillEditPop from '../../EditPopUp/SkillEditPop';
import LangEditPop from '../../EditPopUp/LangEditPop';
import EducEditPop from '../../EditPopUp/EducEditPop';
import ExpEditPop from '../../EditPopUp/ExpEditPop';
import demoProfile from '../../../assets/imgs/demo_profile_1.jpg';

const ProfilePreview = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState({
        name: '',
        location: '',
        role: '',
        overview: '',
        hourlyRate: '',
        skills: [],
        workHistory: [],
        education: [],
        languages: {},
        profilePic: '',
    });
    const [showRoleEditPop, setShowRoleEditPop] = useState(false);
    const [showBioEditPop, setShowBioEditPop] = useState(false);
    const [showRateEditPop, setShowRateEditPop] = useState(false);
    const [showSkillEditPop, setShowSkillEditPop] = useState(false);
    const [showLangEditPop, setShowLangEditPop] = useState(false);
    const [showEducEditPop, setShowEducEditPop] = useState(false);
    const [showExpEditPop, setShowExpEditPop] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // const [prevLanguages, setPrevLanguages] = useState([]);


    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchProfileData(storedUser.id);
        // const submitted = localStorage.getItem('isSubmitted');
        // setIsSubmitted(submitted === 'true');
    }, []);

    // useEffect(() => {
    //     setPrevLanguages(profile.languages);
    // }, [profile.languages]);

    const fetchProfileData = async () => {
        try {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const userId = storedUser ? storedUser.userId : null;

            if (!userId) {
                console.error('User ID not found in localStorage');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch_profile_preview_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch profile data');
            }

            const data = await response.json();
            if (data.result.status === 'success') {
                setProfile(data.result.profile_data);
            } else {
                throw new Error(data.message || 'Failed to fetch profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error.message);
        }
    };

    const handleEditClick = (section) => {
        if (section === 'role') {
            setShowRoleEditPop(true);
        }
        if (section === 'overview') {
            setShowBioEditPop(true);
        }
        if (section === 'hourlyRate') {
            setShowRateEditPop(true);
        }
        if (section === 'skills') {
            setShowSkillEditPop(true);
        }
        if (section === 'languages') {
            setShowLangEditPop(true);
        }
        if (section === 'workHistory') {
            setShowExpEditPop(true);
        }
        if (section === 'education') {
            setShowEducEditPop(true);
        }
    };

    const handleRoleEditClose = () => {
        setShowRoleEditPop(false);
        fetchProfileData();
    };

    const handleRoleChange = (newRole) => {
        if (profile.role !== newRole) {
            setProfile(prevProfile => ({ ...prevProfile, role: newRole }));
            setIsEdited(true);
        }
    };

    const handleBioEditClose = () => {
        setShowBioEditPop(false);
        fetchProfileData();
    };

    const handleBioChange = (newBio) => {
        if (profile.overview !== newBio) {
            setProfile(prevProfile => ({ ...prevProfile, overview: newBio }));
            setIsEdited(true);
        }
    };

    const handleRateEditClose = () => {
        setShowRateEditPop(false);
        fetchProfileData();
    };

    const handleHourlyRateChange = (newHourlyRate) => {
        if (profile.hourlyRate !== newHourlyRate) {
            setProfile(prevProfile => ({ ...prevProfile, hourlyRate: newHourlyRate }));
            setIsEdited(true);
        }
    };

    const handleSkillEditClose = () => {
        setShowSkillEditPop(false);
        fetchProfileData();
    };

    const handleSkillsChange = (newSkills) => {
        if (profile.skills.length !== newSkills.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                skills: newSkills
            }));
            setIsEdited(true);
        }
    };

    const handleLangEditClose = () => {
        setShowLangEditPop(false);
        // setIsEdited(true);
        fetchProfileData();
    };

    // const handleLanguageChange = (newLanguages) => {
    //     if (profile.languages[language].length !== newLanguages.length) {
    //         setProfile(prevProfile => ({
    //             ...prevProfile,
    //             languages: newLanguages
    //         }));
    //         setIsEdited(true);
    //     }
    // }

    const handleLanguageChange = (newLanguages) => {
        if (profile.languages.length !== newLanguages.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                languages: newLanguages.map(lang => lang.language),
                proficiency: newLanguages.map(lang => lang.proficiency)
            }));
            setIsEdited(true);
        }
    };


    const handleExpEditClose = () => {
        setShowExpEditPop(false);
        fetchProfileData();
    };

    const handleExperienceChange = (newWorkHistory) => {
        if (profile.workHistory.length !== newWorkHistory.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                workHistory: newWorkHistory
            }));
            setIsEdited(true);
        }
    }

    const handleEducEditClose = () => {
        setShowEducEditPop(false);
        // setIsEdited(true);
        fetchProfileData();
    };

    const handleEducationChange = (newEducation) => {
        if (profile.education.length !== newEducation.length) {
            setProfile(prevProfile => ({
                ...prevProfile,
                education: newEducation
            }));
            setIsEdited(true);
        }
    }

    const handleSubmitClick = () => {
        // setIsSubmitted(true);
        // localStorage.setItem('isSubmitted', 'true');
        navigate('/freelancer/profile/ready');
    };

    // const handleBackClick = () => {
    //     navigate(-1);
    // };

    return (
        <div className="profile-preview-container">
            <header className="profile-preview-header">
                <img src={logo} alt="Logo" className="profile-preview-logo" />
                <UserNav />
            </header>
            <section className="profile-preview-banner">
                <div className="banner-content">
                    <h2 style={{ color: '#62495B', fontWeight: '700' }}>Looking good, {user ? user.firstName : 'Guest'}!</h2>
                    <p style={{ fontWeight: '500' }}>Make any edits you want, then submit your profile. You can make more changes after it's live.</p>
                    {isEdited && <button className="submit-button" onClick={handleSubmitClick}>Submit Profile</button>}
                    {/* <button className="submit-button" onClick={handleSubmitClick}>Submit Profile</button> */}
                </div>
                <div className="banner-image">
                    <img src="https://blogimage.vantagecircle.com/content/images/2022/03/Don-t-hesitate.png" alt="Banner" style={{ height: '150px', width: '255px' }} />
                </div>
            </section>
            <main className="profile-preview-main">
                <section className="profile-details">
                    <div className="profile-card">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {profile.profilePic ? <img src={`data:image/png;base64,${profile.profilePic}`} alt="Profile" className="profile-pic" /> : <img src={demoProfile} alt='Profile' className="profile-pic"/>}
                            {/* <img src={`data:image/png;base64,${profile.profilePic}`} alt="Profile" className="profile-pic" /> */}
                            <span style={{ marginLeft: '25px' }}>
                                <h3 style={{ fontWeight: '700' }}>{profile.name}</h3>
                                <p style={{ fontWeight: '600' }}><IoLocationOutline />&nbsp;{profile.location}</p>
                            </span>
                        </div>
                        <div className="profile-info">
                            <div className='edit-btn-profile'>
                                <h5 style={{ color: '#A3A3A3', fontWeight: '600' }}>Role</h5>
                                <button className="edit-button" onClick={() => handleEditClick('role')}><MdEdit /></button>
                            </div>
                            <h5 style={{ fontWeight: '600' }}>{profile.role}</h5><br />
                            <div className='edit-btn-profile'>
                                <h5 style={{ color: '#A3A3A3', fontWeight: '600' }}>Overview</h5>
                                <button className="edit-button" onClick={() => handleEditClick('overview')}><MdEdit /></button>
                            </div>
                            <h5 style={{ fontWeight: '400' }}>{profile.overview}</h5><br />
                            <div className='edit-btn-profile'>
                                <h5 style={{ color: '#A3A3A3', fontWeight: '600' }}>Hourly Rate</h5>
                                <button className="edit-button" onClick={() => handleEditClick('hourlyRate')}><MdEdit /></button>
                            </div>
                            <h5 style={{ fontWeight: '600' }}>${profile.hourlyRate} / hour</h5>
                        </div>
                    </div>
                    <div className="skills-section">
                        <div className='edit-btn-div'>
                            <h3 style={{ fontWeight: '700' }}>Skills</h3>
                            <button className="edit-button" onClick={() => handleEditClick('skills')}><MdEdit /></button>
                        </div>
                        <ul>
                            {profile.skills.map((skill, index) => (
                                <li key={index} className="skill-item">{skill}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="work-history-section">
                        <div className='edit-btn-div'>
                            <h3 style={{ fontWeight: '700' }}>Work History</h3>
                            <button className="edit-button" onClick={() => handleEditClick('workHistory')}><MdEdit /></button>
                        </div>
                        {profile.workHistory.map((work, index) => (
                            <div key={index} className="work-history-item">
                                <h5 style={{ fontWeight: '600' }}>{work.company} | {work.position}</h5>
                                <p style={{ color: '#A3A3A3', fontWeight: '600' }}>{work.period}</p>
                            </div>
                        ))}
                    </div>
                    <div className="education-section">
                        <div className='edit-btn-div'>
                            <h3 style={{ fontWeight: '700' }}>Education</h3>
                            <button className="edit-button" onClick={() => handleEditClick('education')}><MdEdit /></button>
                        </div>
                        {profile.education.map((edu, index) => (
                            <div key={index} className="education-item">
                                <h5 style={{ fontWeight: '600' }}>{edu.institution}</h5>
                                <p style={{ color: '#A3A3A3', fontWeight: '600' }}>{edu.degree} | {edu.period}</p>
                            </div>
                        ))}
                    </div>
                </section>
                <section style={{ width: '300px', marginLeft: '10%' }}>
                    <div className="languages-section">
                        <div className='edit-btn-div'>
                            <h3 style={{ fontWeight: '700' }}>Languages</h3>
                            <button className="edit-button" onClick={() => handleEditClick('languages')}><MdEdit /></button>
                        </div>
                        <ul>
                            {Object.entries(profile.languages).map(([language, level], index) => (
                                <li key={index} className="language-item" ><span style={{ fontWeight: '600' }}>{language}</span> : &nbsp;&nbsp;<span style={{ color: '#A3A3A3', fontWeight: '600' }}>{level}</span></li>
                            ))}
                        </ul>
                    </div>
                </section>
            </main>
            <footer className="profile-preview-footer">
                <button className="submit-button" onClick={handleSubmitClick}>Submit Profile</button>
                {/* {!isSubmitted ? (
                    <button className="submit-button" onClick={handleSubmitClick}>Submit Profile</button>
                ) : (
                    <button className="back-button" onClick={handleBackClick}>Back</button>
                )} */}
            </footer>

            {showRoleEditPop && (
                <RoleEditPop
                    user={user}
                    role={profile.role}
                    onClose={handleRoleEditClose}
                    onRoleChange={handleRoleChange}
                />
            )}
            {showBioEditPop && (
                <BioEditPop
                    user={user}
                    bio={profile.overview}
                    onClose={handleBioEditClose}
                    onBioChange={handleBioChange}
                />
            )}
            {showRateEditPop && (
                <RateEditPop
                    user={user}
                    hourlyRate={profile.hourlyRate}
                    onClose={handleRateEditClose}
                    onHourlyRateChange={handleHourlyRateChange}
                />
            )}
            {showSkillEditPop && (
                <SkillEditPop
                    user={user}
                    selectedSkills={profile.skills}
                    onClose={handleSkillEditClose}
                    onSkillsChange={handleSkillsChange}
                />
            )}
            {showLangEditPop && (
                <LangEditPop
                    user={user}
                    languages={profile.languages}
                    onClose={handleLangEditClose}
                    onLanguageChange={handleLanguageChange}
                />
            )}
            {showExpEditPop && (
                <ExpEditPop
                    onClose={handleExpEditClose}
                    onExperienceChange={handleExperienceChange}
                />
            )}
            {showEducEditPop && (
                <EducEditPop
                    onClose={handleEducEditClose}
                    onEducationChange={handleEducationChange}
                />
            )}

        </div>
    );
};

export default ProfilePreview;
