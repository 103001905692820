import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SavedJobs.css';
import { FaChevronLeft, FaRegHeart, FaHeart, FaThumbsDown, FaRegThumbsDown } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { VscVerifiedFilled } from "react-icons/vsc";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const SavedJobs = () => {

    const [user, setUser] = useState(null);
    const [jobs, setJobs] = useState([])

    const navigate = useNavigate();

    const fetchJobs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jobs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch jobs');
            }

            const data = await response.json();
            console.log(data);

            if (data.result.status === 'success') {
                setJobs(data.result.jobs);
            } else {
                throw new Error(data.result.message || 'Failed to fetch jobs');
            }
        } catch (error) {
            console.error('Error fetching jobs:', error.message);
        }
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchJobs();
    }, []);

    const toggleFavorite = (index) => {
        setJobs((prevJobs) =>
            prevJobs.map((job, i) =>
                i === index ? { ...job, favorited: !job.favorited } : job
            )
        );
    };

    const toggleDislike = (index) => {
        setJobs((prevJobs) =>
            prevJobs.map((job, i) =>
                i === index ? { ...job, disliked: !job.disliked } : job
            )
        );
    };

    const handleBackButton = () => {
        navigate('/freelancer/search_jobs');
    }

    return (
        <div className='saved-jobs-container'>
            <Header />
            <button className='back-btn' onClick={handleBackButton}><FaChevronLeft /> Return to Search</button>
            <div className='saved-jobs'>
                {jobs.map((job, index) => (
                    <div key={job.id} className="saved-job-item">
                        <div className='saved-job-heading'>
                            <div>
                                <h4>{job.title}</h4>
                                <span className='saved-job-category'>Fixed-price - {job.experienceLevel} - Est. Budget : {job.hourly_rate}</span>
                            </div>
                            <div>
                                <button onClick={() => toggleDislike(index)} style={{ border: 'none' }}>
                                    {job.disliked ? <FaThumbsDown style={{ color: '#7B1818', height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} /> : <FaRegThumbsDown style={{ height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} />}
                                </button>
                                {/* <AiOutlineDislike style={{ height: '30px', width: '30px', marginRight: '25px', marginTop: '10px' }} /> */}
                                <button onClick={() => toggleFavorite(index)} style={{ border: 'none' }}>
                                    {job.favorited ? <FaHeart style={{ color: 'red', height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} /> : <FaRegHeart style={{ height: '30px', width: '25px', marginRight: '10px', marginTop: '10px' }} />}
                                </button>
                                {/* <FaRegHeart style={{height:'30px',width:'25px',marginRight:'10px',marginTop:'10px'}}/> */}
                            </div>
                        </div>
                        <p>{job.description}</p>
                        <div className="saved-job-tags">
                            {job.skills.map((skill, index) => (
                                <span key={index} className="saved-job-tag">{skill}</span>
                            ))}
                        </div>
                        <div className="saved-job-details">
                            {job.paymentVerified && <span><VscVerifiedFilled style={{ color: '#62495B', height: '23px', width: '23px' }} /> &nbsp; Payment verified</span>}
                            <span>{job.rating} stars</span>
                            <span>{job.type}</span>
                            <span>{job.clientHistory}</span>
                            <span><IoLocationOutline />&nbsp; {job.location}</span>
                        </div>
                        <div className="saved-job-rating">
                            <span>Proposals :{job.proposals}</span>
                            <span>{job.reviews} reviews</span>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    )
}

export default SavedJobs