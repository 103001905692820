import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LangEditPop.css';
import { MdDeleteForever } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

const LangEditPop = ({ user, onClose, onLanguageChange }) => {
    const [languages, setLanguages] = useState([{ language: 'English', proficiency: 'Basic' }]);

    useEffect(() => {
        if (user) {
            fetchLanguages(user.userId);
        }
    }, [user]);

    const fetchLanguages = (userId) => {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const endpoint = '/fetch_language';
        const url = `${apiUrl}${endpoint}`;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user_id: userId })
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.result.status === 'success') {
                    setLanguages(data.result.languages.length > 0 ? data.result.languages : [{ language: 'English', proficiency: 'Basic' }]);
                    console.log('Loaded languages from backend:', data.result.languages);
                } else {
                    console.error('Error fetching languages:', data.result.message);
                }
            })
            .catch(error => {
                console.error('Error fetching languages:', error);
            });
    };

    const addLanguage = () => {
        setLanguages([...languages, { language: '', proficiency: 'Basic' }]);
    };

    const handleLanguageChange = async (index, field, value) => {
        const updatedLanguages = languages.map((lang, i) =>
            i === index ? { ...lang, [field]: value } : lang
        );
        setLanguages(updatedLanguages);
        localStorage.setItem('languages', JSON.stringify(updatedLanguages));

        if (field === 'proficiency') {
            const languageToUpdate = updatedLanguages[index].language;
            const proficiencyToUpdate = value;

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update_language`, {
                    user_id: user.userId,
                    language: languageToUpdate,
                    proficiency: proficiencyToUpdate
                });
                console.log('Response from update language proficiency:', response);
                if (response.data.result.status === 'success') {
                    console.log('Language proficiency updated successfully on backend');
                } else {
                    console.error('Error updating language proficiency:', response.data.result.message);
                }
            } catch (error) {
                console.error('Error updating language proficiency:', error);
            }
        }
    };

    const handleDeleteLanguage = async (index) => {
        const languageToDelete = languages[index].language;

        if (languageToDelete !== 'English') {
            const updatedLanguages = languages.filter((_, i) => i !== index);
            setLanguages(updatedLanguages);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/delete_language`, {
                    user_id: user.userId,
                    language: languageToDelete
                });
                console.log('Response from delete language:', response);
                if (response.data.result.status === 'success') {
                    console.log('Language deleted successfully from backend');
                } else {
                    console.error('Error deleting language:', response.data.result.message);
                }
            } catch (error) {
                console.error('Error deleting language:', error);
            }
        } else {
            console.log('Cannot delete English language');
        }
    };

    const handleSaveButton = async () => {
        const validLanguages = languages.filter(lang => lang.language);

        if (user) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create_language`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: user.userId,
                        languages: validLanguages
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to save languages');
                }

                const result = await response.json();
                if (result.result.status === 'success') {
                    console.log('Languages saved successfully:', result);
                    onLanguageChange(languages)
                    onClose();
                } else {
                    console.error('Error saving languages:', result.result.message);
                }
            } catch (error) {
                console.error('Error saving languages:', error.message);
            }
        } else {
            console.error('User not found in props');
        }
    };

    return (
        <div className='language-card-main'>
            <div className="language-card">
                <div className="close-btn" onClick={onClose}><IoMdClose /></div>
                <h2>Update your skills</h2>
                <div className='heading'>
                    <div>Language</div>
                    <div style={{ marginLeft: '29%' }}>Proficiency</div>
                </div>
                <div className="languages-list">
                    {languages.map((lang, index) => (
                        <div key={index} className="language-item">
                            {lang.language === 'English' ? (
                                <>
                                    <input
                                        type="text"
                                        value={lang.language}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    <select
                                        value={lang.proficiency}
                                        onChange={(e) => handleLanguageChange(index, 'proficiency', e.target.value)}
                                    >
                                        <option value="Basic">Basic</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Fluent">Fluent</option>
                                    </select>
                                    <div><MdDeleteForever style={{ color: '#ddd', cursor: 'not-allowed' }} /></div>
                                </>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        placeholder="Language"
                                        value={lang.language}
                                        onChange={(e) => handleLanguageChange(index, 'language', e.target.value)}
                                    />
                                    <select
                                        value={lang.proficiency}
                                        onChange={(e) => handleLanguageChange(index, 'proficiency', e.target.value)}
                                    >
                                        <option value="Basic">Basic</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Fluent">Fluent</option>
                                    </select>
                                    <div onClick={() => handleDeleteLanguage(index)}><MdDeleteForever /></div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <div className='new-language'>
                    <button onClick={addLanguage}>+ Add Language</button>
                </div>
                <div>
                    <button className='save-btn' onClick={handleSaveButton}>Save</button>
                </div>
            </div>
        </div>
    );
};

export default LangEditPop;
