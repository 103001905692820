import React, { useState } from 'react';
import styles from './NewPassword.module.css';
import axios from 'axios';
import logo from '../../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { useSearchParams } from 'react-router-dom';

const NewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();
  const temp_id = searchParams.get('temp_id');
  const temp = searchParams.get('temp');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reset_password`, {
        temp_id,
        temp,
        password,
        c_password: confirmPassword,
      });
      console.log("response in new password ====================", response);

      if (response.data.result.status === 'success') {
        setMessage('Password updated successfully');
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.logo}>{logo}</div>
      </header>
      <div className={styles.card}>
        <h2>Update Your Password</h2>
        {message && <p className={styles.cardMessage}>{message}</p>}
        <form className={styles.form} onSubmit={handleSubmit}>
          <div>
            <label htmlFor="password" className={styles.formLabel}>
              New Password
            </label>
            <input
              type="password"
              id="password"
              placeholder="New Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.formInput}
            />
          </div>
          <div>
            <label htmlFor="confirm-password" className={styles.formLabel}>
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirm-password"
              placeholder="Confirm Password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={styles.formInput}
            />
          </div>
          <div className={styles.buttons}>
            <button type="button" className={styles.cancelButton}>
              Cancel
            </button>
            <button type="submit" className={styles.resetButton}>
              Update Password
            </button>
          </div>
        </form>
      </div>
      <footer className={styles.footer}>
        <p>
          © 2024 Odoo. <a href="/privacy-policy" className={styles.footerLink}>Privacy Policy</a>
        </p>
      </footer>
    </div>
  );
};

export default NewPassword;
