import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./ProjectPreview.css";
import axios from 'axios';
import ClientHeader from "../../ClientHeader/ClientHeader";
import { MdEdit } from "react-icons/md";
import TitleEdit from "../../EditProjectPopup/TitleEdit/TitleEdit";
import SkillEdit from "../../EditProjectPopup/SkillEdit/SkillEdit";
import DescriptionEdit from "../../EditProjectPopup/DescriptionEdit/DescriptionEdit";
import ScopeEdit from "../../EditProjectPopup/ScopeEdit/ScopeEdit";
import BudgetEdit from "../../EditProjectPopup/BudgetEdit/BudgetEdit";

const ProjectPreview = () => {
    const navigate = useNavigate();

    const [customQuestion, setCustomQuestion] = useState("");
    const [questions, setQuestions] = useState([
        "Describe your recent experience with similar projects",
        "Please list any certifications related to this project",
        "Include a link to your GitHub profile and/or website",
        "What frameworks have you worked with?",
        "Describe your approach to testing and improving QA",
    ]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const [jobId, setJobId] = useState(null);
    const [jobDetails, setJobDetails] = useState({
        title: "",
        description: "",
        budget: "",
        scope: "",
        skills: [],
    });

    const [showTitleEditPop, setShowTitleEditPop] = useState(false);
    const [showDescriptionEditPop, setShowDescriptionEditPop] = useState(false);
    const [showSkillEditPop, setShowSkillEditPop] = useState(false);
    const [showScopeEditPop, setShowScopeEditPop] = useState(false);
    const [showBudgetEditPop, setShowBudgetEditPop] = useState(false);

    useEffect(() => {
        const storedJob = JSON.parse(localStorage.getItem('job'));
        if (storedJob?.jobId) {
            setJobId(storedJob.jobId);
        }
    }, []);

    useEffect(() => {
        if (jobId) {
            fetchJobDetails(jobId);
            console.log(jobId);
        }
    }, [jobId]);

    const fetchJobDetails = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/job/details`, { job_id: id });
            if (response.data.result.status === 'success') {
                setJobDetails(response.data.result.job);
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
        }
    };

    const handleAddQuestion = () => {
        if (customQuestion.trim()) {
            setQuestions((prevQuestions) => [...prevQuestions, customQuestion]);
            setCustomQuestion("");
        }
    };

    const handleQuestionInputChange = (e) => {
        setCustomQuestion(e.target.value);
    };

    const handleSelectQuestion = (question) => {
        if (selectedQuestions.includes(question)) {
            setSelectedQuestions((prevSelected) =>
                prevSelected.filter((q) => q !== question)
            );
        } else {
            setSelectedQuestions((prevSelected) => [...prevSelected, question]);
        }
    };

    const handleBack = () => {
        navigate('/client/project/description');
    };

    const handlePostJob = () => {
        localStorage.removeItem('job');
        navigate('/client/dashboard');
    };

    const handleEditClick = (section) => {
        if (section === 'title') {
            setShowTitleEditPop(true);
        }
        if (section === 'description') {
            setShowDescriptionEditPop(true);
        }
        if (section === 'skills') {
            setShowSkillEditPop(true);
        }
        if (section === 'scope') {
            setShowScopeEditPop(true);
        }
        if (section === 'budget') {
            setShowBudgetEditPop(true);
        }
    };

    const handleTitleEditClose = () => {
        setShowTitleEditPop(false);
        fetchJobDetails(jobId);
    };

    const handleDescriptionEditClose = () => {
        setShowDescriptionEditPop(false);
        fetchJobDetails(jobId);
    };

    const handleSkillEditClose = () => {
        setShowSkillEditPop(false);
        fetchJobDetails(jobId);
    };

    const handleScopeEditClose = () => {
        setShowScopeEditPop(false);
        fetchJobDetails(jobId);
    };

    const handleBudgetEditClose = () => {
        setShowBudgetEditPop(false);
        fetchJobDetails(jobId);
    };

    return (
        <div className="project-preview-container">
            <ClientHeader />
            <div className="project-preview-content">
                <div className="project-header">
                    <div className='edit-btn-div'>
                        <h2>{jobDetails.title}</h2>
                        {/* <button className="edit-button" onClick={() => handleEditClick('title')}><MdEdit /></button> */}
                    </div>

                    <div className='edit-btn-div'>
                        <p>
                            {jobDetails.description}
                        </p>
                        <button className="edit-button" onClick={() => handleEditClick('description')}><MdEdit /></button>
                    </div>
                </div>

                <div className="project-details">

                    {/* <div className='edit-btn-div'>
                        <div className="project-detail">
                            <h4>Category</h4>
                            <p>Web Design</p>
                        </div>
                        <button className="edit-button"><MdEdit /></button>
                    </div> */}

                    <div className='edit-btn-div'>
                        <div className="project-detail">
                            <h4>Skills</h4>
                            <p>{jobDetails.skills.join(", ")}</p>
                        </div>
                        <button className="edit-button" onClick={() => handleEditClick('skills')}><MdEdit /></button>
                    </div>

                    <div className='edit-btn-div'>
                        <div className="project-detail">
                            <h4>Scope</h4>
                            <p>{jobDetails.scope}</p>
                        </div>
                        <button className="edit-button" onClick={() => handleEditClick('scope')}><MdEdit /></button>
                    </div>

                    <div className='edit-btn-div'>
                        <div className="project-detail">
                            <h4>Budget</h4>
                            <p>$ {jobDetails.budget}</p>
                        </div>
                        <button className="edit-button" onClick={() => handleEditClick('budget')}><MdEdit /></button>
                    </div>

                </div>

                <div className="project-questions">
                    <h3>Screening questions (optional)</h3>
                    <p>Narrow down your candidates</p>

                    <div className="add-question">
                        <h4>Add up to 4 more questions</h4>
                        {/* <input
                            type="text"
                            placeholder="Write your own question"
                            value={customQuestion}
                            onChange={handleQuestionInputChange}
                        /> */}
                        <button onClick={handleAddQuestion}>Write your own questions</button>
                    </div>

                    <div className="suggested-questions">
                        <h5>Suggested</h5>
                        {questions.map((question, index) => (
                            <div key={index} className="question-option">
                                <input
                                    type="checkbox"
                                    checked={selectedQuestions.includes(question)}
                                    onChange={() => handleSelectQuestion(question)}
                                />
                                <label>{question}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="navigation-buttons">
                <button className="back-button" onClick={handleBack}>Back</button>
                <div style={{ display: 'flex', gap: '30px' }}>
                    {/* <button className="save-button">Save as a draft</button> */}
                    <button className="post-button" onClick={handlePostJob}>Post this Project</button>
                </div>
            </div>
            {showTitleEditPop && (
                <TitleEdit
                    onClose={handleTitleEditClose}
                />
            )}
            {showDescriptionEditPop && (
                <DescriptionEdit
                    onClose={handleDescriptionEditClose}
                />
            )}
            {showSkillEditPop && (
                <SkillEdit
                    onClose={handleSkillEditClose}
                />
            )}
            {showScopeEditPop && (
                <ScopeEdit
                    onClose={handleScopeEditClose}
                />
            )}
            {showBudgetEditPop && (
                <BudgetEdit
                    onClose={handleBudgetEditClose}
                />
            )}
        </div>
    );
};

export default ProjectPreview;
