import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientSurvey5.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientSurvey5 = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [integrationDetails, setIntegrationDetails] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });
            console.log("rsdv", response);

            if (response.data.result.status === 'success') {
                setSurveyUserInputId(response.data.result.survey_user_input_id);
                setQuestions(response.data.result.questions);

                setIntegrationDetails(response.data.result.questions[13].answer);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching survey questions:', error);
            toast.error('Error fetching survey questions');
        }
    };

    const handleNextButton = async () => {
        if (!integrationDetails) {
            toast.error('All questions are mandatory.');
            return;
        }
        try {
            const answers = [
                { question_id: questions[13].id, answer: integrationDetails },
            ];

            for (let answer of answers) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                    user_id: user.userId,
                    survey_user_input_id: surveyUserInputId,
                    question_id: answer.question_id,
                    answer: answer.answer,
                });
            }
            navigate('/client/survey_6');
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('Error submitting survey response');
        }
    };

    const handleBackButton = () => {
        navigate('/client/survey_4');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-survey5-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="survey5-contents">
                <section className="survey5-section">
                    <div style={{ display: 'flex' }}>5/7</div><br />
                    <h2>Odoo Integration</h2>
                    <p>To effectively implement Odoo in your business, please provide the following information:</p>

                    <form>
                        <div className="question">
                            <label>Do you require Odoo to integrate with any other systems or software that you are currently using?<sup className='madatory'>*</sup></label>
                            <textarea
                                placeholder="Describe"
                                value={integrationDetails}
                                onChange={(e) => setIntegrationDetails(e.target.value)}
                            />
                        </div>
                    </form>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src="https://img.freepik.com/premium-vector/vector-small-business-city-building-illustration-with-flat-design-style_738021-20.jpg" alt="Invest Smart Agency" className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Invest Smart Agency</div>
                                {/* <span>Client</span> */}
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "Finding a developer who understands financial software was crucial for us. OF provided access to top-notch talent that delivered a custom solution tailored to our needs. Highly satisfied!"
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default ClientSurvey5;
