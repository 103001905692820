import React, { useState, useEffect } from 'react';
import './ExpPopUp.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);


const ExpPopUp = ({ onClose, onSave, experience }) => {
    const [filteredEndYears, setFilteredEndYears] = useState(years);
    const [formState, setFormState] = useState({
        title: '',
        company: '',
        location: '',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        description: '',
        isCurrentlyWorking: false,
    });

    useEffect(() => {
        if (experience) {
            const startDate = experience.startDate.split(' ');
            const endDate = experience.endDate === 'Present' ? ['Present', ''] : experience.endDate.split(' ');

            setFormState({
                title: experience.title,
                company: experience.company,
                location: experience.location,
                startMonth: startDate[0],
                startYear: startDate[1],
                endMonth: endDate[0],
                endYear: endDate[1],
                description: experience.description,
                isCurrentlyWorking: experience.endDate === 'Present',
            });
            if (startDate[1]) {
                setFilteredEndYears(years.filter(year => year >= startDate[1]));
            }
        }
    }, [experience]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormState({
            ...formState,
            [name]: type === 'checkbox' ? checked : value,
        });

        if (name === 'startYear') {
            setFilteredEndYears(years.filter(year => year >= value));
            if (formState.endYear && formState.endYear < value) {
                setFormState(prevState => ({ ...prevState, endYear: '' }));
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            formState.startYear &&
            formState.endYear &&
            formState.startYear === formState.endYear &&
            months.indexOf(formState.startMonth) > months.indexOf(formState.endMonth)
        ) { 
            toast.error("Start date should be greater than end date .");
            return;
        }

        const newExperience = {
            title: formState.title,
            company: formState.company,
            location: formState.location,
            startDate: `${formState.startMonth} ${formState.startYear}`,
            endDate: formState.isCurrentlyWorking ? 'Present' : `${formState.endMonth} ${formState.endYear}`,
            description: formState.description,
        };
        onSave(newExperience);
    };

    return (
        <div className="experience-popup">
            <div className="popup-inner">
                <form onSubmit={handleSubmit}>
                    <h2 style={{ marginRight: '35px' }}>Add Your Work Experience</h2>
                    <label>
                        Title
                        <input
                            type="text"
                            name="title"
                            placeholder="Job Title"
                            value={formState.title}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Company
                        <input
                            type="text"
                            name="company"
                            placeholder="Company Name"
                            value={formState.company}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Location
                        <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            value={formState.location}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="isCurrentlyWorking"
                            checked={formState.isCurrentlyWorking}
                            onChange={handleChange}
                        />
                        I am currently working in this role
                    </label>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                            <p style={{ fontWeight: '600' }}>Start Date</p>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <select name="startMonth" value={formState.startMonth} onChange={handleChange}>
                                    <option value="">Month</option>
                                    {months.map(month => <option key={month} value={month}>{month}</option>)}
                                </select>
                                <select name="startYear" value={formState.startYear} onChange={handleChange}>
                                    <option value="">Year</option>
                                    {years.map(year => <option key={year} value={year}>{year}</option>)}
                                </select>
                            </div>
                        </div>
                        {!formState.isCurrentlyWorking &&
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                <p style={{ fontWeight: '600' }}>End Date</p>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <select
                                        name="endMonth"
                                        value={formState.endMonth}
                                        onChange={handleChange}
                                        disabled={formState.isCurrentlyWorking}
                                    >
                                        <option value="">Month</option>
                                        {months.map(month => <option key={month} value={month}>{month}</option>)}
                                    </select>
                                    <select
                                        name="endYear"
                                        value={formState.endYear}
                                        onChange={handleChange}
                                        disabled={formState.isCurrentlyWorking}
                                    >
                                        <option value="">Year</option>
                                        {filteredEndYears.map(year => <option key={year} value={year}>{year}</option>)}
                                    </select>
                                </div>
                            </div>
                        }

                    </div>
                    <label>
                        Description
                        <textarea
                            name="description"
                            placeholder="Describe here"
                            value={formState.description}
                            onChange={handleChange}
                        />
                    </label>
                    <div className="form-buttons">
                        <button type="button" onClick={onClose} style={{ borderRadius: '30px' }}>
                            Cancel
                        </button>
                        <button type="submit" style={{ borderRadius: '30px' }}>Save</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ExpPopUp;