import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Skills.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { toast, ToastContainer } from 'react-toastify';

const Skills = () => {
  const navigate = useNavigate();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [categories] = useState([
    { name: 'Websites, IT & Software', skills: ['Odoo', 'HTML', 'Java', 'JavaScript', 'WordPress', 'MySQL'] },
    { name: 'Writing & Content', skills: ['Copywriting', 'Content Writing', 'Editing'] },
    { name: 'Design, Media & Architecture', skills: ['Graphic Design', 'Interior Design', 'Architecture'] },
    { name: 'Data Entry & Admin', skills: ['Data Entry', 'Virtual Assistant', 'Transcription'] },
    { name: 'Engineering & Science', skills: ['Mechanical Engineering', 'Electrical Engineering', 'Biology'] },
  ]);
  const [activeCategory, setActiveCategory] = useState(categories[0].name);
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);
    console.log('Loaded user from localStorage:', storedUser);

    if (storedUser) {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const endpoint = '/fetch_skills';
      const url = `${apiUrl}${endpoint}`;

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: storedUser.userId })
      };
      console.log("fetching skills===============", requestOptions);
      fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.result.status === 'success') {
            setSelectedSkills(data.result.skills);
            console.log('Loaded skills from backend:', data.result.skills);
          } else {
            console.error('Error fetching skills:', data.result.message);
          }
        })
        .catch(error => {
          console.error('Error fetching skills:', error);
        });
    }
  }, []);
  // && selectedSkills.length < 15
  const handleSkillClick = (skill) => {
    if (!selectedSkills.includes(skill)) {
      const updatedSkills = [...selectedSkills, skill];
      setSelectedSkills(updatedSkills);
    }
  };

  // const handleRemoveSkill = (skill) => {
  //   const updatedSkills = selectedSkills.filter((s) => s !== skill);
  //   setSelectedSkills(updatedSkills);
  // };

  const handleRemoveSkill = async (skill) => {
    const updatedSkills = selectedSkills.filter((s) => s !== skill);
    setSelectedSkills(updatedSkills);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/delete_skill`, {
        user_id: user.userId,
        skill: skill
      });
      console.log('Response from delete skill:', response);
      if (response.data.result.status === 'success') {
        console.log('Skill deleted successfully from backend');
      } else {
        console.error('Error deleting skill:', response.data.result.message);
      }
    } catch (error) {
      console.error('Error deleting skill:', error);
    }
  };


  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBackClick = () => {
    navigate('/freelancer/profile/language');
  };

  const handleNextClick = async () => {
    if (selectedSkills.length === 0) {
      toast.error('You must select at least one skill');
      return;
    }

    localStorage.setItem('selectedSkills', JSON.stringify(selectedSkills));
    console.log('Saved selected skills to localStorage=========', selectedSkills);

    if (user) {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create_skills`, {
        user_id: user.userId,
        skills: selectedSkills.map(skill => ({ skills: skill }))
      });
      console.log('Response from skills==========', response);
      if (response.data.result.status === 'success') {
        navigate('/freelancer/profile/bio');
      } else {
        console.error('Error saving skills:', response.data.result.message);
      }
    } else {
      console.error('User not found in localStorage');
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="skills-container">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="logo" onClick={handleLogoClick} />
        </div>
        <UserNav />
      </header>
      <div className="card">
        <div style={{ display: 'flex' }}>6/10</div>
        <h2>Nearly there! What work are you here to do?</h2>
        <p>Your skills show clients what you can offer, and help us choose which jobs to recommend to you. Add or remove the ones we've suggested, or start typing to pick more. It's up to you.</p>
        <p className="subtext"><a href="#">Why choosing carefully matters</a></p>
        <div className="skills-section">
          <input
            type="text"
            placeholder="Search a skill"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <span className="or-text">or</span>
          <div className="skills-selector">
            <div className="categories">
              {categories.map((category) => (
                <div
                  key={category.name}
                  className={`category-item ${activeCategory === category.name ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category.name)}
                >
                  {category.name}
                </div>
              ))}
            </div>
            <div className="skills-list">
              {categories
                .find((category) => category.name === activeCategory)
                .skills
                .filter((skill) => skill.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((skill) => (
                  <div key={skill} className="skill-item">
                    <span>{skill}</span>
                    <button className='add-button' onClick={() => handleSkillClick(skill)}>+</button>
                  </div>
                ))}
            </div>
            <div className="selected-skills">
              <div style={{ width: '100%' }}>{selectedSkills.length} skills selected</div>
              {selectedSkills.map((skill) => (
                <div key={skill} className="selected-skill-item">
                  <span>{skill}</span>
                  <button className='remove-button' onClick={() => handleRemoveSkill(skill)}>x</button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <button className="back-btn" onClick={handleBackClick}>Back</button>
        <button className="next-btn" onClick={handleNextClick}>Next, write an overview</button>
      </footer>
      <ToastContainer />
    </div>
  );
};

export default Skills;
