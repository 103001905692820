import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FindWork.css';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { BsFillBookmarkFill, BsBookmark } from 'react-icons/bs';
import Header from '../Header/Header';
import demoProfile from '../../../assets/imgs/demo_profile_1.jpg';
import Footer from '../Footer/Footer';
import CompleteProfilePopup from '../../Templates/CompleteProfilePopup';

const FindWork = () => {
    //   const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [isCompleteProfilePopupOpen, setIsCompleteProfilePopupOpen] = useState(false);
    const [profile, setProfile] = useState({
        profilePic: '',
    });
    const [jobListings, setJobListings] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    // const jobListings = [
    //     {
    //         id: 1,
    //         title: 'Figma Designer for Order Entry Screens',
    //         description: 'We are looking for a Figma designer to create order entry screens for our upcoming project...',
    //         type: 'Remote',
    //         paymentVerified: true,
    //         hourlyRate: '$50 - $70/hr',
    //         country: 'United States',
    //         proposals: 'Less than 5',
    //         skills: ['Figma', 'Web Design', 'Graphic Design'],
    //     },
    //     {
    //         id: 2,
    //         title: 'Web designer',
    //         description: 'Creation of webdesign for games ,design of company site...',
    //         type: 'OnSite',
    //         paymentVerified: true,
    //         hourlyRate: '$20 - $30/hr',
    //         country: 'India',
    //         proposals: 'Less than 5',
    //         skills: ['Figma', 'Web Design', 'Graphic Design'],
    //     },
    //     {
    //         id: 3,
    //         title: 'Web designer',
    //         description: 'Creation of webdesign for games ,design of company site...',
    //         type: 'OnSite',
    //         paymentVerified: true,
    //         hourlyRate: '$20 - $30/hr',
    //         country: 'India',
    //         proposals: 'Less than 5',
    //         skills: ['Figma', 'Web Design', 'Graphic Design'],
    //     },
    //     {
    //         id: 4,
    //         title: 'Web designer',
    //         description: 'Creation of webdesign for games ,design of company site...',
    //         type: 'OnSite',
    //         paymentVerified: true,
    //         hourlyRate: '$20 - $30/hr',
    //         country: 'India',
    //         proposals: 'Less than 5',
    //         skills: ['Figma', 'Web Design', 'Graphic Design'],
    //     }
    // ];

    const fetchProfileData = async () => {
        try {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const userId = storedUser ? storedUser.userId : null;

            if (!userId) {
                console.error('User ID not found in localStorage');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch_profile_preview_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch profile data');
            }

            const data = await response.json();
            if (data.result.status === 'success') {
                setProfile(data.result.profile_data);
            } else {
                throw new Error(data.message || 'Failed to fetch profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error.message);
        }
    };

    const fetchJobs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jobs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch jobs');
            }

            const data = await response.json();
            console.log(data);

            if (data.result.status === 'success') {
                setJobListings(data.result.jobs);
            } else {
                throw new Error(data.result.message || 'Failed to fetch jobs');
            }
        } catch (error) {
            console.error('Error fetching jobs:', error.message);
        }
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchProfileData();
        fetchJobs();
    }, []);

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredJobListings = jobListings.filter((job) =>
        job.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleExploreClick = () => {
    };

    const handleJobClick = (id) => {
    };

    const openCompleteProfilePopup = () => {
        setIsCompleteProfilePopupOpen(true);
    };

    const closeCompleteProfilePopup = () => {
        setIsCompleteProfilePopupOpen(false);
    };

    // useEffect(() => {
    //     const storedUser = JSON.parse(localStorage.getItem('user'));
    //     setUser(storedUser);
    //     fetchProfileData(storedUser.id);
    // }, []);

    return (
        <div className="job-listing-container">
            <Header />
            <main className='main-container'>
                <div className='container-left'>
                    <section className="intro-section">
                        <div className="intro-content">
                            <h2>Odoo freelancer will guide you through the basics of our platform.</h2>
                            <h4>Learn how to get started on Odoo Freelancer</h4>
                            <button onClick={handleExploreClick}>Explore Odoo Freelancer</button>
                        </div>
                        <div className='intro-image'>
                            <img src="https://c02.purpledshub.com/uploads/sites/41/2022/08/hybrid-working-toolkit-160a367.jpg" alt="working" />
                        </div>
                    </section>
                    <section className="search-section">
                        <input
                            type="text"
                            placeholder="Search for Jobs"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                        />
                    </section>
                    <section className="job-listings">
                        {/* <div style={{ width: '500px' }}>
                            <h2 style={{ color: '#62495B' }}>Thank You for Registering!</h2>

                            <h3 style={{ fontSize: 'larger' }}>Welcome to Odoo Freelancer!</h3>

                            <p>We’re thrilled to have you on board. Our team is currently working hard to develop and enhance our services to offer you the best experience possible.</p>
                            <p>Your patience and support during this exciting phase are greatly appreciated. If you have any questions or need assistance, please feel free to reach out to us.</p>

                            <p>Stay tuned for more updates!</p>

                            Thank you</div> */}

                        <h3>Jobs you might like</h3>
                        {filteredJobListings.map((job) => (
                            <div key={job.id} className="job-card" onClick={() => handleJobClick(job.id)}>
                                <div className="job-header">
                                    <h4>{job.title}</h4>
                                    <div className="bookmark-icon">
                                        <BsBookmark />
                                    </div>
                                </div>
                                <p>{job.description}</p>
                                <div className="job-info">
                                    <span>{job.type}</span>
                                    <span>${job.hourly_rate}</span>
                                    <span>{job.location}</span>
                                    <span>{job.proposals}</span>
                                </div>
                                <div className="job-skills">
                                    {job.skills.map((skill, index) => (
                                        <span key={index} className="skill">{skill}</span>
                                    ))}
                                </div>
                                <div className="payment-verified">
                                    {job.paymentVerified ? (
                                        <>
                                            <AiFillStar />
                                            <span>Payment verified</span>
                                        </>
                                    ) : (
                                        <>
                                            <AiOutlineStar />
                                            <span>Payment not verified</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </section>
                </div>
                <div className='container-right'>
                    <div className='complete-profile'>
                        <div className="user-info">
                            <div className="user-avatar">{profile.profilePic ? <img src={`data:image/png;base64,${profile.profilePic}`} alt="Profile" className="profile-pic" /> : <img src={demoProfile} alt='Profile' className="profile-pic" />}</div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>{user ? user.userName : 'Guest'}</span>
                                <h6>Web & UI/UX Designer</h6>
                            </div>
                        </div><br />
                        <a href='#' style={{ color: 'green' }} onClick={openCompleteProfilePopup}>complete your profile</a>
                    </div>
                    <div className='features'>
                        <p>Promoted with ads</p>
                        <p>Connects</p>
                        <p>Preferences</p>
                        <p>Proposals</p>
                        <p>Project Catalog</p>
                    </div>
                    <div className='contract'>
                        <p>Direct Contracts</p>
                        <p>Get Paid</p>
                        <p>Community & Forums</p>
                        <p>Help Center</p>
                    </div>
                </div>
            </main>
            <Footer />
            {isCompleteProfilePopupOpen && (
                <CompleteProfilePopup
                    onClose={closeCompleteProfilePopup}
                // onSave={handleCloseAccount}
                />
            )}
        </div>
    );
};

export default FindWork;