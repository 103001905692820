import React, { useState, useEffect } from 'react';
import './EducPopUp.css';
import ConfirmPopUp from './ConfirmPopUp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const years = Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i);

const EducPopUp = ({ onClose, onSave, education }) => {
    const [formState, setFormState] = useState({
        school: '',
        degree: '',
        field: '',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        description: '',
    });

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [filteredEndYears, setFilteredEndYears] = useState(years);

    useEffect(() => {
        if (education) {
            const startDate = education.startDate.split(' ');
            const endDate = education.endDate === 'Present' ? ['Present', ''] : education.endDate.split(' ');

            setFormState({
                school: education.school,
                degree: education.degree,
                field: education.field,
                startMonth: startDate[0],
                startYear: startDate[1],
                endMonth: endDate[0],
                endYear: endDate[1],
                description: education.description,
            });
            if (startDate[1]) {
                setFilteredEndYears(years.filter(year => year >= startDate[1]));
            }
        }
    }, [education]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormState({
            ...formState,
            [name]: type === 'checkbox' ? checked : value,
        });

        if (name === 'startYear') {
            setFilteredEndYears(years.filter(year => year >= value));
            if (formState.endYear && formState.endYear < value) {
                setFormState(prevState => ({ ...prevState, endYear: '' }));
            }
        }
    };

    const handleSubmit = (e) => {
        // e.preventDefault();
        if (e) e.preventDefault();
        const newEducation = {
            school: formState.school,
            degree: formState.degree,
            field: formState.field,
            startDate: `${formState.startYear}`,
            endDate: formState.endMonth === 'Present' ? 'Present' : `${formState.endYear}`,
            description: formState.description,
        };
        onSave(newEducation);
    };

    const handleCancelClick = () => {
        setShowConfirmationPopup(true);
    };

    const handleConfirmSave = () => {
        setShowConfirmationPopup(false);
        handleSubmit();
        onClose();
    };

    const handleCancelClose = () => {
        setShowConfirmationPopup(false);
        onClose();
    };


    return (
        <div className="education-popup">
            <div className="education-popup-inner">
                <form onSubmit={handleSubmit}>
                    <h2 style={{ marginRight: '100px' }}>Add Education History</h2>
                    <label>
                        School
                        <input
                            type="text"
                            name="school"
                            placeholder="School Name"
                            value={formState.school}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Degree
                        <input
                            type="text"
                            name="degree"
                            placeholder="Degree"
                            value={formState.degree}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Field of Study
                        <input
                            type="text"
                            name="field"
                            placeholder="Field of Study"
                            value={formState.field}
                            onChange={handleChange}
                        />
                    </label>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                            <p style={{ fontWeight: '600' }}>Dates Attended</p>
                            <span style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px', width: '120%' }}>
                                <select name="startYear" value={formState.startYear} onChange={handleChange}>
                                    <option value="">From</option>
                                    {years.map(year => <option key={year} value={year}>{year}</option>)}
                                </select>
                                <select
                                    name="endYear"
                                    value={formState.endYear}
                                    onChange={handleChange}
                                    disabled={formState.endMonth === 'Present'}
                                    style={{ marginLeft: '20px' }}
                                >
                                    <option value="">To(or expected graduation year)</option>
                                    {filteredEndYears.map(year => <option key={year} value={year}>{year}</option>)}
                                </select>
                            </span>
                        </div>
                    </div>
                    <label>
                        Description
                        <textarea
                            name="description"
                            placeholder="Describe here"
                            value={formState.description}
                            onChange={handleChange}
                        />
                    </label>
                    <div className="form-buttons">
                        <button type="button" onClick={handleCancelClick} style={{ borderRadius: '30px' }}>
                            Cancel
                        </button>
                        <button type="submit" style={{ borderRadius: '30px' }}>Save</button>
                    </div>
                </form>
            </div>
            {showConfirmationPopup && (
                <ConfirmPopUp
                    onClose={handleCancelClose}
                    onSave={handleConfirmSave}
                />
            )}
        </div>
    );
};

export default EducPopUp;
