import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUpForm.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUpForm = () => {
    const [role, setRole] = useState('');
    const navigate = useNavigate();
    console.log("abse url", process.env.REACT_APP_API_BASE_URL);

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (role === 'client') {
            navigate('/client/register', { state: { role } });
        } else if (role === 'freelancer') {
            navigate('/freelancer/register', { state: { role } });
        }
    };

    const handleLoginClick = (e) => {
        e.preventDefault();
        if (!role) {
            toast.info('Please select a role before login.');
            return;
        }
        if (role === 'client') {
            navigate('/client/login', { state: { role } });
        } else if (role === 'freelancer') {
            navigate('/freelancer/login', { state: { role } });
        }
        // navigate('/login');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    // useEffect(() => {
    //     const storedUser = JSON.parse(localStorage.getItem('user'));
    //     if (storedUser) {
    //         navigate(storedUser.role === 'freelancer' ? '/freelancer/onboarding' : '/client/survey');
    //     }
    // }, [navigate]);

    return (
        <div className="signup-form-container">
            <div className="logo" onClick={handleLogoClick}><img style={{ height: '65px' }} src={logo} alt="Logo" /></div>
            <h2 style={{ marginRight: 'auto', fontWeight: '600' }}>Sign Up</h2>
            <br />
            <form onSubmit={handleSubmit}>
                <h3>Join as a client or odoo freelancer</h3>
                <div className="role-selection">
                    <label className={`role-option ${role === 'client' ? 'selected' : ''}`}>
                        <input
                            type="radio"
                            name="role"
                            value="client"
                            checked={role === 'client'}
                            onChange={handleRoleChange}
                        />
                        <div className="role-content">
                            <div className="icon"><svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 31.36C31.0592 31.36 36.8 25.6192 36.8 18.56C36.8 11.5008 31.0592 5.76001 24 5.76001C16.9408 5.76001 11.2 11.5008 11.2 18.56C11.2 25.6192 16.9408 31.36 24 31.36ZM17.664 23.0848C18.1632 22.5856 18.976 22.5856 19.4752 23.0848C19.8272 23.4368 20.2176 23.7376 20.6208 23.9936C22.6816 25.2736 25.3184 25.2736 27.3792 23.9936C27.7824 23.7376 28.1728 23.4368 28.5248 23.0848C29.024 22.5856 29.8368 22.5856 30.336 23.0848C30.8352 23.584 30.8352 24.3968 30.336 24.896C28.5888 26.6432 26.2912 27.5136 24 27.5136C21.7088 27.5136 19.4112 26.6432 17.664 24.896C17.1648 24.3968 17.1648 23.584 17.664 23.0848ZM32.2432 58.24H8V49.92C8 41.0944 15.1744 33.92 24 33.92C24.9408 33.92 25.8624 34.0096 26.7648 34.1632C24.958 36.9493 23.9977 40.1994 24 43.52C24 49.7344 27.3024 55.1936 32.2432 58.24ZM41.28 28.8C33.1648 28.8 26.56 35.4048 26.56 43.52C26.56 51.6352 33.1648 58.24 41.28 58.24C49.3952 58.24 56 51.6352 56 43.52C56 35.4048 49.3952 28.8 41.28 28.8ZM42.56 51.2V53.12C42.56 53.824 41.984 54.4 41.28 54.4C40.576 54.4 40 53.824 40 53.12V51.2H38.08C37.376 51.2 36.8 50.624 36.8 49.92C36.8 49.216 37.376 48.64 38.08 48.64H42.56C43.616 48.64 44.48 47.776 44.48 46.72C44.48 45.664 43.616 44.8 42.56 44.8H40C37.5296 44.8 35.52 42.7904 35.52 40.32C35.52 37.8496 37.5296 35.84 40 35.84V33.92C40 33.216 40.576 32.64 41.28 32.64C41.984 32.64 42.56 33.216 42.56 33.92V35.84H44.48C45.184 35.84 45.76 36.416 45.76 37.12C45.76 37.824 45.184 38.4 44.48 38.4H40C38.944 38.4 38.08 39.264 38.08 40.32C38.08 41.376 38.944 42.24 40 42.24H42.56C45.0304 42.24 47.04 44.2496 47.04 46.72C47.04 49.1904 45.0304 51.2 42.56 51.2Z" fill="#3F3F46" />
                            </svg></div>
                            <div>
                                <p><strong>I am a Client,</strong><p>Hiring for a project</p></p>
                            </div>
                        </div>
                    </label>
                    <label className={`role-option ${role === 'freelancer' ? 'selected' : ''}`}>
                        <input
                            type="radio"
                            name="role"
                            value="freelancer"
                            checked={role === 'freelancer'}
                            onChange={handleRoleChange}
                        />
                        <div className="role-content">
                            <div className="icon"><svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.6666 16.6667C10.6666 15.0754 11.2987 13.5493 12.4239 12.4241C13.5492 11.2989 15.0753 10.6667 16.6666 10.6667H47.3333C48.1212 10.6667 48.9014 10.8219 49.6294 11.1235C50.3573 11.425 51.0187 11.867 51.5759 12.4241C52.133 12.9813 52.575 13.6427 52.8765 14.3706C53.1781 15.0986 53.3333 15.8788 53.3333 16.6667V27.5654C51.3381 26.6172 49.0714 26.4073 46.9361 26.9731C44.8008 27.539 42.9354 28.8438 41.6717 30.6556C40.4079 32.4674 39.8278 34.6686 40.0344 36.8679C40.2409 39.0672 41.2209 41.122 42.7999 42.6667H16.6666C15.0753 42.6667 13.5492 42.0346 12.4239 40.9094C11.2987 39.7842 10.6666 38.258 10.6666 36.6667V16.6667ZM34.6666 50.0587C34.6666 48.8347 34.9626 47.6827 35.4879 46.6667H7.33325C6.80282 46.6667 6.29411 46.8775 5.91904 47.2525C5.54397 47.6276 5.33325 48.1363 5.33325 48.6667C5.33325 49.1972 5.54397 49.7059 5.91904 50.081C6.29411 50.456 6.80282 50.6667 7.33325 50.6667H34.6719L34.6666 50.3334V50.0587ZM55.9999 36.0001C55.9999 37.7682 55.2975 39.4639 54.0473 40.7141C52.7971 41.9644 51.1014 42.6667 49.3333 42.6667C47.5651 42.6667 45.8695 41.9644 44.6192 40.7141C43.369 39.4639 42.6666 37.7682 42.6666 36.0001C42.6666 34.232 43.369 32.5363 44.6192 31.286C45.8695 30.0358 47.5651 29.3334 49.3333 29.3334C51.1014 29.3334 52.7971 30.0358 54.0473 31.286C55.2975 32.5363 55.9999 34.232 55.9999 36.0001ZM61.3333 50.3334C61.3333 54.4827 57.9039 58.6667 49.3333 58.6667C40.7626 58.6667 37.3333 54.4987 37.3333 50.3334V50.0587C37.3333 47.4454 39.4506 45.3334 42.0613 45.3334H56.6053C59.2186 45.3334 61.3333 47.4481 61.3333 50.0587V50.3334Z" fill="#3F3F46" />
                            </svg></div>
                            <div>
                                <p><strong>I am a Odoo Freelancer,</strong><p>Looking for a project</p></p>
                            </div>
                        </div>
                    </label>
                </div>
                <div style={{ paddingTop: '160px' }}>
                    <button type="submit" className={`create-account-btn ${role ? 'active' : ''}`} disabled={!role}>
                        Create Account
                    </button>
                    <div className="login-link">
                        Already have an account? <a href="" onClick={handleLoginClick} disabled={!role}>Login</a>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default SignUpForm;