import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PiUserCircleFill } from 'react-icons/pi';
import { FaLaptopCode, FaUserCog } from 'react-icons/fa';
import { RiLogoutBoxRFill, RiUserSettingsLine } from "react-icons/ri";
import './Navbar.css';
import demoProfile from '../../../assets/imgs/demo_profile_1.jpg';

const Navbar = () => {
    const [user, setUser] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const [profile, setProfile] = useState({
        profilePic: '',
    });

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchProfileData(storedUser.id);
    }, []);

    const fetchProfileData = async () => {
        try {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const userId = storedUser ? storedUser.userId : null;

            if (!userId) {
                console.error('User ID not found in localStorage');
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fetch_profile_preview_data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch profile data');
            }

            const data = await response.json();
            if (data.result.status === 'success') {
                setProfile(data.result.profile_data);
            } else {
                throw new Error(data.message || 'Failed to fetch profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error.message);
        }
    };

    const handleToggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleCloseAccount = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_id: user ? user.userId : null })
            });
            console.log("response from close account============", response);

            const result = await response.json();
            console.log("result===============", result);
            if (result.result.status === 'success') {
                localStorage.removeItem('user');
                navigate('/');
            } else {
                console.error(result.message);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };


    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/');
    };

    const handleSetting = () => {
        navigate('/freelancer/setting');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="navbar-container">
            <div className="user-profile" onClick={handleToggleDropdown}>
                <div>{profile.profilePic ? <img src={`data:image/png;base64,${profile.profilePic}`} alt="Profile" className="profile-photo" /> : <img src={demoProfile} alt='Profile' className="profile-photo" />}</div>
                <div>
                    <div className="user-name">
                        &nbsp; {user ? user.firstName.split(' ')[0] : 'Guest'}
                    </div>
                    <div className="user-role">
                        {user ? user.role === 'freelancer' ? 'Odoo Freelancer' : 'Odoo Client' : ''} &nbsp;<FaLaptopCode />
                    </div>
                </div>
            </div>
            {dropdownOpen && (
                <div className="dropdown-nav-buttons">
                    {/* <div className="dropdown-nav-item" onClick={handleCloseAccount}>
                        <FaUserCog /> Close Account
                    </div> */}
                    <div className="dropdown-nav-item" onClick={handleSetting}>
                        <RiUserSettingsLine /> Account Settings
                    </div>
                    <div className="dropdown-nav-item" onClick={handleLogout}>
                        <RiLogoutBoxRFill /> Log Out
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
