import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientSurvey1.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { PhoneInput } from 'react-international-phone';
// import 'react-international-phone/style.css';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const ClientSurvey1 = () => {
    const [user, setUser] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyContact, setCompanyContact] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);

        const fetchCountryCode = async () => {
            if (storedUser) {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/user`, {
                    user_id: storedUser.userId,
                });
                console.log("response in setting fetching=====", response);

                if (response.data.result.status === 'success') {
                    setCountryCode(response.data.result.country_code);
                    // setCountryCode(response.data.result.country_code.toLowerCase());
                }
            } else {
                console.error('Failed to fetch user data');
            }
        }

        fetchCountryCode();
    }, []);
    console.log("country code ---------------", countryCode);


    const fetchSurveyQuestions = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

            if (response.data.result.status === 'success') {
                setSurveyUserInputId(response.data.result.survey_user_input_id);
                setQuestions(response.data.result.questions);

                setCompanyName(response.data.result.questions[0].answer);
                setCompanyEmail(response.data.result.questions[1].answer);
                setCompanyAddress(response.data.result.questions[2].answer);
                setCompanyContact(response.data.result.questions[3].answer);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching survey questions:', error);
            toast.error('Error fetching survey questions');
        }
    };

    const handleNextButton = async () => {

        if (!companyName || !companyEmail || !companyContact) {
            toast.error('All questions are mandatory.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(companyEmail)) {
            toast.error('Please provide a valid email address.');
            return;
        }

        try {
            const answers = [
                { question_id: questions[0].id, answer: companyName },
                { question_id: questions[1].id, answer: companyEmail },
                { question_id: questions[2].id, answer: companyAddress },
                { question_id: questions[3].id, answer: companyContact },
            ];

            for (let answer of answers) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                    user_id: user.userId,
                    survey_user_input_id: surveyUserInputId,
                    question_id: answer.question_id,
                    answer: answer.answer,
                });
            }
            navigate('/client/survey_2');
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('Error submitting survey response');
        }
    };

    const isValidPhoneNumber = (value) => {
        const phoneNumberOnly = value.replace(/[^0-9]/g, '');
        return phoneNumberOnly.length > 4;
    };

    const handleBackButton = () => {
        // navigate('/freelancer/profile/skills');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-survey1-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="survey1-contents">
                <section className="survey1-section">
                    <div style={{ display: 'flex' }}>1/7</div><br />
                    <h2>Got it. Now, tell us something about yourself and your project.</h2>
                    <p>Please take a few minutes to share details about your project so we can connect you with the best Odoo freelancers.</p>

                    <form>
                        <div className="input-group">
                            <label>What is the name of your company?<sup className='madatory'>*</sup></label>
                            <input
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>Could you please provide your company's email address?<sup className='madatory'>*</sup></label>
                            <input
                                type="email"
                                value={companyEmail}
                                onChange={(e) => setCompanyEmail(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>Could you please provide your company's address?</label>
                            <input
                                type="text"
                                value={companyAddress}
                                onChange={(e) => setCompanyAddress(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>Could you please provide your company's contact?<sup className='madatory'>*</sup></label>
                            {countryCode && (
                                // <PhoneInput
                                //     style={{ height: '50px', width: '70%' }}
                                //     defaultCountry={countryCode}
                                //     value={companyContact}
                                //     onChange={(companyContact) => setCompanyContact(companyContact)}
                                //     // inputProps={{
                                //     //     required: true,
                                //     //     autoFocus: true,
                                //     // }}
                                //     // disableAreaCodes= 'true'
                                //     // disableCountryChange = 'true'
                                //     // validate={isValidPhoneNumber}
                                // />
                                <PhoneInput
                                    style={{ height: '50px', width: '70%' }}
                                    placeholder="Enter phone number"
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry={countryCode}
                                    value={companyContact}
                                    limitMaxLength={true}
                                    onChange={(companyContact) => setCompanyContact(companyContact)}
                                />
                            )}
                        </div>
                    </form>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src="https://t4.ftcdn.net/jpg/03/83/25/83/360_F_383258331_D8imaEMl8Q3lf7EKU2Pi78Cn0R7KkW9o.jpg" alt="Natalia G." className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Natalia G.</div>
                                <span>Client</span>
                                {/* <div className="testimonial-stats">
                                    <span>5.0</span> <span>50.6k</span> <span>1.4k Jobs</span>
                                </div> */}
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "I rely on Odoo Freelancer for finding meaningful freelance work. The platform's vetting process ensures quality clients, and its messaging system makes communication straightforward. It's empowering to have Odoo Freelancer support my career growth in such a dynamic and supportive environment."
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                {/* <button className="back-btn" onClick={handleBackButton}>Back</button> */}
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default ClientSurvey1;
