import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { FaRegBell, FaChevronDown, FaChevronUp, FaBriefcase, FaUsers } from "react-icons/fa";
import { AiOutlineCaretDown } from "react-icons/ai";
import Navbar from '../Navbar/Navbar';

const Header = () => {
    const navigate = useNavigate();

    const [findworkDropdownOpen, setFindworkDropdownOpen] = useState(false);
    const [deliverworkDropdownOpen, setDeliverworkDropdownOpen] = useState(false);
    const [manageDropdownOpen, setManageDropdownOpen] = useState(false);
    const [jobDropdownOpen, setJobDropdownOpen] = useState(false);

    const handleFindWorkDropdown = () => {
        setFindworkDropdownOpen(!findworkDropdownOpen)
        setDeliverworkDropdownOpen(false)
        setManageDropdownOpen(false)
        setJobDropdownOpen(false)
    }

    const handleDeliverWorkDropdown = () => {
        setDeliverworkDropdownOpen(!deliverworkDropdownOpen)
        setFindworkDropdownOpen(false)
        setManageDropdownOpen(false)
        setJobDropdownOpen(false)
    }

    const handleManageDropdown = () => {
        setManageDropdownOpen(!manageDropdownOpen)
        setFindworkDropdownOpen(false)
        setDeliverworkDropdownOpen(false)
        setJobDropdownOpen(false)
    }

    const handleJobDropdown = () => {
        setJobDropdownOpen(!jobDropdownOpen)
        setFindworkDropdownOpen(false)
        setDeliverworkDropdownOpen(false)
        setManageDropdownOpen(false)
    }

    const handleJob = () => {
        navigate('/freelancer/search_jobs')
    }

    const handleFindWork = () => {
        navigate('/freelancer/find_work')
    }

    const handleSavedJobs = () => {
        navigate('/freelancer/saved_jobs')
    }

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <header className="user-header">
            <div className="logo" onClick={handleLogoClick}><img src={logo} alt="Logo" /></div>
            <nav>
                <ul>
                    <li onClick={handleFindWorkDropdown}>
                        Find Work&nbsp;{findworkDropdownOpen ? (<FaChevronUp />) : (<FaChevronDown />)}
                        {findworkDropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item" onClick={handleFindWork}>Find Work</div>
                                <div className="dropdown-item" onClick={handleSavedJobs}>Saved Jobs</div>
                                <div className="dropdown-item">Proposals and Offers</div>
                            </div>
                        )}
                    </li>
                    <li onClick={handleDeliverWorkDropdown}>
                        Deliver Work&nbsp;{deliverworkDropdownOpen ? (<FaChevronUp />) : (<FaChevronDown />)}
                        {deliverworkDropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item">Your Active Jobs</div>
                                <div className="dropdown-item">Hourly work diary</div>
                                <div className="dropdown-item">Client History</div>
                            </div>
                        )}
                    </li>
                    <li onClick={handleManageDropdown}>
                        Manage Finances&nbsp;{manageDropdownOpen ? (<FaChevronUp />) : (<FaChevronDown />)}
                        {manageDropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item">Financial Overview</div>
                                <div className="dropdown-item">Your Reports</div>
                                <div className="dropdown-item">Billing and Earnings</div>
                                <div className="dropdown-item">Transactions and Invoices</div>
                                <div className="dropdown-item">Certificate of Earnings</div>
                            </div>
                        )}
                    </li>
                    <li>Messages</li>
                </ul>
            </nav>
            <div className="search-container">
                <div className="input-dropdown-wrapper">
                    <input
                        type="text"
                        placeholder="Search here"
                        className="search-input"
                    />
                    <div className="search-dropdown-trigger" onClick={handleJobDropdown}>
                        Jobs <AiOutlineCaretDown />
                    </div>
                    {jobDropdownOpen && (
                        <div className="search-dropdown-menu">
                            <div className="search-dropdown-item">
                                <FaBriefcase className="dropdown-icon" />
                                <span onClick={handleJob}>Jobs</span>
                            </div>
                            <div className="search-dropdown-item">
                                <FaUsers className="dropdown-icon" />
                                <span>Talent</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <input
                type="text"
                placeholder="Search here"
                name="search"
            /> */}
            <FaRegBell />
            <Navbar />
        </header>
    );
};

export default Header;
