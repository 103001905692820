import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileReady.css';
import UserNav from '../../Auth/UserNav/UserNav';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';

const ProfileReady = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
    }, []);

    const handleViewProfile = () => {
        navigate('/freelancer/profile/review-profile');
    };

    const handleBrowseJobs = () => {
        navigate('/freelancer/find_work');
    };

    return (
        <div className="profile-ready-container">
            <header>
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>

                <div className="user-info">
                    {/* <div className="user-avatar"></div> */}
                    <UserNav />
                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{user ? user.userName : 'Guest'}</span>
                        <span>Odoo Buddy</span>
                    </div> */}
                </div>
            </header>
            <main>
                <div className="profile-circle">
                    <img src="https://st4.depositphotos.com/4218696/38603/i/450/depositphotos_386030508-stock-photo-happy-afro-woman-screaming-and.jpg" alt="Profile" />
                </div>
                <h2>Nice work,&nbsp;<span style={{ color: '#62495B' }}>{user ? user.userName : 'Guest'}</span>!</h2>
                <h2>Your profile’s ready.</h2><br />
                <p>Congratulations! With thousands to choose from, it's time to start bidding on roles that are the perfect fit for your skills.</p>
                <div className="button-group">
                    <button onClick={handleViewProfile} className='view-profile-btn'>View My Profile</button>
                    <button onClick={handleBrowseJobs} className='browse-job-btn'>Browse Jobs</button>
                </div>
            </main>
        </div>
    );
};

export default ProfileReady;
