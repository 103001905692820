import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Language.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { MdDeleteForever } from "react-icons/md";
import UserNav from '../../Auth/UserNav/UserNav';

const Language = () => {
    const navigate = useNavigate();
    const [languages, setLanguages] = useState([{ language: 'English', proficiency: 'Basic' }]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage:', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_language';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetching languages===============", requestOptions);
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setLanguages(data.result.languages.length > 0 ? data.result.languages : [{ language: 'English', proficiency: 'Basic' }]);
                        console.log('Loaded languages from backend:', data.result.languages);
                    } else {
                        console.error('Error fetching languages:', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching languages:', error);
                });
        }
    }, []);

    const addLanguage = () => {
        setLanguages([...languages, { language: '', proficiency: 'Basic' }]);
    };

    // const handleLanguageChange = (index, field, value) => {
    //     const updatedLanguages = languages.map((lang, i) =>
    //         i === index ? { ...lang, [field]: value } : lang
    //     );
    //     setLanguages(updatedLanguages);
    //     localStorage.setItem('languages', JSON.stringify(updatedLanguages));
    // };

    const handleLanguageChange = async (index, field, value) => {
        const updatedLanguages = languages.map((lang, i) =>
          i === index ? { ...lang, [field]: value } : lang
        );
        setLanguages(updatedLanguages);
        localStorage.setItem('languages', JSON.stringify(updatedLanguages));
      
        if (field === 'proficiency') {
          const languageToUpdate = updatedLanguages[index].language;
          const proficiencyToUpdate = value;
      
          try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update_language`, {
              user_id: user.userId,
              language: languageToUpdate,
              proficiency: proficiencyToUpdate
            });
            console.log('Response from update language proficiency:', response);
            if (response.data.result.status === 'success') {
              console.log('Language proficiency updated successfully on backend');
            } else {
              console.error('Error updating language proficiency:', response.data.result.message);
            }
          } catch (error) {
            console.error('Error updating language proficiency:', error);
          }
        }
      };
      

    // const handleDeleteLanguage = (index) => {
    //     const updatedLanguages = languages.filter((_, i) => i !== index);
    //     setLanguages(updatedLanguages);
    //     localStorage.setItem('languages', JSON.stringify(updatedLanguages));
    // };

    const handleDeleteLanguage = async (index) => {
        const languageToDelete = languages[index].language;

        if (languageToDelete !== 'English') {
            const updatedLanguages = languages.filter((_, i) => i !== index);
            setLanguages(updatedLanguages);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/delete_language`, {
                    user_id: user.userId,
                    language: languageToDelete
                });
                console.log('Response from delete language:', response);
                if (response.data.result.status === 'success') {
                    console.log('Language deleted successfully from backend');
                } else {
                    console.error('Error deleting language:', response.data.result.message);
                }
            } catch (error) {
                console.error('Error deleting language:', error);
            }
        } else {
            console.log('Cannot delete English language');
        }
    };

    const handleBackButton = () => {
        navigate('/freelancer/profile/education');
    };

    const handleNextButton = async () => {
        const validLanguages = languages.filter(lang => lang.language);
        setLanguages(validLanguages);
        localStorage.setItem('languages', JSON.stringify(validLanguages));
        console.log('Saved languages to localStorage:', validLanguages);

        if (user) {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create_language`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: user.userId,
                    languages: validLanguages
                }),
            });
            console.log("response in language ==============", response);
            const result = await response.json();

            if (result.result.status === 'success') {
                console.log('Languages saved successfully:', result);
                navigate('/freelancer/profile/skills');
            } else {
                console.error('Error saving languages:', result.result.message);
            }
        } else {
            console.error('User not found in localStorage');
        }
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="language-container">
            <header className="header">
                <div className="logo">
                    <img src={logo} alt="logo" onClick={handleLogoClick} />
                </div>
                <UserNav />
            </header>
            <div className="card">
                <div style={{ display: 'flex' }}>5/10</div><br />
                <h2>Looking good. Next, tell us which languages you speak.</h2>
                <p>This platform is global, so clients are often interested to know what languages you speak. English is a must, but do you speak any other languages?</p>
                <div className='heading'>
                    <div>Language</div>
                    <div style={{ marginLeft: '29%' }}>Proficiency</div>
                </div>
                <div className="languages-list">
                    {languages.map((lang, index) => (
                        <div key={index} className="language-item">
                            {lang.language === 'English' ? (
                                <>
                                    <input
                                        type="text"
                                        value={lang.language}
                                        readOnly
                                        style={{ backgroundColor: '#f0f0f0' }}
                                    />
                                    <select
                                        value={lang.proficiency}
                                        onChange={(e) => handleLanguageChange(index, 'proficiency', e.target.value)}
                                    >
                                        <option value="Basic">Basic</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Fluent">Fluent</option>
                                    </select>
                                    <div><MdDeleteForever style={{ color: '#ddd', cursor: 'not-allowed' }} /></div>
                                </>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        placeholder="Language"
                                        value={lang.language}
                                        onChange={(e) => handleLanguageChange(index, 'language', e.target.value)}
                                    />
                                    <select
                                        value={lang.proficiency}
                                        onChange={(e) => handleLanguageChange(index, 'proficiency', e.target.value)}
                                    >
                                        <option value="Basic">Basic</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Fluent">Fluent</option>
                                    </select>
                                    <div onClick={() => handleDeleteLanguage(index)}><MdDeleteForever /></div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <div className='new-language'>
                    <button onClick={addLanguage}>+ Add Language</button>
                </div>
            </div>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next, add your skills</button>
            </footer>
        </div>
    );
};

export default Language;



//     const handleNextButton = () => {
//         const validLanguages = languages.filter(lang => lang.language);
//         setLanguages(validLanguages);
//         localStorage.setItem('languages', JSON.stringify(validLanguages));
//         console.log('Saved languages to localStorage:', validLanguages);
//         navigate('/profile/skills');
//     };
