import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Title.css";
import axios from 'axios';
import ClientHeader from '../../ClientHeader/ClientHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Title = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [jobTitle, setJobTitle] = useState('');
    const [jobId, setJobId] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        const storedJob = JSON.parse(localStorage.getItem('job'));
        if (storedJob) {
            setJobId(storedJob.jobId);
        }
    }, []);

    useEffect(() => {
        if (jobId) {
            fetchJobDetails(jobId);
        }
    }, [jobId]);

    const fetchJobDetails = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/job/details`, { job_id: id });
            console.log(response);

            if (response.data.result.status === 'success') {
                setJobTitle(response.data.result.job.title);
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
        }
    };

    const handleNext = async () => {
        if (jobTitle === '') {
            toast.error('Title is mandatory');
            return;
        }

        if (jobId && jobTitle) {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/job/update`, {
                job_id: jobId,
                title: jobTitle,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);

            if (response.data.result.status === 'success') {
                navigate('/client/project/skills');
            } else {
                alert('Error updating job details: ' + response.data.result.message);
            }
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create/job`, {
                name: jobTitle,
                user_id: user?.userId,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(response);


            if (response.data.result.status === 'success') {
                setJobId(response.data.result.job_id);
                localStorage.setItem('job', JSON.stringify({ jobId: response.data.result.job_id }));
                navigate('/client/project/skills');
            } else {
                alert("Failed to create job. Please try again.");
            }
        } catch (error) {
            console.error("Error creating job:", error);
            alert("An error occurred while creating the job.");
        }
    };

    // const handleNext = () => {
    //     navigate('/client/new_project/skills');
    // };

    const handleBack = () => {
        navigate('/client/dashboard');
    };

    return (
        <div className="title-container">
            <ClientHeader />

            <div className="title-content">
                <div className="progress">1/5</div>
                <h2>Let's start with a strong title.</h2>
                <p className="description">This helps your job post stand out to the right candidates. It’s the first thing they’ll see, so make it count!</p>

                <div className="input-container">
                    <label>Write a title for your job post</label>
                    <input
                        type="text"
                        className="title-input"
                        placeholder="Title"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                    />
                </div>

                <div className="example-titles">
                    <p>Example titles</p>
                    <ul>
                        <li>Odoo integration and implementation</li>
                        <li>Quickbook migration</li>
                        <li>Odoo customization</li>
                    </ul>
                </div>
            </div>

            <div className="navigation-buttons">
                <button className="back-button" onClick={handleBack}>Back</button>
                <button className="next-button" onClick={handleNext}>Next</button>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Title