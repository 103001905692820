import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './IntegrationPopup.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IntegrationPopup = ({ onClose }) => {
    const [user, setUser] = useState(null);
    const [integrationDetails, setIntegrationDetails] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setIntegrationDetails(response.data.result.questions[13].answer);
        }
    };

    const handleSaveButton = async () => {
        if (!integrationDetails) {
            toast.error('All questions are mandatory.');
            return;
        }

        const answers = [
            { question_id: questions[13].id, answer: integrationDetails },
        ];

        for (let answer of answers) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                survey_user_input_id: surveyUserInputId,
                question_id: answer.question_id,
                answer: answer.answer,
                user_id: user.userId,
            });
        }
        onClose();
    };

    return (
        <div className='integration-edit-popup-main'>
            <div className="integration-edit-popup">
                <IoMdClose className="integration-edit-pop-close" onClick={onClose} />
                <section className="integration-sections">
                    <form>
                        <div className="question">
                            <label>Do you require Odoo to integrate with any other systems or software that you are currently using?</label>
                            <textarea
                                placeholder="Describe"
                                value={integrationDetails}
                                onChange={(e) => setIntegrationDetails(e.target.value)}
                            />
                        </div>
                    </form>
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
            </div >
            <ToastContainer />
        </div>
    );
}

export default IntegrationPopup;
