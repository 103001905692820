import React from 'react';
import './ProfileSection.css';
import { MdEdit } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import { PiDotsThreeCircle } from "react-icons/pi";

const ProfileSection = () => {
    return (
        <section className="profile-section">

            <div className="profile-visibility">
                <div className="header-left">
                    <h2>My Profile</h2>
                    <a href="">View my profile as others see it</a>
                </div>
                <div className="visibility-options">
                    <label>Visibility</label>
                    <select>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                    </select>
                </div>
                <div className="visibility-options">
                    <label>Project preference <IoInformationCircleOutline /></label>
                    <select>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                    </select>
                </div>
                <div className="earnings-privacy">
                    <h5>Earnings Privacy <IoInformationCircleOutline /></h5>
                    <p>Want to keep your earnings private?</p>
                    <p><a href='' style={{ color: '#62495B' }}>Upgrade to Plus Services</a> to enable this setting</p>
                </div>
            </div>

            <div className="experience-level">
                <h3>Experience level</h3>
                <div className="experience-options">
                    <label className="experience-option">
                        <span className="text">Beginner <br />
                            <span className="subtext">I am relatively new to this field</span>
                        </span>
                        <input
                            type="radio"
                            name="experience"
                            value="Beginner"
                        // checked={experience === 'Beginner'}
                        // onChange={handleChange}
                        />
                    </label>
                    <label className="experience-option">
                        <span className="text">Intermediate <br />
                            <span className="subtext">I have substantial experience in this field</span>
                        </span>
                        <input
                            type="radio"
                            name="experience"
                            value="Intermediate"
                        // checked={experience === 'Intermediate'}
                        // onChange={handleChange}
                        />
                    </label>
                    <label className="experience-option">
                        <span className="text">Advanced <br />
                            <span className="subtext">I have comprehensive and deep expertise in this field</span>
                        </span>
                        <input
                            type="radio"
                            name="experience"
                            value="Advanced"
                        // checked={experience === 'Advanced'}
                        // onChange={handleChange}
                        />
                    </label>
                </div>
            </div>

            <div className="categories-section">
                <div className='edit-btn-div'>
                    <h3>Categories</h3>
                    <button className="edit-button"><MdEdit /></button>
                </div>
                <div className="categories-list">
                    <div className="category-tag">Web & Mobile Design</div>
                    <div className="category-tag">Admin Support</div>
                    <div className="category-tag">Data Entry & Transcription Services</div>
                    <div className="category-tag">Design & Creative</div>
                    <div className="category-tag">Art & Illustration</div>
                </div>
            </div>

            <div className="specialized-profiles">
                <h3>Specialized profiles</h3>
                <p>0 out of 2 published</p>
                <p>Create up to two different versions of your profile to more effectively highlight your individual specialties.<a href='' style={{ color: '#62495B' }}>Learn More</a></p>
                <div className="profile-list">
                    <div className="profile-tag">
                        <span>UX/UI Design - Draft</span>
                        <div className="profile-actions">
                            <button><PiDotsThreeCircle style={{ height: '30px', width: '30px' }} /></button>
                        </div>
                    </div>
                </div>
                <button className="add-profile-button">Add Specialized Profile</button>
            </div>

            <div className="linked-accounts">
                <h3>Linked accounts</h3>
                <div className="account-list">
                    <div className="account-tag">Dribble</div>
                    <div className="account-tag">Github</div>
                </div>
            </div>
        </section>
    );
};

export default ProfileSection;
