import React, { useRef, useState, useEffect } from 'react';
import './ResumePopUp.css';
import { useNavigate } from 'react-router-dom';

const ResumePopUp = ({ onClose, onUploadSuccess }) => {
    const fileInputRef = useRef(null);
    const [user, setUser] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
    }, []);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        console.log('Selected file details:', file);

        if (file) {
            setSelectedFile(file);
        }
    };

    const handleContinue = async () => {
        if (selectedFile && user) {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('user_id', user.userId);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/linkedin/upload`, {
                    method: 'POST',
                    body: formData,
                });
                console.log('response in linkedin:', response);
                if (response.ok) {
                    const result = await response.json();
                    console.log('OpenAI API response:', result);
                    navigate('/freelancer/profile/role');
                    // onClose();
                } else {
                    console.error('Failed to upload file');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.error('No file selected or user not found');
        }
        handleClose();
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
    };

    return (
        <div className="resume-model">
            <div className="model-content">
                <span className="close" onClick={handleClose}>&times;</span>
                <h2 style={{ textAlign: 'center' }}>Upload your resume here </h2>
                <p style={{ textAlign: 'left' }}>
                    <strong style={{ color: '#62495B' }}>Step 1:</strong> If you haven't already, make a resume and save your Resume as a PDF.
                </p>
                <p style={{ textAlign: 'left' }}>
                    <strong style={{ color: '#62495B' }}>Step 2:</strong> Come back here to upload it.
                </p>
                <input
                    type="file"
                    accept=".pdf"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                />
                {selectedFile ? (
                    <div className="file-name-box">
                        <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '170px' }}>{selectedFile.name}<span className="close-icon" onClick={handleRemoveFile}>&times;</span></p>
                    </div>
                ) : (
                    <button className="upload-button" onClick={() => fileInputRef.current.click()}>Upload your Resume PDF</button>
                )}
                <button className="continue-button" onClick={handleContinue}>Continue</button>
                {isUploading && (
                    <p style={{ textAlign: 'center', color: '#62495B', marginTop: '10px' }}>Please wait for few seconds...</p>
                )}
            </div>
        </div>
    );
};

export default ResumePopUp;
