import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ClientPreview.css';
import axios from 'axios';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import sideImg from '../../../assets/imgs/Rectangle 97.png';
import { MdEdit } from "react-icons/md";
import { FaFileAlt } from "react-icons/fa";
import ClientNavbar from '../ClientNavbar/ClientNavbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomizationPopup from '../ClientEditPopUp/CustomizationPopup';
import ImplementationPopup from '../ClientEditPopUp/ImplementationPopup';
import IntegrationPopup from '../ClientEditPopUp/IntegrationPopup';
import MaintenancePopup from '../ClientEditPopUp/MaintenancePopup';
import BudgetPopup from '../ClientEditPopUp/BudgetPopup';
import FileEditPopup from '../ClientEditPopUp/FileEditPopup';

const ClientPreview = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [odooPurchased, setOdooPurchased] = useState(null);
    const [odooUsageDuration, setOdooUsageDuration] = useState(null);
    const [customizationsMade, setCustomizationsMade] = useState(null);
    const [customizationDetails, setCustomizationDetails] = useState('');
    const [currentSystems, setCurrentSystems] = useState('');
    const [implementation, setImplementation] = useState('');
    const [integrationDetails, setIntegrationDetails] = useState('');
    const [implementingSupport, setImplementingSupport] = useState('');
    const [managingPlan, setManagingPlan] = useState(null);
    const [timeline, setTimeline] = useState('');
    const [budget, setBudget] = useState('');
    const [filenames, setFilenames] = useState([]);
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    const [showCustomizationEditPop, setShowCustomizationEditPop] = useState(false);
    const [showImplementationEditPop, setShowImplementationEditPop] = useState(false);
    const [showIntegrationEditPop, setShowIntegrationEditPop] = useState(false);
    const [showMaintenanceEditPop, setShowMaintenanceEditPop] = useState(false);
    const [showBudgetEditPop, setShowBudgetEditPop] = useState(false);
    const [showFileEditPop, setShowFileEditPop] = useState(false);

    const [showMaintenanceQue, setShowMaintenanceQue] = useState(false);
    const [showCustomizeDetailQue, setShowCustomizeDetailQue] = useState(false);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
        fetchSupportDocument(storedUser.userId);
    }, []);

    const fetchSupportDocument = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/get_document_filename`, { user_id: userId });
            console.log("response of filename======", response);

            if (response.data.result.status === 'success') {
                setFilenames(response.data.result.filenames);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            toast.error("Failed to fetch supporting document");
        }
    };

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setOdooPurchased(response.data.result.questions[4].answer);
            setOdooUsageDuration(response.data.result.questions[5].answer);
            setCustomizationsMade(response.data.result.questions[6].answer);
            setCustomizationDetails(response.data.result.questions[7].answer);
            setCurrentSystems(response.data.result.questions[11].answer);
            setImplementation(response.data.result.questions[12].answer);
            setIntegrationDetails(response.data.result.questions[13].answer);
            setImplementingSupport(response.data.result.questions[14].answer);
            setManagingPlan(response.data.result.questions[15].answer);
            // setTimeline(response.data.result.questions[16].answer);
            // setBudget(response.data.result.questions[17].answer);
        } else {
            toast.error(response.data.result.message);
        }
    };

    useEffect(() => {
        setShowMaintenanceQue(odooPurchased === 'Yes');
    }, [odooPurchased]);

    useEffect(() => {
        setShowCustomizeDetailQue(customizationsMade === 'Yes');
    }, [customizationsMade]);

    const handleEditClick = (section) => {
        if (section === 'customization') setShowCustomizationEditPop(true);
        if (section === 'implementation') setShowImplementationEditPop(true);
        if (section === 'integration') setShowIntegrationEditPop(true);
        if (section === 'maintenance') setShowMaintenanceEditPop(true);
        if (section === 'budget') setShowBudgetEditPop(true);
        if (section === 'doc') setShowFileEditPop(true);
    };

    const handleFileDelete = async (fileId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/client/delete_file`, {
                file_id: fileId,
            });

            if (response.data.result.status === 'success') {
                toast.success("File deleted successfully.");
                fetchSupportDocument(user.userId);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            toast.error("Failed to delete the file.");
        }
    };

    const handleCustomizationEditClose = () => {
        setShowCustomizationEditPop(false);
        fetchSurveyQuestions(user.userId);
    };

    const handleImplementationEditClose = () => {
        setShowImplementationEditPop(false);
        fetchSurveyQuestions(user.userId);
    };

    const handleIntegrationEditClose = () => {
        setShowIntegrationEditPop(false);
        fetchSurveyQuestions(user.userId);
    };

    const handleMaintenanceEditClose = () => {
        setShowMaintenanceEditPop(false);
        fetchSurveyQuestions(user.userId);
    };

    const handleBudgetEditClose = () => {
        setShowBudgetEditPop(false);
        fetchSurveyQuestions(user.userId);
    };

    const handleFileEditClose = () => {
        setShowFileEditPop(false);
        fetchSurveyQuestions(user.userId);
        fetchSupportDocument(user.userId);
    };

    const handleSubmitClick = () => {
        navigate('/client/profile_completed');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-preview-container">
            <header className="client-preview-header">
                <img src={logo} alt="Logo" className="client-preview-logo" onClick={handleLogoClick} />
                <ClientNavbar />
            </header>
            <div className='main-heading'>Preview Project Details</div>
            {/* <section className="notice">
                <h5>Your profile is now complete and is currently under review. We will get back to you once the review process is finished. In the meantime, we appreciate your patience. If you have any questions or need assistance, please feel free to contact our support team at <a href='mailto:info@o2b.co.in'>info@o2b.co.in</a>. We are committed to providing you with the best experience possible.</h5>
            </section> */}
            <section className="client-preview-banner">
                <div className="banner-content">
                    <h2 style={{ color: '#62495B', fontWeight: '700' }}>Looking good, {user ? user.firstName : 'Guest'}!</h2>
                    <p style={{ fontWeight: '500' }}>Make any edits you want, then submit your profile. You can make more changes after it's live.</p>
                    {/* <button className="submit-button" onClick={handleSubmitClick}>Submit Project</button> */}
                </div>
                <div className="banner-image">
                    <img src={sideImg} alt="Banner" style={{ height: '150px', width: '255px' }} />
                </div>
            </section>
            <main className="client-preview-main">
                <section className="client-details">
                    <div className="customization-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Odoo Usage and Customization</h3>
                            <button className="edit-button" onClick={() => handleEditClick('customization')}><MdEdit /></button>
                        </div>
                        <div>
                            <p className='heading' >Have you already purchased Odoo?</p>
                            <p className='content'>{odooPurchased}</p>
                        </div>
                        {showMaintenanceQue && <div>
                            <p className='heading'>Since when you are using Odoo?</p>
                            <p className='content'>{odooUsageDuration}</p>
                        </div>}
                        {showMaintenanceQue && <div>
                            <p className='heading'>Have you made any customizations to Odoo?</p>
                            <p className='content'>{customizationsMade}</p>
                        </div>}
                        {showMaintenanceQue && showCustomizeDetailQue && <div>
                            <p className='heading'>Customization details</p>
                            <p className='content'>{customizationDetails}</p>
                        </div>}
                    </div>
                    <div className="systems-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Current Systems and Odoo Implementation Goals </h3>
                            <button className="edit-button" onClick={() => handleEditClick('implementation')}><MdEdit /></button>
                        </div>
                        <div>
                            <p className='heading'>Current System</p>
                            <p className='content'>{currentSystems}</p>
                        </div>
                        <div>
                            <p className='heading'>Implementation Goals</p>
                            <p className='content'>{implementation}</p>
                        </div>
                    </div>
                    <div className="integration-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Odoo Integration</h3>
                            <button className="edit-button" onClick={() => handleEditClick('integration')}><MdEdit /></button>
                        </div>
                        <div>
                            <p className='content'>{integrationDetails}</p>
                        </div>
                    </div>
                    <div className="maintenance-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>System Maintenance and Updates</h3>
                            <button className="edit-button" onClick={() => handleEditClick('maintenance')}><MdEdit /></button>
                        </div>
                        <div>
                            <p className='heading'>Implementation support </p>
                            <p className='content'>{implementingSupport}</p>
                        </div>
                        <div>
                            <p className='heading'>maintenance and updates plan</p>
                            <p className='content'>{managingPlan}</p>
                        </div>
                    </div>
                    {/* <div className="budget-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Project Timeline and Budget</h3>
                            <button className="edit-button" onClick={() => handleEditClick('budget')}><MdEdit /></button>
                        </div>
                        <div>
                            <p className='heading'>Preferred Project Timeline</p>
                            <p className='content'>{timeline}</p>
                        </div>
                        <div>
                            <p className='heading'>Your Budget</p>
                            <p className='content'>$ {budget}</p>
                        </div>
                    </div> */}
                </section>
                <section className='supporting-doc'>
                    <div className="doc-section">
                        <div className='edit-btn-div'>
                            <h3 className='box-heading'>Supporting Documents</h3>
                            <button className="edit-button" onClick={() => handleEditClick('doc')}><MdEdit /></button>
                        </div>

                        {filenames && filenames.map((file) => (
                            <div key={file.id} className="selected-files">
                                <span className='doc_filename'><FaFileAlt /> &nbsp;{file.name}</span>
                                <button className='remove-button' onClick={() => handleFileDelete(file.id)}>x</button>
                            </div>
                        ))}

                    </div>
                </section>
            </main>
            <footer className="client-preview-footer">
                <button className="submit-button foot" onClick={handleSubmitClick}>Submit Project Detail</button>
            </footer>
            {showCustomizationEditPop && (
                <CustomizationPopup
                    onClose={handleCustomizationEditClose}
                />
            )}
            {showImplementationEditPop && (
                <ImplementationPopup
                    onClose={handleImplementationEditClose}
                />
            )}
            {showIntegrationEditPop && (
                <IntegrationPopup
                    onClose={handleIntegrationEditClose}
                />
            )}
            {showMaintenanceEditPop && (
                <MaintenancePopup
                    onClose={handleMaintenanceEditClose}
                />
            )}
            {showBudgetEditPop && (
                <BudgetPopup
                    onClose={handleBudgetEditClose}
                />
            )}
            {showFileEditPop && (
                <FileEditPopup
                    onClose={handleFileEditClose}
                />
            )}
            <ToastContainer />
        </div>
    );
};

export default ClientPreview;
