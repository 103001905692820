import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserRegisterForm.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { FcGoogle } from "react-icons/fc";
import { FaApple, FaEye, FaEyeSlash } from "react-icons/fa";
import { useGoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserRegisterForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = location.state?.role;

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: '',
    subscribe: false,
    role: role || '',
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryData = response.data.map(country => ({
          code: country.cca2,
          name: country.name.common,
        }));
        countryData.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryData);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'firstName' || name === 'lastName') {
      setFormData({
        ...formData,
        [name]: value.replace(/[^A-Za-z]/g, ''),
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.password || !formData.country) {
      toast.error('Please fill in all required fields.');
      return;
    }
    if (!passwordRegex.test(formData.password)) {
      toast.error('Password must be at least 8 characters long with one uppercase letter, one number, and one special character.');
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register/user`, formData);
      console.log("response from user registration", response);
      if (response.data.result.status === "success") {
        const user = {
          userId: response.data.result.user_id,
          userName: `${formData.firstName} ${formData.lastName}`,
          firstName: `${formData.firstName}`,
          email: formData.email,
          role: formData.role,
        };
        localStorage.setItem('user', JSON.stringify(user));
        toast.success('User registered successfully.\nKindly check your mail.');
        setTimeout(() => {
          navigate('/');
        }, 3000);
        // navigate('/freelancer/onboarding');
      } else {
        toast.error(response.data.result.message || 'Registration failed.');
      }
    } catch (error) {
      toast.error('An error occurred while registering the user.');
      console.log("error ======================= ", error);
    }
  };

  const handleLoginClick = () => {
    navigate('/freelancer/login', { state: { role } });
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };


  const handleGoogleLoginSuccess = async (tokenResponse) => {
    try {
      const userInfo = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
          Accept: 'application/json',
        },
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/google/login`, {
        user_info: userInfo.data,
        role: formData.role
      });
      console.log("response===============", response);

      if (response.data.result.status === 'success') {
        const user = {
          userId: response.data.result.user_id,
          userName: `${userInfo.data.given_name} ${userInfo.data.family_name}`,
          firstName: `${userInfo.data.given_name}`,
          email: userInfo.data.email,
          role: formData.role,
        };

        localStorage.setItem('user', JSON.stringify(user));
        console.log("user details========", user);
        toast.success('User registered successfully.\nKindly check your mail');
        if (formData.role === 'freelancer') {
          navigate('/freelancer/onboarding');
        } else {
          navigate('/client/survey');
        }
        // navigate('/freelancer/onboarding');
      } else {
        toast.error(response.data.result.message);
      }
    } catch (error) {
      toast.error('An error occurred while logging in with Google.');
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google login failed:', error);
    toast.error('Google login failed. Please try again.');
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onFailure: handleGoogleLoginFailure,
  });

  // const handleTermsOfServices = () => {
  //   navigate('/freelancer/terms_of_services');
  // };

  // const handleUserAgreement = () => {
  //   navigate('/freelancer/user_agreement');
  // };

  // const handlePrivacyPolicy = () => {
  //   navigate('/freelancer/privacy_policy');
  // };

  const handleTermsOfServices = (e) => {
    e.preventDefault();
    window.open('/freelancer/terms_of_services', '_blank', 'noopener,noreferrer');
  };

  const handleUserAgreement = (e) => {
    e.preventDefault();
    window.open('/freelancer/user_agreement', '_blank', 'noopener,noreferrer');
  };

  const handlePrivacyPolicy = (e) => {
    e.preventDefault();
    window.open('/freelancer/privacy_policy', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="user-register-form-container">
      <div className="logo" onClick={handleLogoClick}><img className='buddy_logo_style' src={logo} alt="logo" style={{ height: '65px' }} /></div>
      <h2>Register to Find Work You Love</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '18px' }}>
          <div className="input-group">
            <label>First Name<sup className='madatory'>*</sup></label>
            <input
              type="text"
              name="firstName"
              placeholder='First Name '
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Last Name<sup className='madatory'>*</sup></label>
            <input
              type="text"
              name="lastName"
              placeholder='Last Name '
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="input-group">
          <label>Email Address<sup className='madatory'>*</sup></label>
          <input
            type="email"
            name="email"
            placeholder="example@gmail.com"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group password-group">
          <label>Password<sup className='madatory'>*</sup></label>
          <input
            type={passwordVisible ? "text" : "password"}
            name="password"
            placeholder="password (8 or more characters)"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <div className="password-toggle" onClick={togglePasswordVisibility}>
            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
          </div>
        </div>
        <div className="input-group password-group">
          <label>Confirm  Password<sup className='madatory'>*</sup></label>
          <input
            type={confirmPasswordVisible ? "text" : "password"}
            name="confirmPassword"
            placeholder="confirm password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
          <div className="password-toggle" onClick={toggleConfirmPasswordVisibility}>
            {confirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
          </div>
        </div>
        <div className="input-group">
          <label>Country<sup className='madatory'>*</sup></label>
          <select name="country" value={formData.country} onChange={handleChange} required>
            <option value="">Select</option>
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="checkbox-group">
          <input
            type="checkbox"
            name="subscribe"
            checked={formData.subscribe}
            onChange={handleChange}
          />
          <label>Send me helpful emails to find rewarding work and job leads.</label>
        </div> */}
        <div className="checkbox-group">
          <input type="checkbox" required style={{ marginLeft: '3px' }} />
          <label style={{ marginRight: '14%', marginTop: '2%', textAlign: 'justify' }}>
            Yes, I understand and agree to the <a href="" onClick={handleTermsOfServices}>Terms of Services</a>, including the <a href="" onClick={handleUserAgreement}>Freelancer Agreement</a> and <a href="" onClick={handlePrivacyPolicy}>Privacy Policy</a>.
          </label>
        </div>
        <button type="submit" className="create-account-btn">Create Account</button>
        {/* {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>} */}
        <div className="login-link">
          Already Have An Account? <a href="" onClick={handleLoginClick}>Login</a>
        </div>
      </form>
      <div className="divider">
        <span>Or</span>
      </div>
      <div className="social-login">
        <button className="social-btn google-btn" onClick={() => login()}><FcGoogle /> Continue with Google</button>
        {/* <button className="social-btn apple-btn"><FaApple /> Continue with Apple</button> */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserRegisterForm;
