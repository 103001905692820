import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Description.css";
import axios from 'axios';
import ClientHeader from "../../ClientHeader/ClientHeader";
import { MdAttachFile } from "react-icons/md";

const Description = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        const storedJob = JSON.parse(localStorage.getItem('job'));
        setJobId(storedJob.jobId);
    }, []);

    useEffect(() => {
        if (jobId) {
            fetchJobDetails(jobId);
        }
    }, [jobId]);

    const fetchJobDetails = async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/job/details`, { job_id: id });
            console.log(response);

            if (response.data.result.status === 'success') {
                setDescription(response.data.result.job.description);
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching job details:', error);
        }
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.size <= 100 * 1024 * 1024) { // 100 MB limit
            setFile(selectedFile);
        } else {
            alert("Max file size is 100 MB");
        }
    };

    const handleSubmit = async () => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/job/update`, {
            job_id: jobId,
            description: description,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(response);


        if (response.data.result.status === 'success') {
            navigate('/client/project/preview');
        } else {
            alert('Error updating job details: ' + response.data.result.message);
        }
    };

    const handleBack = () => {
        navigate('/client/project/budget');
    };

    return (
        <div className="description-container">
            <ClientHeader />
            <div className="description-content">
                <div className="progress">5/5</div>
                <h2>Start the conversation.</h2>
                <p>Talent are looking for:</p>
                <ul>
                    <li>Clear expectations about your task or deliverables</li>
                    <li>The skills required for your work</li>
                    <li>Good communication</li>
                    <li>Details about how you or your team like to work</li>
                </ul>

                <div className="description-input">
                    <label htmlFor="description">Describe what you need</label>
                    <textarea
                        id="description"
                        placeholder="Describe"
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </div>

                <div className="file-attachment">
                    <label htmlFor="file-input" className="attach-file-button">
                        <MdAttachFile />&nbsp; Attach File
                    </label>
                    <input
                        type="file"
                        id="file-input"
                        accept=".pdf,.doc,.docx,.txt,.png,.jpg,.jpeg"
                        onChange={handleFileChange}
                    />
                    <p>Max file size 100 MB</p>
                </div>
            </div>

            <div className="navigation-buttons">
                <button className="back-button" onClick={handleBack}>Back</button>
                <button className="next-button" onClick={handleSubmit}>Review</button>
            </div>
        </div>
    );
};

export default Description;
