import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Categories.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';

const Categories = () => {
    const navigate = useNavigate();
    const [hourlyRate, setHourlyRate] = useState(0);
    const serviceFeePercentage = 10;
    const serviceFee = (hourlyRate * serviceFeePercentage) / 100;
    const amountAfterFee = hourlyRate - serviceFee;
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_emp_rate';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetch request options==========", requestOptions);

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setHourlyRate(data.result.hourly_rate);
                        console.log('rate from backend==========', data.result.hourly_rate);
                    } else {
                        console.error('Error fetching rate=======', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching rate======', error);
                });
        }
    }, []);

    const handleRateChange = (e) => {
        const { value } = e.target;
        setHourlyRate(parseFloat(value) || 0);
        // localStorage.setItem('hourlyRate', value);
    };

    const handleNextClick = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/hourly_rate`, {
                hourly_rate: hourlyRate,
                service_fee: serviceFee,
                amount_after_fee: amountAfterFee,
                user_id: user.userId,
            });
            console.log("response in hourly rate================", response);
            if (response.data.result.status === 'success') {
                navigate('/freelancer/profile/setup');
            } else {
                console.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error saving hourly rate:', error);
        }
    };

    const handleBackClick = () => {
        navigate('/freelancer/profile/bio');
    };

    return (
        <div className="categories-container">
            <header className="header">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <UserNav />
            </header>
            <div className="content">
                <div style={{ display: 'flex' }}>8/10</div><br />
                <h1>Now, let’s set your hourly rate.</h1>
                <p>Clients will see this rate on your profile and in search results once you publish your profile. You can adjust your rate every time you submit a proposal.</p>
                <div className="rate-container">
                    <div className="rate-item">
                        <label>Hourly rate</label>
                        <input
                            type="number"
                            value={hourlyRate}
                            onChange={handleRateChange}
                            min="0"
                            step="0.5"
                        />
                        <span>/hr</span>
                    </div>
                    <div className="rate-item">
                        <label>Service fee</label>
                        <input
                            type="text"
                            value={`-$${serviceFee.toFixed(2)}`}
                            readOnly
                        />
                        <span>/hr</span>
                    </div>
                    <div className="rate-item">
                        <label>You'll get</label>
                        <input
                            type="text"
                            value={`$${amountAfterFee.toFixed(2)}`}
                            readOnly
                        />
                        <span>/hr</span>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackClick}>Back</button>
                <button className="next-btn" onClick={handleNextClick}>Next, choose your categories</button>
            </footer>
        </div>
    );
};

export default Categories;
