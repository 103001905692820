import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import './BioEditPop.css';

const BioEditPop = ({ user, bio: initialBio, onClose, onBioChange }) => {
    const [bio, setBio] = useState(initialBio);
    const [error, setError] = useState('');

    useEffect(() => {
        if (user) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_emp_bio';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: user.userId })
            };

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setBio(data.result.bio);
                    } else {
                        console.error('Error fetching bio', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching bio', error);
                });
        }
    }, [user]);

    const handleBioChange = (e) => {
        setBio(e.target.value);
    };

    const handleSaveButton = async () => {
        if (!user || !user.userId) {
            setError('User not logged in');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update_bio`, {
                bio: bio,
                user_id: user.userId
            });

            if (response.data.result.status === 'success') {
                if (initialBio !== bio) {
                    onBioChange(bio);
                }
                onClose();
            } else {
                setError(response.data.result.message);
            }
        } catch (error) {
            console.error('Error updating bio:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className='bio-edit-pop-main'>
            <div className="bio-edit-popup">
                <IoMdClose className="bio-edit-pop-close" onClick={onClose} />
                <section className="bio-main-contents">
                    <section className="bio-section">
                        <h2>Update Your Bio</h2>
                        <textarea
                            placeholder="Enter Your Top Skills, Experiences, and interests. This is one of the things clients will see on your profile."
                            value={bio}
                            onChange={handleBioChange}
                        />
                        <span className="char-counter">{bio.length >= 100 ? '' : `At least ${100 - bio.length} characters`}</span>
                    </section>
                    <div>
                        <button className="save-btn" onClick={handleSaveButton}>Save</button>
                    </div>
                </section>
                {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
        </div>
    );
}

export default BioEditPop;
