import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ClientHeader.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { FaRegBell, FaChevronDown, FaChevronUp, FaBriefcase, FaUsers } from "react-icons/fa";
import { AiOutlineCaretDown } from "react-icons/ai";
import ClientNavbar from '../ClientNavbar/ClientNavbar';

const ClientHeader = () => {
    const navigate = useNavigate();

    const [jobsDropdownOpen, setJobsDropdownOpen] = useState(false);
    const [odoofreelancerDropdownOpen, setOdoofreelancerDropdownOpen] = useState(false);
    const [reportDropdownOpen, setReportDropdownOpen] = useState(false);
    const [jobDropdownOpen, setJobDropdownOpen] = useState(false);

    const handleJobsDropdown = () => {
        setJobsDropdownOpen(!jobsDropdownOpen)
        setOdoofreelancerDropdownOpen(false)
        setReportDropdownOpen(false)
        setJobDropdownOpen(false)
    }

    const handleOdoofreelancerDropdown = () => {
        setOdoofreelancerDropdownOpen(!odoofreelancerDropdownOpen)
        setJobsDropdownOpen(false)
        setReportDropdownOpen(false)
        setJobDropdownOpen(false)
    }

    const handleReportDropdown = () => {
        setReportDropdownOpen(!reportDropdownOpen)
        setJobsDropdownOpen(false)
        setOdoofreelancerDropdownOpen(false)
        setJobDropdownOpen(false)
    }

    const handleJobDropdown = () => {
        setJobDropdownOpen(!jobDropdownOpen)
        setJobsDropdownOpen(false)
        setOdoofreelancerDropdownOpen(false)
        setReportDropdownOpen(false)
    }

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <header className="client-header">
            <div className="logo" onClick={handleLogoClick}><img src={logo} alt="Logo" /></div>
            <nav>
                <ul>
                    <li onClick={handleJobsDropdown}>
                        Jobs&nbsp;{jobsDropdownOpen ? (<FaChevronUp />) : (<FaChevronDown />)}
                        {jobsDropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item" >Post a Job</div>
                                <div className="dropdown-item" >Your Dashboard</div>
                                <div className="dropdown-item">All Job Posts</div>
                                <div className="dropdown-item" >All Contracts</div>
                                <div className="dropdown-item">All Hires</div>
                            </div>
                        )}
                    </li>
                    <li onClick={handleOdoofreelancerDropdown}>
                        Odoo Freelancer&nbsp;{odoofreelancerDropdownOpen ? (<FaChevronUp />) : (<FaChevronDown />)}
                        {odoofreelancerDropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item">Your Active Jobs</div>
                                <div className="dropdown-item">Hourly work diary</div>
                                <div className="dropdown-item">Client History</div>
                            </div>
                        )}
                    </li>
                    <li onClick={handleReportDropdown}>
                        Reports&nbsp;{reportDropdownOpen ? (<FaChevronUp />) : (<FaChevronDown />)}
                        {reportDropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item">Financial Overview</div>
                                <div className="dropdown-item">Your Reports</div>
                                <div className="dropdown-item">Billing and Earnings</div>
                                <div className="dropdown-item">Transactions and Invoices</div>
                                <div className="dropdown-item">Certificate of Earnings</div>
                            </div>
                        )}
                    </li>
                    <li>Messages</li>
                </ul>
            </nav>
            <div className="search-container">
                <div className="input-dropdown-wrapper">
                    <input
                        type="text"
                        placeholder="Search here"
                        className="search-input"
                    />
                    <div className="search-dropdown-trigger" onClick={handleJobDropdown}>
                        Talent <AiOutlineCaretDown />
                    </div>
                    {jobDropdownOpen && (
                        <div className="search-dropdown-menu">
                            <div className="search-dropdown-item">
                                <FaUsers className="dropdown-icon" />
                                <span>Talent</span>
                            </div>
                            <div className="search-dropdown-item">
                                <FaBriefcase className="dropdown-icon" />
                                <span>Odoo Freelancer</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <FaRegBell />
            <ClientNavbar />
        </header>
    );
};

export default ClientHeader;
