import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './FreelanceSurvey2.css';
import logo from '../../assets/imgs/Odoo-Feelancer-LOGO.png';
import MainIncome from '../../assets/imgs/main_money.jpg';
import SideIncome from '../../assets/imgs/side_income.webp';
import WorkExp from '../../assets/imgs/Work_Experience.png';
import NoGoal from '../../assets/imgs/no_goal.jpg';
import UserNav from '../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FreelanceSurvey2 = () => {
    const [goal, setGoal] = useState('');
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // useEffect(() => {
    //     const storedGoal = localStorage.getItem('goal');
    //     if (storedGoal) {
    //         setGoal(storedGoal);
    //     }
    //     const storedUser = JSON.parse(localStorage.getItem('user'));
    //     if (storedUser) {
    //         setUser(storedUser);
    //     }
    // }, []);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_survey_goal';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetch request options==========", requestOptions);

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.status === 'success') {
                        setGoal(data.result.goal);
                        console.log('goal from backend==========', data.result.goal);
                    } else {
                        console.error('Error fetching goal=======', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching goal======', error);
                });
        }
    }, []);

    // useEffect(() => {
    //     localStorage.setItem('goal', goal);
    // }, [goal]);

    const handleChange = (e) => {
        setGoal(e.target.value);
    };

    const handleBackButton = () => {
        navigate('/freelancer/survey/1');
    };

    const handleNextButton = async () => {
        if (!user || !user.userId) {
            toast.error('User not logged in');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/goal`, {
                user_id: user.userId,
                goal: goal
            });
            console.log("Response from goal survey============", response);

            if (response.data.result.status === 'success') {
                navigate('/freelancer/survey/3');
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    const handleSkipButton = () => {
        navigate('/freelancer/survey/3');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <>
            <div className="survey2-container">
                <header className="header">
                    <div className="logo" onClick={handleLogoClick}><img style={{ height: '65px' }} src={logo} alt="" /></div>
                    <UserNav />
                </header>
                <main className="main-content">
                    <section className="question-section">
                        <div className="progress">2/3</div>
                        <h1>Got it. What's your biggest goal for freelancing?</h1>
                        <p className="subtext">Different people choose to Upwork for various reasons. We want to highlight the opportunities that fit your goals best while still showing you all the possibilities.</p>
                        <div className="goal-options">
                            <label className="goal-option">
                                <img src={MainIncome} alt="Income" />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>To earn my main income</p>
                                    <input
                                        type="radio"
                                        name="goal"
                                        value="To earn my main income"
                                        checked={goal === 'To earn my main income'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </label>
                            <label className="goal-option">
                                <img src={SideIncome} alt="Side Money" />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>To make money on the side</p>
                                    <input
                                        type="radio"
                                        name="goal"
                                        value="To make money on the side"
                                        checked={goal === 'To make money on the side'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </label>
                            <label className="goal-option">
                                <img src={WorkExp} alt="Experience" />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>To get experience, for a full-time job</p>
                                    <input
                                        type="radio"
                                        name="goal"
                                        value="To get experience, for a full-time job"
                                        checked={goal === 'To get experience, for a full-time job'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </label>
                            <label className="goal-option">
                                <img src={NoGoal} alt="No Goal" />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <p>I don't have a goal in mind yet</p>
                                    <input
                                        type="radio"
                                        name="goal"
                                        value="I don't have a goal in mind yet"
                                        checked={goal === 'I don\'t have a goal in mind yet'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </label>
                        </div>
                    </section>
                </main>
                <div className="navigation-buttons">
                    <button className="back-button" onClick={handleBackButton}>Back</button>
                    <div className="skip-next-buttons">
                        <button className="skip-button" onClick={handleSkipButton}>Skip for now</button>
                        <button className="next-button" onClick={handleNextButton}>Next</button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
};

export default FreelanceSurvey2;
