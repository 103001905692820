import React from 'react';
import './CloseAccountConfirmation.css';

const CloseAccountConfirmation = ({ onClose, onSave }) => {
    return (
        <div className="close-account-popup-overlay">
            <div className="close-account-popup-content">
                <h2>Are you sure you want to close your account .</h2>
                {/* <p>Your changes will be lost if you leave without saving.</p> */}
                <div className="close-account-popup-buttons">
                    <button className="popup-button-no" onClick={onClose}>Cancel</button>
                    <button className="popup-button-yes" onClick={onSave}>Yes, Close Account</button>
                </div>
            </div>
        </div>
    );
};

export default CloseAccountConfirmation;

