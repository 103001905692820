import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientSurvey3.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import UserNav from '../../Auth/UserNav/UserNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientSurvey3 = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [companyDetails, setCompanyDetails] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [employeeCount, setEmployeeCount] = useState('');
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage===========', storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const fetchSurveyQuestions = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });
            console.log("rsdv", response);

            if (response.data.result.status === 'success') {
                setSurveyUserInputId(response.data.result.survey_user_input_id);
                setQuestions(response.data.result.questions);

                setCompanyDetails(response.data.result.questions[8].answer);
                setCompanyWebsite(response.data.result.questions[9].answer);
                setEmployeeCount(response.data.result.questions[10].answer);
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error fetching survey questions:', error);
            toast.error('Error fetching survey questions');
        }
    };

    const handleNextButton = async () => {
        if (!companyDetails || !companyWebsite || !employeeCount) {
            toast.error('All questions are mandatory.');
            return;
        }
        try {
            const answers = [
                { question_id: questions[8].id, answer: companyDetails },
                { question_id: questions[9].id, answer: companyWebsite },
                { question_id: questions[10].id, answer: employeeCount },
            ];

            for (let answer of answers) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/submit`, {
                    user_id: user.userId,
                    survey_user_input_id: surveyUserInputId,
                    question_id: answer.question_id,
                    answer: answer.answer,
                });
            }
            navigate('/client/survey_4');
        } catch (error) {
            console.error('Error submitting survey response:', error);
            toast.error('Error submitting survey response');
        }
    };

    const handleBackButton = () => {
        navigate('/client/survey_2');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-survey3-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <UserNav />
            </header>
            <section className="survey3-contents">
                <section className="survey3-section">
                    <div style={{ display: 'flex' }}>3/7</div><br />
                    <h2>Tell us about your company</h2>
                    <p>How we can assist you, please provide a brief overview of your company and your main opertations.</p>

                    <form>
                        <div className="question">
                            <label>Could you give a summary of your company and its operations?<sup className='madatory'>*</sup></label>
                            <textarea
                                placeholder="Describe"
                                value={companyDetails}
                                onChange={(e) => setCompanyDetails(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>Could you please provide your company's website?<sup className='madatory'>*</sup></label>
                            <input
                                type="text"
                                value={companyWebsite}
                                onChange={(e) => setCompanyWebsite(e.target.value)}
                            />
                        </div>
                        <div className="input-group">
                            <label>What is your company's employee count?<sup className='madatory'>*</sup></label>
                            <input
                                type="number"
                                value={employeeCount}
                                onChange={(e) => setEmployeeCount(e.target.value)}
                            />
                        </div>
                    </form>
                </section>
                <section className="testimonial-section">
                    <div className="testimonial">
                        <div className="testimonial-header">
                            <img src="https://img.freepik.com/premium-vector/vector-small-business-city-building-illustration-with-flat-design-style_738021-21.jpg" alt="Digital Solutions Co." className="profile-image" />
                            <div className='testimonial-detail'>
                                <div className="testimonial-name">Digital Solutions Co.</div>
                                {/* <span>Client</span> */}
                            </div>
                        </div>
                        <p className="testimonial-text">
                            "Odoo Freelancer helped us connect with a fantastic developer who transformed our app idea into reality. The seamless communication made collaboration effortless. We couldn't be happier with the results!"
                        </p>
                    </div>
                </section>
            </section>
            <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default ClientSurvey3;
