import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './LoginForm.css';
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { useGoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom';

const ClientLoginForm = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const role = location.state?.role;
    const [searchParams] = useSearchParams();
    const verification_token = searchParams.get('temp');

    console.log("verification token =========", verification_token);


    const handleChange = (e) => {
        setEmail(e.target.value);
    };
    // 122.160.26.224:9028
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login_step1`, { email, verification_token });
            console.log("response===============", response);
            if (response.data.result.status === 'success') {
                if (response.data.result.user_tag === 'Client') {
                    navigate('/client/login/ps', { state: { email, role } });
                } else {
                    toast.error('You are not registered as a Client')
                    // toast("Sent mail", {
                    //     className: 'grey-background',
                    //     bodyClassName: "grow-font-size",
                    //     progressClassName: 'fancy-progress-bar'
                    // });
                    setTimeout(() => {
                        navigate('/');
                    }, 4000);
                }
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    };

    const handleSignupClick = () => {
        navigate('/client/register', { state: { role } });
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleGoogleLoginSuccess = async (tokenResponse) => {
        try {
            const userInfo = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
                headers: {
                    Authorization: `Bearer ${tokenResponse.access_token}`,
                    Accept: 'application/json',
                },
            });

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/google/login`, {
                user_info: userInfo.data,
                role: "client"
            });
            console.log("response===============", response);

            if (response.data.result.status === 'success') {
                const user = {
                    userId: response.data.result.user_id,
                    userName: `${userInfo.data.given_name} ${userInfo.data.family_name}`,
                    firstName: `${userInfo.data.given_name}`,
                    email: userInfo.data.email,
                    role: "client",
                };

                localStorage.setItem('user', JSON.stringify(user));
                toast.success('User logged in successfully!');
                if (role === 'freelancer') {
                    navigate('/freelancer/onboarding');
                } else {
                    navigate('/client/survey_1');
                }
                // navigate('/freelancer/onboarding');
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            setError('An error occurred while logging in with Google.');
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google login failed:', error);
        setError('Google login failed. Please try again.');
    };

    const login = useGoogleLogin({
        onSuccess: handleGoogleLoginSuccess,
        onFailure: handleGoogleLoginFailure,
    });

    const handlePrivacyPolicy = () => {
        navigate('/client/privacy_policy');
    };

    return (
        <div className="login-form-container">
            <div className="logo" onClick={handleLogoClick}><img style={{ height: '100px' }} src={logo} alt="Logo" /></div>
            <div className='content'>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <p>Glad to have you back</p>
                    <div className="input-group">
                        <label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email Address or User Name"
                                value={email}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    <button type="submit" className="continue-btn">Continue</button>
                </form>
                <div className="divider">
                    <span>Or</span>
                </div>
                <div className="social-login">
                    <button className="social-btn google-btn" onClick={() => login()}><FcGoogle />&nbsp;&nbsp; Continue with Google</button>
                    {/* <button className="social-btn apple-btn"><FaApple />&nbsp;&nbsp; Continue with Apple</button> */}
                </div>
                <div className="signup-link">
                    Don't have an account? <a href="" onClick={handleSignupClick}>Sign up</a>
                </div>
            </div>
            {/* <div className="footer">
                © 2024 powered by O2B. &nbsp;<a href="" onClick={handlePrivacyPolicy}>Privacy Policy</a>
            </div> */}
            <ToastContainer />
        </div>
    );
};

export default ClientLoginForm;
