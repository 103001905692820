import React, { useState, useEffect } from 'react';
import './EducEditPop.css';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import EducPopUp from '../Templates/EducPopUp';

const EducEditPop = ({ onClose ,onEducationChange}) => {
    const [user, setUser] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [educationList, setEducationList] = useState([]);
    const [currentEducation, setCurrentEducation] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('Loaded user from localStorage:', storedUser);

        if (storedUser) {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const endpoint = '/fetch_education';
            const url = `${apiUrl}${endpoint}`;

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: storedUser.userId })
            };
            console.log("fetch===============", requestOptions);
            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.result.success) {
                        setEducationList(data.result.educations);
                        console.log('Loaded educations from backend:', data.result.educations);
                    } else {
                        console.error('Error fetching educations:', data.result.message);
                    }
                })
                .catch(error => {
                    console.error('Error fetching educations:', error);
                });
        }
    }, []);

    const handleAddEducation = () => {
        setCurrentEducation(null);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleSaveEducation = async (newEducation) => {
        let apiUrl;
        let endpoint;
        if (currentEducation !== null) {
            apiUrl = `${process.env.REACT_APP_API_BASE_URL}/update_education`;
            endpoint = '/update_education';
        } else {
            apiUrl = `${process.env.REACT_APP_API_BASE_URL}/create_education`;
            endpoint = '/create_education';
        }

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: user.userId,
                education_id: currentEducation !== null ? educationList[currentEducation].id : null,
                school: newEducation.school,
                degree: newEducation.degree,
                field: newEducation.field,
                start_date: newEducation.startDate,
                end_date: newEducation.endDate,
                description: newEducation.description,
            }),
        });

        const result = await response.json();

        if (result.result.status === 'success') {
            console.log('Education saved successfully:', result.result.education_id);
            let updatedList;
            if (currentEducation !== null) {
                updatedList = educationList.map((edu, index) =>
                    index === currentEducation ? newEducation : edu
                );
                onEducationChange(updatedList);
            } else {
                updatedList = [...educationList, newEducation];
                onEducationChange(updatedList);
            }
            setEducationList(updatedList);
            localStorage.setItem('educationList', JSON.stringify(updatedList));
            console.log('Saved educations to localStorage:', updatedList);
            setIsPopupOpen(false);
        } else {
            console.error('Error saving education:', result.result.message);
        }
    };

    const handleEditEducation = (index) => {
        setCurrentEducation(index);
        setIsPopupOpen(true);
    };

    const handleDeleteEducation = async (index) => {
        const educationToDelete = educationList[index];
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_education`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                education_id: educationToDelete.id,
            }),
        });

        const result = await response.json();

        if (result.result.status === 'success') {
            console.log('Education deleted successfully');
            const updatedEducationList = educationList.filter((_, i) => i !== index);
            setEducationList(updatedEducationList);
            localStorage.setItem('educationList', JSON.stringify(updatedEducationList));
            onEducationChange(updatedEducationList);
            console.log('Updated education list after deletion:', updatedEducationList);
        } else {
            console.error('Error deleting education:', result.result.message);
        }
    };

    return (
        <>
            <div className="popup-backdrop" onClick={onClose}></div>
            <main className="education-main-content">
                <div className="experience-container">
                    {educationList.length > 0 ? (
                        educationList.map((education, index) => (
                            <div className="experience-card" key={index}>
                                <div className="experience-card-header">
                                    <h3>{education.school}</h3>
                                    <div className="experience-card-icons">
                                        <span className="edit-icon" onClick={() => handleEditEducation(index)}><FaEdit /></span>
                                        <span className="delete-icon" onClick={() => handleDeleteEducation(index)}><MdDeleteForever /></span>
                                    </div>
                                </div>
                                <p>{education.degree} | {education.field} | {education.startDate} - {education.endDate}</p>
                                <p>{education.location}</p>
                                <p className="experience-description">{education.description}</p>
                            </div>
                        ))
                    ) : (
                        <p>No education records found. Please add your education details.</p>
                    )}
                    <button className="add-experience-btn" onClick={handleAddEducation}>+</button>
                </div>
                <button className='save-btn' onClick={onClose}>Save</button>
            </main>
            {isPopupOpen && (
                <EducPopUp
                    onClose={handleClosePopup}
                    onSave={handleSaveEducation}
                    education={currentEducation !== null ? educationList[currentEducation] : null}
                />
            )}
        </>
    );
}

export default EducEditPop;
