import React from 'react';
import './ConfirmPopUp.css';

const ConfirmPopUp = ({ onClose, onSave }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Are you sure you want to close this window?</h2>
        <p>Your changes will be lost if you leave without saving.</p>
        <div className="popup-buttons">
          <button className="popup-button-cancel" onClick={onClose}>Cancel, Without Saving</button>
          <button className="popup-button-save" onClick={onSave}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopUp;
