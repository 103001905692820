import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './LoginForm1.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientLoginForm1 = () => {
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    // const role = location.state?.role;
    const [lastAnsweredIndex, setLastAnsweredIndex] = useState(0);
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
    }, []);

    const handleChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login_step2`, { email, password });
            console.log("response login ps===============", response);
            if (response.data.result.status === 'success') {
                const userInfo = {
                    userName: response.data.result.user_name,
                    firstName: response.data.result.first_name,
                    userId: response.data.result.user_id,
                    role: response.data.result.emp_type
                };
                localStorage.setItem('user', JSON.stringify(userInfo));
                if (userInfo.role === 'freelancer') {
                    navigate('/freelancer/onboarding');
                } else {
                    // navigate('/client/survey_1');
                    fetchLastAnsweredQuestionIndex(userInfo.userId);
                }
            } else {
                toast.error(response.data.result.message);
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred. Please try again.');
        }
    };

    const fetchLastAnsweredQuestionIndex = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });
            if (response.data.result.status === 'success') {
                const lastAnsweredIndex = response.data.result.last_answered_question_index;
                console.log("last index", lastAnsweredIndex);

                if (lastAnsweredIndex >= 3 && lastAnsweredIndex < 7) {
                    navigate('/client/survey_2');
                } else if (lastAnsweredIndex >= 7 && lastAnsweredIndex < 10) {
                    navigate('/client/survey_3');
                } else if (lastAnsweredIndex >= 10 && lastAnsweredIndex < 12) {
                    navigate('/client/survey_4');
                } else if (lastAnsweredIndex >= 12 && lastAnsweredIndex < 13) {
                    navigate('/client/survey_5');
                } else if (lastAnsweredIndex >= 13 && lastAnsweredIndex < 15) {
                    navigate('/client/survey_6');
                } else if (lastAnsweredIndex >= 15) {
                    // navigate('/client/survey_8');
                    // } else if (lastAnsweredIndex >= 17) {
                    // navigate('/client/profile_completed');
                    navigate('/client/dashboard');
                }
                else {
                    navigate('/client/survey_1');
                }
            } else {
                console.error('Error fetching survey data:', response.data.message);
            }
        } catch (error) {
            console.error('Error during survey data fetching:', error);
        }
    };


    const handleLogoClick = () => {
        navigate('/');
    };

    const handleForgetPassword = () => {
        navigate('/forget_password');
    };

    const handlePrivacyPolicy = () => {
        navigate('/client/privacy_policy');
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="password-form-container">
            <div className="logo" onClick={handleLogoClick}>
                <img style={{ height: '100px' }} src={logo} alt="Logo" />
            </div>
            <div className='content'>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <p>Glad to have you back</p>
                    <div className="input-group">
                        <input
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={handleChange}
                            required
                        />
                        <div className="password-toggle" onClick={togglePasswordVisibility}>
                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                        </div>
                    </div>
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    <div className='checkbox'>
                        <label>
                            <input type="checkbox" style={{ width: '15px' }} /> Remember Me
                        </label>
                        <a href="" onClick={handleForgetPassword} style={{ marginLeft: '29%', fontSize: '14px' }}>Forgot Password?</a>
                    </div>
                    <button type="submit" className="login-btn">Login</button>
                </form>
            </div>
            {/* <div className="footer">
                © 2024 powered by O2B. &nbsp;<a href="" onClick={handlePrivacyPolicy}>Privacy Policy</a>
            </div> */}
            <ToastContainer />
        </div>
    );
};

export default ClientLoginForm1;
