import React, { useState } from 'react';
import styles from './ForgetPassword.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import illustration from '../../../../assets/imgs/bro.png';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/forget_password`, { email });
      console.log("response in forget ====================", response);

      if (response.data.result.status === 'success') {
        setMessage('Password reset email sent successfully. Please check your inbox.');
        setError('');
        navigate('/email_sent');
      } else {
        setError(response.data.message || 'An error occurred. Please try again.');
        setMessage('');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      setMessage('');
    }

    setMessage('Password reset email sent successfully. Please check your inbox.');
    setError('');
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img className='buddy_logo_style' style={{ height: '80px' }} src={logo} alt="Logo" />
        </div>
      </header>
      <div className={styles.card}>
        <h2>Update Your Password</h2>
        <div className={styles.imageContainer}>
          <img src={illustration} alt="Password Update Illustration" />
        </div>
        <p>Enter your email address and select Send Email.</p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <label htmlFor="email" className={styles.formLabel}>Email</label>
          <input
            type="email"
            id="email"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.formInput}
          />
          <div className={styles.buttons}>
            <button type="button" className={styles.cancelButton} onClick={() => navigate('/')}>
              Cancel
            </button>
            <button type="submit" className={styles.submitButton}>
              Send Email
            </button>
          </div>
        </form>
      </div>
      <footer className={styles.footer}>
        <p>© 2024 Odoo. <a href="/privacy-policy" className={styles.footerLink}>Privacy Policy</a></p>
      </footer>
    </div>
  );
};

export default ForgetPassword;
