import React from 'react';
import './Membership.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

const Membership = () => {
    return (
        <div className="membership-container">
            <Header />
            <main className="membership-main">
                <h1>Membership plans</h1>
                <div className="links">
                    <a href="">Learn about starting the agency</a>
                    <a href="">Agency membership</a>
                </div>

                <div className="form-group">
                    <label htmlFor="companyName">Company Name</label>
                    <input type="text" id="companyName" placeholder="Enter Name" />
                </div>

                <div className="plans">
                    <div className="plan basic">
                        <div className="plan-header">
                            <h2>Basic</h2>
                            <button className="continue-button">Continue with Basics</button>
                        </div>
                        <p className="price">Free</p>
                        <p className="description">
                            This is what you get:
                        </p>
                        <ul className="benefits">
                            <li>✔️ Buy Connects as you need them for $0.15 + Tax each*</li>
                            <li>✔️ Hourly protection to ensure you’re paid for each hour worked</li>
                            <li>✔️ Fixed-price payments are secured through milestones</li>
                            <li>✔️ Limited reports and functionality</li>
                        </ul>
                        <p className="note">*Connects expire one year after purchase date</p>
                    </div>
                    <div className="plan plus">
                        <div className="plan-header">
                            <h2>Plus</h2>
                            <button className="continue-button">Go with Plus</button>
                        </div>
                        <p className="price">$20 per month*</p>
                        <p className="description">
                            Includes everything in Basic and also:
                        </p>
                        <ul className="benefits">
                            <li>✔️ 100 Connects total per month</li>
                            <li>✔️ Odoo's Chat Pro powered by Uma</li>
                            <li>✔️ View competitor bids for any job</li>
                            <li>✔️ Get exclusive access to the Odoo Freelancer’s Plus group</li>
                            <li>✔️ Skip the line for Odoo Freelancer’s Skills Certifications</li>
                            <li>✔️ Enjoy a 0% service fee when you bring new clients to Odoo Freelancer’s</li>
                            <li>✔️ Customize your profile</li>
                        </ul>
                        <p className="note">*Sales tax may apply</p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Membership;
