import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ClientProfile.css';
import logo from '../../../assets/imgs/Odoo-Feelancer-LOGO.png';
import { ToastContainer, toast } from 'react-toastify';
import { MdEdit } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';
import ClientNavbar from '../ClientNavbar/ClientNavbar';
import CompanyDetailPopup from '../ClientEditPopUp/CompanyDetailPopup';
import AboutCompanyPopup from '../ClientEditPopUp/AboutCompanyPopup';
import CloseAccountConfirmation from '../../Templates/CloseAccountConfirmation';

const ClientProfile = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [editInfo, setEditInfo] = useState(true);
    const [surveyUserInputId, setSurveyUserInputId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [employeeCount, setEmployeeCount] = useState('');
    const [companyDetails, setCompanyDetails] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const [showDetailEditPop, setShowDetailEditPop] = useState(false);
    const [showAboutEditPop, setShowAboutEditPop] = useState(false);

    const fetchSurveyQuestions = async (userId) => {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey/questions`, { user_id: userId });

        if (response.data.result.status === 'success') {
            setSurveyUserInputId(response.data.result.survey_user_input_id);
            setQuestions(response.data.result.questions);

            setCompanyName(response.data.result.questions[0].answer);
            setCompanyDetails(response.data.result.questions[8].answer);
            setCompanyWebsite(response.data.result.questions[9].answer);
            setEmployeeCount(response.data.result.questions[10].answer);
        } else {
            toast.error(response.data.result.message);
        }
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        fetchSurveyQuestions(storedUser.userId);
    }, []);

    const handleCloseAccount = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_id: user ? user.userId : null })
            });
            console.log("response from close account============", response);

            const result = await response.json();
            console.log("result===============", result);
            if (result.result.status === 'success') {
                localStorage.removeItem('user');
                navigate('/');
            } else {
                console.error(result.message);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    const editUserInfo = () => {
        setEditInfo(!editInfo)
    }

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        setUser(storedUser);
        console.log('stored urer in fetch ====', storedUser);

        const fetchUserData = async () => {
            if (storedUser) {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/fetch/user`, {
                    user_id: storedUser.userId,
                });
                console.log("response in setting fetching=====", response);

                if (response.data.result.status === 'success') {
                    setFirstName(response.data.result.name.split(' ')[0] || '');
                    setLastName(response.data.result.name.split(' ').slice(-1).join(' ') || '');
                    setEmail(response.data.result.email || '');
                    setUserName(response.data.result.name || '');
                    setUser({ ...storedUser, ...response.data });
                }
            } else {
                console.error('Failed to fetch user data');
            }
        }

        fetchUserData();
    }, []);

    const updateUserInfo = async () => {
        if (!user) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.userId,
                    name: `${firstName} ${lastName}`,
                    email
                }),
            });
            const data = await response.json();
            console.log("data user========", data);

            if (data.result.status === 'success') {

                const updatedUser = {
                    ...user,
                    userName: `${firstName} ${lastName}`,
                    firstName,
                    email,
                };
                localStorage.setItem('user', JSON.stringify(updatedUser));
                setUserName(data.result.name)
                setEmail(data.result.email)
                setFirstName(data.result.name.split(' ')[0] || '');
                setLastName(data.result.name.split(' ').slice(-1).join(' ') || '');
                toast.success('User updated successfully');
                setEditInfo(true);
            } else {
                toast.error(`Error: ${data.result.message}`);
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    }

    const handleEditClick = (section) => {
        if (section === 'detail') setShowDetailEditPop(true);
        if (section === 'about') setShowAboutEditPop(true);
    };

    const handleDetailEditClose = () => {
        setShowDetailEditPop(false);
        fetchSurveyQuestions(user.userId);
    };

    const handleAboutEditClose = () => {
        setShowAboutEditPop(false);
        fetchSurveyQuestions(user.userId);
    };

    const openCloseAccountPopup = () => {
        setIsPopupOpen(true);
    };

    const closeCloseAccountPopup = () => {
        setIsPopupOpen(false);
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="client-profile-container">
            <header className="header">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <ClientNavbar />
            </header>
            <section className="client-profile-contents">
                {editInfo ? (<div className="contact-card">
                    <div className='edit-btn-profile'>
                        <p className="main-heading" style={{ marginBottom: '20px' }}>Account</p>
                        <button className="edit-button" onClick={editUserInfo}><MdEdit /></button>
                    </div>
                    <div className='user-detail'>
                        <p className='heading'>User ID</p>
                        <p className='content'>{user?.userId}</p>
                    </div>
                    <div className='user-detail'>
                        <p className='heading'>Name</p>
                        <p className='content'>{userName}</p>
                    </div>
                    <div className='user-detail'>
                        <p className='heading'>Email</p>
                        <p className='content'>{email}</p>
                    </div>
                </div>) : (
                    <div className="contact-card">
                        <h3 style={{ marginBottom: '20px' }}>Account</h3>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '18px' }}>
                            <div className="input-group">
                                <label>First Name</label>
                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
                            </div>
                            <div className="input-group">
                                <label>Last Name</label>
                                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
                            </div>
                        </div>
                        <br />
                        <div className="input-group">
                            <label>Email</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        </div>
                        <button className='update-btn' onClick={updateUserInfo}>Update</button>
                        <button className='cancel-btn' onClick={editUserInfo}>Cancel</button>
                    </div>
                )}
                <div className="client-card">
                    <div className="client-info">
                        <div className='edit-btn-profile'>
                            <p className='heading'>Company Name</p>
                            <button className="edit-button" onClick={() => handleEditClick('detail')}><MdEdit /></button>
                        </div>
                        <p className='content' style={{ fontWeight: '600' }}>{companyName}</p><br />
                        <div className='edit-btn-profile'>
                            <p className='heading'>Employees</p>
                        </div>
                        <p className='content' style={{ fontWeight: '600' }}>{employeeCount}</p><br />
                        <div className='edit-btn-profile'>
                            <p className='heading'>Company Website</p>
                        </div>
                        <p className='content' style={{ fontWeight: '600' }}>{companyWebsite}</p>
                    </div>
                </div>
                <div className="company-section">
                    <div className='edit-btn-profile'>
                        <p className='heading'>About Company</p>
                        <button className="edit-button" onClick={() => handleEditClick('about')}><MdEdit /></button>
                    </div>
                    <p className='content'>{companyDetails}</p>
                </div>
                <div>
                    <button className='close-account' onClick={openCloseAccountPopup}>Close my account</button>
                </div>
            </section>
            {/* <footer className="footer">
                <button className="back-btn" onClick={handleBackButton}>Back</button>
                <button className="next-btn" onClick={handleNextButton}>Next</button>
            </footer> */}
            {showDetailEditPop && (
                <CompanyDetailPopup
                    onClose={handleDetailEditClose}
                />
            )}
            {showAboutEditPop && (
                <AboutCompanyPopup
                    onClose={handleAboutEditClose}
                />
            )}
            {isPopupOpen && (
                <CloseAccountConfirmation
                    onClose={closeCloseAccountPopup}
                    onSave={handleCloseAccount}
                />
            )}
            <ToastContainer />
        </div>
    );
}

export default ClientProfile;
